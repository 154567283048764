import { Method, MethodsDict, MethodsInfoDict } from 'graphql-clientgen';

export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  MongoID: any;
  Date: any;
};

export type _IdOperatorsFilterAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAnalyticsCommunicationsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAnalyticsDocumentsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAnswerResearchInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyCampaignContestationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDownloadSensitiveFilesInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailStatsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailTemplateInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEventInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFeedInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFileInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyImportUsersPlanInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyIncentiveCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyJobTitleInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyMeuDiaPdvTableInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyReconheceInvoiceOriginInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyReconheceTransactionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyReconheceWalletInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyRegionItemInput = {
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  ne?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyRemunerationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyResearchInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManySearchCountInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyShareInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserCreditOperationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserDeletedInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserHistoryBlockInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserWordsBlackListInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneCampaignContestationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEmailInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEmailStatsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneJobTitleInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneMeuDiaPdvTableInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneRegionItemInput = {
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  ne?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneRemunerationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneUserContentPermissionInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneUserDeletedInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveManyFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveManyPinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOnePinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterRemoveOneShareInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemunerationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneAnalyticsCommunicationsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneAnalyticsDocumentsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneAnswerResearchInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterUpdateOneCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneImportUsersPlanInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneMeuDiaPdvTableInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneRemunerationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneResearchInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type AcceptedTermsAnalytics = {
  __typename?: 'AcceptedTermsAnalytics';
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
};

export type AddressHelperPayload = {
  __typename?: 'AddressHelperPayload';
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<AddressHelperRegionItemOption>;
  state?: Maybe<AddressHelperRegionItemOption>;
  region?: Maybe<AddressHelperRegionItemOption>;
  regionDDDs?: Maybe<Array<Maybe<AddressHelperRegionItemOption>>>;
  neighborhood?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
};

export type AddressHelperRegionItemOption = {
  __typename?: 'AddressHelperRegionItemOption';
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AnalyticsComment = {
  __typename?: 'AnalyticsComment';
  _id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  materialTitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  likesCount?: Maybe<Scalars['Int']>;
  subCommentsCount?: Maybe<Scalars['Int']>;
  commentId?: Maybe<Scalars['String']>;
};

export type AnalyticsCommunications = {
  __typename?: 'AnalyticsCommunications';
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsCommunicationsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type AnalyticsCommunicationsPagination = {
  __typename?: 'AnalyticsCommunicationsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<AnalyticsCommunications>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AnalyticsDocuments = {
  __typename?: 'AnalyticsDocuments';
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsDocumentsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type AnalyticsDocumentsPagination = {
  __typename?: 'AnalyticsDocumentsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<AnalyticsDocuments>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AnalyticsIncentiveCampaign = {
  __typename?: 'AnalyticsIncentiveCampaign';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignValidity?: Maybe<Scalars['JSON']>;
};

export type AnalyticsNotification = {
  __typename?: 'AnalyticsNotification';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type AnalyticsPagination = {
  __typename?: 'AnalyticsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Analytics>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AnalyticsPin = {
  __typename?: 'AnalyticsPin';
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AnalyticsPoints = {
  __typename?: 'AnalyticsPoints';
  userId?: Maybe<Scalars['String']>;
  points?: Maybe<Array<Maybe<PointsExpireType>>>;
};

export type AnalyticsReportFilterInput = {
  kind?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['Date']>;
  dateEnd?: Maybe<Scalars['Date']>;
};

export type AnalyticsReportItem = {
  __typename?: 'AnalyticsReportItem';
  event?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userChannel?: Maybe<Scalars['String']>;
  userRoleGroup?: Maybe<Scalars['String']>;
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  date: Scalars['Date'];
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  day: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  deviceKind?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
};

export type AnalyticsUser = {
  __typename?: 'AnalyticsUser';
  _id?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cargo?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  data_adesao?: Maybe<Scalars['String']>;
  ativo?: Maybe<Scalars['Boolean']>;
  matricula?: Maybe<Scalars['String']>;
  responsavelUOPDV?: Maybe<Scalars['String']>;
  matriculaResponsavelUOPDV?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reconhece?: Maybe<ReconheceAnalytics>;
  acceptedTerms?: Maybe<Array<Maybe<AcceptedTermsAnalytics>>>;
  data_criacao?: Maybe<Scalars['String']>;
  data_atualizacao?: Maybe<Scalars['String']>;
  data_ultimo_login?: Maybe<Scalars['String']>;
};

export type AnalyticsUserNotification = {
  __typename?: 'AnalyticsUserNotification';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  read?: Maybe<Array<Maybe<AnalyticsNotification>>>;
  unread?: Maybe<Array<Maybe<AnalyticsNotification>>>;
};

export type AnswerResearch = {
  __typename?: 'AnswerResearch';
  userId?: Maybe<Scalars['String']>;
  researchId?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  research?: Maybe<Research>;
};

/** List of items with pagination. */
export type AnswerResearchPagination = {
  __typename?: 'AnswerResearchPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<AnswerResearch>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Author = {
  __typename?: 'Author';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type AvailableAtChannelsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyEventInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyCampaignContestationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyEventInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyIncentiveCampaignInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyRemunerationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyResearchInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneCampaignContestationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneIncentiveCampaignInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneRemunerationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterRemoveOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterRemunerationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneIncentiveCampaignInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneRemunerationInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneResearchInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyEventInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyEventInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyCampaignContestationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyEventInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyIncentiveCampaignInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyRemunerationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneCampaignContestationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneIncentiveCampaignInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneRemunerationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterRemoveOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterRemunerationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneIncentiveCampaignInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneRemunerationInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AwaitingUsersApprover = {
  __typename?: 'AwaitingUsersApprover';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidity>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImages>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedBy>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<BannerAuthor>;
};

export type BannerAuthor = {
  __typename?: 'BannerAuthor';
  name: Scalars['String'];
};

export type BannerChangedBy = {
  __typename?: 'BannerChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type BannerChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type BannerImages = {
  __typename?: 'BannerImages';
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  display?: Maybe<EnumBannerImagesDisplay>;
  fileId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type BannerImagesInput = {
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  display?: Maybe<EnumBannerImagesDisplay>;
  fileId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type BannerValidity = {
  __typename?: 'BannerValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BannerValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BaseDownload = {
  __typename?: 'BaseDownload';
  schema?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type CampaignContestation = {
  __typename?: 'CampaignContestation';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  protocol?: Maybe<Scalars['Float']>;
  indicator?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  response?: Maybe<CampaignContestationResponse>;
  isResultContest?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<CampaignContestationAuthor>;
  files?: Maybe<Array<Maybe<File>>>;
  responseFiles?: Maybe<Array<Maybe<File>>>;
  campaign?: Maybe<IncentiveCampaign>;
};

export type CampaignContestationFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type CampaignContestationResponseFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type CampaignContestationAuthor = {
  __typename?: 'CampaignContestationAuthor';
  name: Scalars['String'];
  channel: Scalars['String'];
  region: Scalars['String'];
  roleGroup: Scalars['String'];
  matricula?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type CampaignContestationPagination = {
  __typename?: 'CampaignContestationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<CampaignContestation>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CampaignContestationResponse = {
  __typename?: 'CampaignContestationResponse';
  status?: Maybe<EnumCampaignContestationResponseStatus>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  responseFileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CampaignContestationResponseInput = {
  status?: Maybe<EnumCampaignContestationResponseStatus>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  responseFileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Category = {
  __typename?: 'Category';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Category>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  parentCategory?: Maybe<Category>;
  materials?: Maybe<MaterialPagination>;
};

export type CategorySubCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCategoryInput>;
};

export type CategoryParentCategoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCategoryInput>;
};

export type CategoryMaterialsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
  kind: Array<Maybe<EnumMaterialKind>>;
};

/** List of items with pagination. */
export type CategoryPagination = {
  __typename?: 'CategoryPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Channel = {
  __typename?: 'Channel';
  _id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  roleGroups?: Maybe<Array<Maybe<RoleGroup>>>;
  subChannels?: Maybe<Array<Maybe<Channel>>>;
};

export type ChannelRoleGroupsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type ChannelSubChannelsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyChannelInput>;
};

/** List of items with pagination. */
export type ChannelPagination = {
  __typename?: 'ChannelPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Channel>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Comment = {
  __typename?: 'Comment';
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Likes>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  subComments?: Maybe<Array<Maybe<Comment>>>;
  replyRecipient?: Maybe<User>;
  user?: Maybe<SocialUser>;
};

export type CommentSubCommentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCommentInput>;
};

/** List of items with pagination. */
export type CommentPagination = {
  __typename?: 'CommentPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CommentSortPaginationFilterInput = {
  parentId: Scalars['String'];
};

export enum CommunicationKindFilter {
  Email = 'email',
  Notification = 'notification'
}

export enum ContentPermissionsHelperKind {
  Region = 'region',
  RoleGroup = 'roleGroup',
  SiteContentType = 'siteContentType'
}

export type ContentPermissionsHelperOption = {
  __typename?: 'ContentPermissionsHelperOption';
  id: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
  pId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  kind: ContentPermissionsHelperKind;
};

export enum ContestationStatusFilterEnum {
  Founded = 'founded',
  Unfounded = 'unfounded',
  Empty = 'empty'
}

export type CreatedAtOperatorsFilterAnalyticsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreatedAtOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreatedByOperatorsFilterFindManyReconheceWalletInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateManyCategoryInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type CreateManyCategoryPayload = {
  __typename?: 'CreateManyCategoryPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Category>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
};

export type CreateManyEmailStatsPayload = {
  __typename?: 'CreateManyEmailStatsPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<EmailStats>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyMeuDiaPdvTableInput = {
  regional: Scalars['String'];
  uf: Scalars['String'];
  municipio: Scalars['String'];
  canal: Scalars['String'];
  bairro?: Maybe<Scalars['String']>;
  loja: Scalars['String'];
  endereco: Scalars['String'];
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo: Scalars['String'];
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
};

export type CreateManyMeuDiaPdvTablePayload = {
  __typename?: 'CreateManyMeuDiaPdvTablePayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<MeuDiaPdvTable>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type CreateManyNotificationPayload = {
  __typename?: 'CreateManyNotificationPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Notification>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateOneAnalyticsCommunicationsInput = {
  userId: Scalars['String'];
  materialId: Scalars['String'];
  fileId?: Maybe<Scalars['String']>;
  action: EnumAnalyticsCommunicationsAction;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneAnalyticsCommunicationsPayload = {
  __typename?: 'CreateOneAnalyticsCommunicationsPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<AnalyticsCommunications>;
};

export type CreateOneAnalyticsDocumentsInput = {
  userId: Scalars['String'];
  materialId: Scalars['String'];
  fileId?: Maybe<Scalars['String']>;
  action: EnumAnalyticsDocumentsAction;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneAnalyticsDocumentsPayload = {
  __typename?: 'CreateOneAnalyticsDocumentsPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<AnalyticsDocuments>;
};

export type CreateOneAnswerResearchInput = {
  userId: Scalars['String'];
  researchId: Scalars['String'];
  answers?: Maybe<Scalars['JSON']>;
};

export type CreateOneAnswerResearchPayload = {
  __typename?: 'CreateOneAnswerResearchPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<AnswerResearch>;
};

export type CreateOneBannerInput = {
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position: Scalars['String'];
  title: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images: Array<Maybe<BannerImagesInput>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
};

export type CreateOneBannerPayload = {
  __typename?: 'CreateOneBannerPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Banner>;
};

export type CreateOneCampaignContestationInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  indicator: Scalars['String'];
  parentId: Scalars['String'];
  fileIds: Array<Maybe<Scalars['String']>>;
  isResultContest?: Maybe<Scalars['Boolean']>;
};

export type CreateOneCampaignContestationPayload = {
  __typename?: 'CreateOneCampaignContestationPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<CampaignContestation>;
};

export type CreateOneCategoryInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type CreateOneCategoryPayload = {
  __typename?: 'CreateOneCategoryPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Category>;
};

export type CreateOneCommentInput = {
  parentId: Scalars['String'];
  roomId?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content: Scalars['String'];
  replyRecipientId?: Maybe<Scalars['String']>;
};

export type CreateOneCommentPayload = {
  __typename?: 'CreateOneCommentPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Comment>;
};

export type CreateOneEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
};

export type CreateOneEmailStatsPayload = {
  __typename?: 'CreateOneEmailStatsPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EmailStats>;
};

export type CreateOneEmailTemplateInput = {
  title: Scalars['String'];
  headerFileId: Scalars['String'];
  footerFileId: Scalars['String'];
};

export type CreateOneEmailTemplatePayload = {
  __typename?: 'CreateOneEmailTemplatePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EmailTemplate>;
};

export type CreateOneEventInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventStatus>;
  validity?: Maybe<EventValidityInput>;
  publishDate?: Maybe<Scalars['Date']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  prePublishMail?: Maybe<Scalars['String']>;
  onPublishMail?: Maybe<Scalars['String']>;
  onUpdateMail?: Maybe<Scalars['String']>;
  sentEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  schedulles?: Maybe<Scalars['JSON']>;
  gallery?: Maybe<Scalars['JSON']>;
  guides?: Maybe<Scalars['JSON']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneEventLiveInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate: Scalars['Date'];
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  kind: EnumEventLiveKind;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
};

export type CreateOneEventLivePayload = {
  __typename?: 'CreateOneEventLivePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EventLive>;
};

export type CreateOneEventPayload = {
  __typename?: 'CreateOneEventPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Event>;
};

export type CreateOneFeedInput = {
  eventId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  justification?: Maybe<Scalars['String']>;
  status?: Maybe<EnumFeedStatus>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOneFeedPayload = {
  __typename?: 'CreateOneFeedPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Feed>;
};

export type CreateOneFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  extension: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneFilePayload = {
  __typename?: 'CreateOneFilePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<File>;
};

export type CreateOneFolderInput = {
  kind: EnumFolderKind;
  name?: Maybe<Scalars['String']>;
};

export type CreateOneFolderPayload = {
  __typename?: 'CreateOneFolderPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Folder>;
};

export type CreateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneImportUsersPlanPayload = {
  __typename?: 'CreateOneImportUsersPlanPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<ImportUsersPlan>;
};

export type CreateOneIncentiveCampaignInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  status: EnumIncentiveCampaignStatus;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriodInput>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContentInput>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin: Scalars['String'];
  /** _id da carteira */
  walletId: Scalars['String'];
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidityInput>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidityInput>;
};

export type CreateOneIncentiveCampaignPayload = {
  __typename?: 'CreateOneIncentiveCampaignPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<IncentiveCampaign>;
};

export type CreateOneMaterialInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind: EnumMaterialKind;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneMaterialPayload = {
  __typename?: 'CreateOneMaterialPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Material>;
};

export type CreateOneMeuDiaPdvTableInput = {
  regional: Scalars['String'];
  uf: Scalars['String'];
  municipio: Scalars['String'];
  canal: Scalars['String'];
  bairro?: Maybe<Scalars['String']>;
  loja: Scalars['String'];
  endereco: Scalars['String'];
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo: Scalars['String'];
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
};

export type CreateOneMeuDiaPdvTablePayload = {
  __typename?: 'CreateOneMeuDiaPdvTablePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<MeuDiaPdvTable>;
};

export type CreateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type CreateOneNotificationPayload = {
  __typename?: 'CreateOneNotificationPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Notification>;
};

export type CreateOnePinInput = {
  /** ID do item que está sendo favoritado. */
  recordId: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
};

export type CreateOnePinPayload = {
  __typename?: 'CreateOnePinPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Pin>;
};

export type CreateOneRecentsActivitiesInput = {
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreateOneRecentsActivitiesPayload = {
  __typename?: 'CreateOneRecentsActivitiesPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<RecentsActivities>;
};

export type CreateOneReconheceWalletInput = {
  /** Data em que o status foi alterado para `Disponivel` */
  paidAt?: Maybe<Scalars['Date']>;
  initialCredit: Scalars['Float'];
  status?: Maybe<EnumReconheceWalletStatus>;
  /** identificador da ordem de pagamento */
  POIdentifier: Scalars['String'];
  /** imagem da ordem de pagamento */
  POImages: Array<Maybe<Scalars['String']>>;
  /** identificador da NF */
  NFIdentifier: Scalars['String'];
  /** imagem da ordem da NF */
  NFImages: Array<Maybe<Scalars['String']>>;
  invoiceOrigin: Scalars['String'];
};

export type CreateOneReconheceWalletPayload = {
  __typename?: 'CreateOneReconheceWalletPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<ReconheceWallet>;
};

export type CreateOneRemunerationInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
};

export type CreateOneRemunerationPayload = {
  __typename?: 'CreateOneRemunerationPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Remuneration>;
};

export type CreateOneResearchInput = {
  title: Scalars['String'];
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidityInput>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneResearchPayload = {
  __typename?: 'CreateOneResearchPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Research>;
};

export type CreateOneShareInput = {
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId: Scalars['String'];
  userIds: Array<Maybe<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
};

export type CreateOneSharePayload = {
  __typename?: 'CreateOneSharePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Share>;
};

export type CreateOneUserHistoryBlockInput = {
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
};

export type CreateOneUserHistoryBlockPayload = {
  __typename?: 'CreateOneUserHistoryBlockPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserHistoryBlock>;
};

export type CreateOneUserWordsBlackListInput = {
  word?: Maybe<Scalars['String']>;
};

export type CreateOneUserWordsBlackListPayload = {
  __typename?: 'CreateOneUserWordsBlackListPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserWordsBlackList>;
};

export type DateRange = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type DayOperatorsFilterAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  ne?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DayOperatorsFilterFindManyAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  ne?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyCampaignContestationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyIncentiveCampaignInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyRemunerationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneCampaignContestationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneIncentiveCampaignInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneRemunerationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterRemoveOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterRemunerationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneIncentiveCampaignInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneRemunerationInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DownloadAcceptedTerms = {
  __typename?: 'DownloadAcceptedTerms';
  url?: Maybe<Scalars['String']>;
};

export type DownloadDebitExpirePointsUrl = {
  __typename?: 'DownloadDebitExpirePointsUrl';
  url?: Maybe<Scalars['String']>;
};

export type DownloadExpirePointsUrl = {
  __typename?: 'DownloadExpirePointsUrl';
  url?: Maybe<Scalars['String']>;
};

export type DownloadExtraCreditUrl = {
  __typename?: 'DownloadExtraCreditUrl';
  url?: Maybe<Scalars['String']>;
};

export type DownloadReversalUrl = {
  __typename?: 'DownloadReversalUrl';
  url?: Maybe<Scalars['String']>;
};

export type DownloadSensitiveFiles = {
  __typename?: 'DownloadSensitiveFiles';
  owner?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  file?: Maybe<File>;
  user?: Maybe<User>;
};

/** List of items with pagination. */
export type DownloadSensitiveFilesPagination = {
  __typename?: 'DownloadSensitiveFilesPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DownloadSensitiveFiles>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type DownloadtUserCreditPoints = {
  __typename?: 'DownloadtUserCreditPoints';
  url?: Maybe<Scalars['String']>;
};

export type DownloadUsersAcceptedPoints = {
  __typename?: 'DownloadUsersAcceptedPoints';
  url?: Maybe<Scalars['String']>;
};

export type DownloadUsersInfo = {
  __typename?: 'DownloadUsersInfo';
  url?: Maybe<Scalars['String']>;
};

export type DownloadUsersWithPoints = {
  __typename?: 'DownloadUsersWithPoints';
  url?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadBy>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnRead>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  images?: Maybe<EmailImages>;
  attachments?: Maybe<Array<Maybe<File>>>;
  html?: Maybe<EmailHtml>;
};

export type EmailAttachmentsArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type EmailHtml = {
  __typename?: 'EmailHtml';
  body?: Maybe<Scalars['String']>;
};

export type EmailImages = {
  __typename?: 'EmailImages';
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
};

export type EmailNotifyCollectionOnRead = {
  __typename?: 'EmailNotifyCollectionOnRead';
  modelToUpdate?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailNotifyCollectionOnReadInput = {
  modelToUpdate?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterFindManyUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterFindOneUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterUpdateOneUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** List of items with pagination. */
export type EmailPagination = {
  __typename?: 'EmailPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Email>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type EmailReadBy = {
  __typename?: 'EmailReadBy';
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EmailReadByInput = {
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EmailStats = {
  __typename?: 'EmailStats';
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type EmailStatsAnalyticsPayload = {
  __typename?: 'EmailStatsAnalyticsPayload';
  id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  menu?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Int']>;
  delivered?: Maybe<Scalars['Int']>;
  undelivered?: Maybe<Scalars['Int']>;
  spam?: Maybe<Scalars['Int']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  title?: Maybe<Scalars['String']>;
  headerFileId?: Maybe<Scalars['String']>;
  footerFileId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  headerFile?: Maybe<File>;
  footerFile?: Maybe<File>;
};

export enum EmailTemplateNames {
  Template1 = 'template1',
  Template2 = 'template2',
  Template3 = 'template3',
  Template4 = 'template4'
}

/** List of items with pagination. */
export type EmailTemplatePagination = {
  __typename?: 'EmailTemplatePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<EmailTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum EnumAnalyticsCommunicationsAction {
  View = 'view',
  Download = 'download'
}

/** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
export enum EnumAnalyticsDeviceKind {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  NotFound = 'notFound'
}

export enum EnumAnalyticsDocumentsAction {
  View = 'view',
  Download = 'download'
}

export enum EnumAnalyticsEvent {
  Login = 'login',
  LoginDenied = 'login_denied',
  Logout = 'logout',
  LoginPcs = 'login_pcs',
  LoginAzureAd = 'login_azure_ad',
  LoginDeniedPcs = 'login_denied_pcs',
  LogoutPcs = 'logout_pcs',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  View = 'view',
  PageView = 'pageView',
  Share = 'share',
  Like = 'like',
  Download = 'download',
  DownloadDenied = 'download_denied',
  Search = 'search',
  RecoverPoints = 'recover_points',
  DownloadRegulation = 'download_regulation',
  DownloadPresentation = 'download_presentation'
}

/** Kind do documento que recebeu o evento: Ex: price_table */
export enum EnumAnalyticsParentKind {
  Live = 'live',
  User = 'user',
  Search = 'search',
  PageView = 'pageView',
  IncentiveCampaign = 'incentiveCampaign',
  PriceTable = 'price_table',
  Material = 'material',
  Video = 'video',
  Communication = 'communication',
  Regulation = 'regulation',
  Tanamao = 'tanamao',
  OfferMap = 'offer_map',
  Process = 'process',
  SalesArgument = 'sales_argument',
  DevicesComparator = 'devices_comparator',
  PartsMap = 'parts_map',
  SupportMaterials = 'support_materials',
  LinksUteis = 'links_uteis',
  News = 'news',
  Document = 'document',
  CompaniesMaterials = 'companies_materials',
  SeeMoreChannel = 'see_more_channel',
  SeeMoreRegion = 'see_more_region',
  SocialFeed = 'social_feed',
  CommunicationBackup = 'communication_backup',
  Pilulas = 'pilulas',
  ComunicacaoParaOCanal = 'comunicacao_para_o_canal',
  Matinais = 'matinais',
  MateriaisParaOPdv = 'materiais_para_o_pdv',
  PdvMap = 'pdv_map',
  Planogram = 'planogram',
  PdvPieces = 'pdv_pieces',
  PocketGuide = 'pocket_guide'
}

export enum EnumBannerImagesDisplay {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Both = 'both',
  Stories = 'stories'
}

export enum EnumBannerStatus {
  Cancelled = 'cancelled',
  Published = 'published',
  Expired = 'expired'
}

export enum EnumCampaignContestationResponseStatus {
  Founded = 'founded',
  Unfounded = 'unfounded'
}

export enum EnumEmailStatsStatus {
  Sent = 'sent',
  Open = 'open',
  Spam = 'spam',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Error = 'error'
}

export enum EnumEmailStatus {
  Scheduled = 'scheduled',
  Sent = 'sent',
  Draft = 'draft',
  Error = 'error',
  Sending = 'sending'
}

export enum EnumEmailTemplateName {
  Template1 = 'template1',
  Template2 = 'template2',
  Template3 = 'template3',
  Template4 = 'template4',
  Template5 = 'template5',
  TemplateConsumer01 = 'templateConsumer01',
  TemplateConsumer02 = 'templateConsumer02',
  TemplateConsumer03 = 'templateConsumer03',
  TemplateConsumer04 = 'templateConsumer04',
  TemplateConsumer05 = 'templateConsumer05',
  TemplateCorp01 = 'templateCorp01',
  TemplateCorp02 = 'templateCorp02',
  TemplateCorp03 = 'templateCorp03',
  TemplateCorp04 = 'templateCorp04',
  TemplateCorp05 = 'templateCorp05',
  TemplateCorp06 = 'templateCorp06',
  TemplateCorp07 = 'templateCorp07',
  TemplateCorp08 = 'templateCorp08',
  TemplateCorp09 = 'templateCorp09',
  TemplateCorp10 = 'templateCorp10',
  TemplateCorp11 = 'templateCorp11',
  TemplateCorp12 = 'templateCorp12',
  TemplateCorp13 = 'templateCorp13',
  TemplateCorp14 = 'templateCorp14',
  TemplateCorp15 = 'templateCorp15',
  PlainHtml = 'plainHTML'
}

export enum EnumEventLiveKind {
  Live = 'live'
}

export enum EnumEventLiveStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

export enum EnumEventStatus {
  Editing = 'editing',
  Programming = 'programming',
  InProgress = 'in_progress',
  Closed = 'closed',
  Cancelled = 'cancelled'
}

export enum EnumFeedStatus {
  Moderation = 'moderation',
  Approved = 'approved',
  Disapproved = 'disapproved'
}

/** O tipo de uso do material. Ex: "video_poster" */
export enum EnumFileKind {
  CardThumb = 'card_thumb',
  VideoMp4 = 'video_mp4',
  VideoThumb = 'video_thumb',
  VideoBanner = 'video_banner',
  Pdf = 'pdf',
  Notification = 'notification',
  Mailing = 'mailing',
  NotificationImage = 'notification_image',
  EmailAsset = 'email_asset',
  BannerImage = 'banner_image',
  AnyImage = 'any_image',
  AnyDoc = 'any_doc',
  ProfileImage = 'profile_image',
  Xlsx = 'xlsx',
  BannerMaterialMobile = 'banner_material_mobile',
  BannerMaterialDesktop = 'banner_material_desktop',
  SocialFeedImage = 'social_feed_image',
  SocialFeedVideo = 'social_feed_video',
  SensitiveFile = 'sensitive_file',
  Places = 'places',
  SmbFtp = 'smb_ftp',
  UserCreditOperation = 'user_credit_operation',
  StructuralFiles = 'structural_files',
  Cad = 'cad',
  StructuralImages = 'structural_images'
}

export enum EnumFolderKind {
  Favorite = 'favorite'
}

export enum EnumFutureOperationOperation {
  Credit = 'credit',
  Reserve = 'reserve',
  Debit = 'debit',
  Release = 'release',
  Chargeback = 'chargeback',
  Reverse = 'reverse',
  Expired = 'expired',
  DisabledExpired = 'disabledExpired',
  ManualCredit = 'manualCredit',
  ManualRevertReserved = 'manualRevertReserved'
}

export enum EnumImportUsersPlanOrigin {
  Sigtim = 'SIGTIM',
  Radar = 'RADAR',
  Import = 'IMPORT',
  Basevarejo = 'BASEVAREJO'
}

export enum EnumImportUsersPlanStatusImportUser {
  Pending = 'pending',
  Processed = 'processed',
  Processing = 'processing',
  Error = 'error'
}

export enum EnumIncentiveCampaignStatus {
  Current = 'current',
  WithResults = 'with_results',
  ResultWithPoints = 'result_with_points',
  InProgress = 'in_progress',
  InVerification = 'in_verification',
  WaitingPointsDistribution = 'waiting_points_distribution',
  Closed = 'closed'
}

export enum EnumMaterialCommunicationItemsConfigKind {
  Email = 'email',
  Notification = 'notification',
  Bulletin = 'bulletin'
}

export enum EnumMaterialKind {
  PriceTable = 'price_table',
  Material = 'material',
  Video = 'video',
  Communication = 'communication',
  Regulation = 'regulation',
  Tanamao = 'tanamao',
  OfferMap = 'offer_map',
  Process = 'process',
  SalesArgument = 'sales_argument',
  DevicesComparator = 'devices_comparator',
  PartsMap = 'parts_map',
  SupportMaterials = 'support_materials',
  LinksUteis = 'links_uteis',
  News = 'news',
  Document = 'document',
  CompaniesMaterials = 'companies_materials',
  SeeMoreChannel = 'see_more_channel',
  SeeMoreRegion = 'see_more_region',
  SocialFeed = 'social_feed',
  CommunicationBackup = 'communication_backup',
  Pilulas = 'pilulas',
  Matinais = 'matinais',
  ComunicacaoParaOCanal = 'comunicacao_para_o_canal',
  MateriaisParaOPdv = 'materiais_para_o_pdv',
  PdvMap = 'pdv_map',
  Planogram = 'planogram',
  PdvPieces = 'pdv_pieces',
  PocketGuide = 'pocket_guide'
}

export enum EnumMaterialPreviousStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

export enum EnumMaterialStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

export enum EnumReconheceTransactionKind {
  Credit = 'credit',
  Reversal = 'reversal',
  CreditExtra = 'creditExtra'
}

export enum EnumReconheceTransactionStatus {
  Pending = 'pending',
  Done = 'done',
  Error = 'error'
}

export enum EnumReconheceWalletStatus {
  Draft = 'draft',
  Available = 'available'
}

export enum EnumRemunerationCommunicationItemsConfigKind {
  Email = 'email',
  Notification = 'notification',
  Bulletin = 'bulletin'
}

export enum EnumRemunerationStatus {
  Created = 'created',
  Published = 'published',
  Finished = 'finished'
}

export enum EnumRoleGroupIdmType {
  Region = 'region',
  Uf = 'uf'
}

export enum EnumShareKind {
  Material = 'material',
  IncentiveCampaign = 'incentiveCampaign'
}

export enum EnumUserCreditOperationOp {
  Credit = 'credit',
  Reserve = 'reserve',
  Debit = 'debit',
  Release = 'release',
  Chargeback = 'chargeback',
  Reverse = 'reverse',
  Expired = 'expired',
  DisabledExpired = 'disabledExpired',
  ManualCredit = 'manualCredit',
  ManualRevertReserved = 'manualRevertReserved'
}

export enum EnumUserCreditOperationStatus {
  Reversed = 'reversed',
  Done = 'done'
}

export enum EnumUserReconheceHistoryOp {
  Credit = 'credit',
  Reserve = 'reserve',
  Debit = 'debit',
  Release = 'release',
  Chargeback = 'chargeback',
  Reverse = 'reverse',
  Expired = 'expired',
  DisabledExpired = 'disabledExpired',
  ManualCredit = 'manualCredit',
  ManualRevertReserved = 'manualRevertReserved'
}

export type Event = {
  __typename?: 'Event';
  title?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EventStatusOption>;
  validity?: Maybe<EventValidity>;
  likes?: Maybe<Scalars['Float']>;
  publishDate?: Maybe<Scalars['Date']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  prePublishMail?: Maybe<Scalars['String']>;
  onPublishMail?: Maybe<Scalars['String']>;
  onUpdateMail?: Maybe<Scalars['String']>;
  sentEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  schedulles?: Maybe<Scalars['JSON']>;
  gallery?: Maybe<Scalars['JSON']>;
  guides?: Maybe<Scalars['JSON']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** O usuário que criou o evento */
  author?: Maybe<User>;
  thumbnailContent?: Maybe<File>;
  bannerContent?: Maybe<File>;
  /** O usuário que criou o evento */
  notifications?: Maybe<Array<Maybe<Notification>>>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
};

export type EventDateOperatorsFilterFindManyEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventDateOperatorsFilterFindOneEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventDateOperatorsFilterUpdateOneEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventLive = {
  __typename?: 'EventLive';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories: Array<Maybe<Category>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Likes>;
  changedBy?: Maybe<EventLiveChangedBy>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
  files?: Maybe<Array<Maybe<File>>>;
  user?: Maybe<LiveAuthor>;
  views?: Maybe<MaterialViews>;
  sameCategoryItems?: Maybe<MaterialPagination>;
};

export type EventLiveFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type EventLiveSameCategoryItemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type EventLiveChangedBy = {
  __typename?: 'EventLiveChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EventLiveChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type EventLivePagination = {
  __typename?: 'EventLivePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<EventLive>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type EventOperatorsFilterAnalyticsInput = {
  gt?: Maybe<EnumAnalyticsEvent>;
  gte?: Maybe<EnumAnalyticsEvent>;
  lt?: Maybe<EnumAnalyticsEvent>;
  lte?: Maybe<EnumAnalyticsEvent>;
  ne?: Maybe<EnumAnalyticsEvent>;
  in?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
  nin?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
};

export type EventOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<EnumAnalyticsEvent>;
  gte?: Maybe<EnumAnalyticsEvent>;
  lt?: Maybe<EnumAnalyticsEvent>;
  lte?: Maybe<EnumAnalyticsEvent>;
  ne?: Maybe<EnumAnalyticsEvent>;
  in?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
  nin?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
};

/** List of items with pagination. */
export type EventPagination = {
  __typename?: 'EventPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Event>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type EventStatusOption = {
  __typename?: 'EventStatusOption';
  label: Scalars['String'];
  _id: Scalars['String'];
  value: EnumEventStatus;
  description: Scalars['String'];
};

export type EventValidity = {
  __typename?: 'EventValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Feed = {
  __typename?: 'Feed';
  eventId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<File>>>;
  videos?: Maybe<Array<Maybe<File>>>;
  justification?: Maybe<Scalars['String']>;
  status?: Maybe<EnumFeedStatus>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Usuário que criou a postagem */
  owner?: Maybe<FeedPostAuthor>;
  event?: Maybe<Event>;
};

export type FeedImagesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type FeedVideosArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type FeedEventArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type FeedPagination = {
  __typename?: 'FeedPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Feed>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type FeedPostAuthor = {
  __typename?: 'FeedPostAuthor';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
};

export type FeedUpdateLike = {
  __typename?: 'FeedUpdateLike';
  likes?: Maybe<Array<Scalars['String']>>;
};

export type File = {
  __typename?: 'File';
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfo>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  ownerContent?: Maybe<User>;
  /** A URL do arquivo assinada com um token de autorização */
  signedUrl?: Maybe<Scalars['String']>;
  /** A URL do arquivo assinada com um token de autorização */
  downloadUrl?: Maybe<Scalars['String']>;
};

export type FileImageInfo = {
  __typename?: 'FileImageInfo';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FileImageInfoInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type FilePagination = {
  __typename?: 'FilePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<File>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Filter = {
  titleOReventID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retorna os itens que incluem os status */
  statusIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retorna os itens que estão com os canais/cargos passados no filtro */
  partAvailableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca por usuario */
  user?: Maybe<UserFilterFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  parentId?: Maybe<Scalars['String']>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
};

export type FilterAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterAnalyticsInput>;
  OR?: Maybe<Array<FilterAnalyticsInput>>;
  AND?: Maybe<Array<FilterAnalyticsInput>>;
};

export type FilterAuthorInput = {
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type FilterFindManyAnalyticsCommunicationsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsCommunicationsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAnalyticsCommunicationsInput>;
  OR?: Maybe<Array<FilterFindManyAnalyticsCommunicationsInput>>;
  AND?: Maybe<Array<FilterFindManyAnalyticsCommunicationsInput>>;
};

export type FilterFindManyAnalyticsDocumentsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsDocumentsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAnalyticsDocumentsInput>;
  OR?: Maybe<Array<FilterFindManyAnalyticsDocumentsInput>>;
  AND?: Maybe<Array<FilterFindManyAnalyticsDocumentsInput>>;
};

export type FilterFindManyAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAnalyticsInput>;
  OR?: Maybe<Array<FilterFindManyAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindManyAnalyticsInput>>;
};

export type FilterFindManyAnswerResearchInput = {
  userId?: Maybe<Scalars['String']>;
  researchId?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAnswerResearchInput>;
  OR?: Maybe<Array<FilterFindManyAnswerResearchInput>>;
  AND?: Maybe<Array<FilterFindManyAnswerResearchInput>>;
};

export type FilterFindManyBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyBannerInput>;
  OR?: Maybe<Array<FilterFindManyBannerInput>>;
  AND?: Maybe<Array<FilterFindManyBannerInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindManyCampaignContestationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  protocol?: Maybe<Scalars['Float']>;
  indicator?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  response?: Maybe<CampaignContestationResponseInput>;
  isResultContest?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignContestationInput>;
  OR?: Maybe<Array<FilterFindManyCampaignContestationInput>>;
  AND?: Maybe<Array<FilterFindManyCampaignContestationInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por usuario */
  user?: Maybe<UserFilterFilter>;
  status?: Maybe<ContestationStatusFilterEnum>;
  protocolSearch?: Maybe<Scalars['String']>;
};

export type FilterFindManyCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCategoryInput>;
  OR?: Maybe<Array<FilterFindManyCategoryInput>>;
  AND?: Maybe<Array<FilterFindManyCategoryInput>>;
};

export type FilterFindManyChannelInput = {
  _id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyChannelInput>;
  OR?: Maybe<Array<FilterFindManyChannelInput>>;
  AND?: Maybe<Array<FilterFindManyChannelInput>>;
};

export type FilterFindManyCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCommentInput>;
  OR?: Maybe<Array<FilterFindManyCommentInput>>;
  AND?: Maybe<Array<FilterFindManyCommentInput>>;
};

export type FilterFindManyDownloadSensitiveFilesInput = {
  owner?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDownloadSensitiveFilesInput>;
  OR?: Maybe<Array<FilterFindManyDownloadSensitiveFilesInput>>;
  AND?: Maybe<Array<FilterFindManyDownloadSensitiveFilesInput>>;
};

export type FilterFindManyEmailInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadByInput>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnReadInput>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailInput>;
  OR?: Maybe<Array<FilterFindManyEmailInput>>;
  AND?: Maybe<Array<FilterFindManyEmailInput>>;
};

export type FilterFindManyEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailStatsInput>;
  OR?: Maybe<Array<FilterFindManyEmailStatsInput>>;
  AND?: Maybe<Array<FilterFindManyEmailStatsInput>>;
};

export type FilterFindManyEmailTemplateInput = {
  title?: Maybe<Scalars['String']>;
  headerFileId?: Maybe<Scalars['String']>;
  footerFileId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailTemplateInput>;
  OR?: Maybe<Array<FilterFindManyEmailTemplateInput>>;
  AND?: Maybe<Array<FilterFindManyEmailTemplateInput>>;
};

export type FilterFindManyEventInput = {
  title?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventStatus>;
  validity?: Maybe<EventValidityInput>;
  likes?: Maybe<Scalars['Float']>;
  publishDate?: Maybe<Scalars['Date']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  prePublishMail?: Maybe<Scalars['String']>;
  onPublishMail?: Maybe<Scalars['String']>;
  onUpdateMail?: Maybe<Scalars['String']>;
  sentEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  schedulles?: Maybe<Scalars['JSON']>;
  gallery?: Maybe<Scalars['JSON']>;
  guides?: Maybe<Scalars['JSON']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEventInput>;
  OR?: Maybe<Array<FilterFindManyEventInput>>;
  AND?: Maybe<Array<FilterFindManyEventInput>>;
  titleOReventID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type FilterFindManyEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEventLiveInput>;
  OR?: Maybe<Array<FilterFindManyEventLiveInput>>;
  AND?: Maybe<Array<FilterFindManyEventLiveInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindManyFeedInput = {
  eventId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  justification?: Maybe<Scalars['String']>;
  status?: Maybe<EnumFeedStatus>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFeedInput>;
  OR?: Maybe<Array<FilterFindManyFeedInput>>;
  AND?: Maybe<Array<FilterFindManyFeedInput>>;
  subscription?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfoInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFileInput>;
  OR?: Maybe<Array<FilterFindManyFileInput>>;
  AND?: Maybe<Array<FilterFindManyFileInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFolderInput>;
  OR?: Maybe<Array<FilterFindManyFolderInput>>;
  AND?: Maybe<Array<FilterFindManyFolderInput>>;
};

export type FilterFindManyImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyImportUsersPlanInput>;
  OR?: Maybe<Array<FilterFindManyImportUsersPlanInput>>;
  AND?: Maybe<Array<FilterFindManyImportUsersPlanInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
};

export type FilterFindManyIncentiveCampaignInput = {
  owner?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumIncentiveCampaignStatus>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriodInput>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContentInput>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin?: Maybe<Scalars['String']>;
  /** _id da carteira */
  walletId?: Maybe<Scalars['String']>;
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidityInput>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyIncentiveCampaignInput>;
  OR?: Maybe<Array<FilterFindManyIncentiveCampaignInput>>;
  AND?: Maybe<Array<FilterFindManyIncentiveCampaignInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Retorna os itens que incluem os status */
  statusIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retorna os itens que estão com os canais/cargos passados no filtro */
  partAvailableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
};

export type FilterFindManyJobTitleInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyJobTitleInput>;
  OR?: Maybe<Array<FilterFindManyJobTitleInput>>;
  AND?: Maybe<Array<FilterFindManyJobTitleInput>>;
};

export type FilterFindManyMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyMaterialInput>;
  OR?: Maybe<Array<FilterFindManyMaterialInput>>;
  AND?: Maybe<Array<FilterFindManyMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
  andCategories?: Maybe<Scalars['JSON']>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
};

export type FilterFindManyMeuDiaPdvTableInput = {
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyMeuDiaPdvTableInput>;
  OR?: Maybe<Array<FilterFindManyMeuDiaPdvTableInput>>;
  AND?: Maybe<Array<FilterFindManyMeuDiaPdvTableInput>>;
};

export type FilterFindManyNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyNotificationInput>;
  OR?: Maybe<Array<FilterFindManyNotificationInput>>;
  AND?: Maybe<Array<FilterFindManyNotificationInput>>;
};

export type FilterFindManyPinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPinInput>;
  OR?: Maybe<Array<FilterFindManyPinInput>>;
  AND?: Maybe<Array<FilterFindManyPinInput>>;
};

export type FilterFindManyRecentsActivitiesInput = {
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRecentsActivitiesInput>;
  OR?: Maybe<Array<FilterFindManyRecentsActivitiesInput>>;
  AND?: Maybe<Array<FilterFindManyRecentsActivitiesInput>>;
};

export type FilterFindManyReconheceInvoiceOriginInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyReconheceInvoiceOriginInput>;
  OR?: Maybe<Array<FilterFindManyReconheceInvoiceOriginInput>>;
  AND?: Maybe<Array<FilterFindManyReconheceInvoiceOriginInput>>;
};

export type FilterFindManyReconheceTransactionInput = {
  _id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  status?: Maybe<EnumReconheceTransactionStatus>;
  value?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  originWallets?: Maybe<Array<Maybe<ReconheceTransactionOriginWalletsInput>>>;
  walletIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<EnumReconheceTransactionKind>;
  error?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyReconheceTransactionInput>;
  OR?: Maybe<Array<FilterFindManyReconheceTransactionInput>>;
  AND?: Maybe<Array<FilterFindManyReconheceTransactionInput>>;
};

export type FilterFindManyReconheceWalletInput = {
  createdBy?: Maybe<Scalars['String']>;
  /** Data em que o status foi alterado para `Disponivel` */
  paidAt?: Maybe<Scalars['Date']>;
  initialCredit?: Maybe<Scalars['Float']>;
  usedCredit?: Maybe<Scalars['Float']>;
  availableCredit?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumReconheceWalletStatus>;
  /** identificador da ordem de pagamento */
  POIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem de pagamento */
  POImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** identificador da NF */
  NFIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem da NF */
  NFImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyReconheceWalletInput>;
  OR?: Maybe<Array<FilterFindManyReconheceWalletInput>>;
  AND?: Maybe<Array<FilterFindManyReconheceWalletInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inInvoiceOrigin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allInvoiceOrigin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
};

export type FilterFindManyRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegionInput>;
  OR?: Maybe<Array<FilterFindManyRegionInput>>;
  AND?: Maybe<Array<FilterFindManyRegionInput>>;
};

export type FilterFindManyRegionItemInput = {
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegionItemInput>;
  OR?: Maybe<Array<FilterFindManyRegionItemInput>>;
  AND?: Maybe<Array<FilterFindManyRegionItemInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyRemunerationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  remunerationId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRemunerationInput>;
  OR?: Maybe<Array<FilterFindManyRemunerationInput>>;
  AND?: Maybe<Array<FilterFindManyRemunerationInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
};

export type FilterFindManyResearchInput = {
  title?: Maybe<Scalars['String']>;
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidityInput>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyResearchInput>;
  OR?: Maybe<Array<FilterFindManyResearchInput>>;
  AND?: Maybe<Array<FilterFindManyResearchInput>>;
};

export type FilterFindManyRoleGroupInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdmInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRoleGroupInput>;
  OR?: Maybe<Array<FilterFindManyRoleGroupInput>>;
  AND?: Maybe<Array<FilterFindManyRoleGroupInput>>;
};

export type FilterFindManySearchCountInput = {
  _id?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManySearchCountInput>;
  OR?: Maybe<Array<FilterFindManySearchCountInput>>;
  AND?: Maybe<Array<FilterFindManySearchCountInput>>;
};

export type FilterFindManyShareInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyShareInput>;
  OR?: Maybe<Array<FilterFindManyShareInput>>;
  AND?: Maybe<Array<FilterFindManyShareInput>>;
};

export type FilterFindManyUserCreditOperationInput = {
  userId?: Maybe<Scalars['String']>;
  op?: Maybe<EnumUserCreditOperationOp>;
  value?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EnumUserCreditOperationStatus>;
  externalExtractId?: Maybe<Scalars['String']>;
  externalRequestNumber?: Maybe<Scalars['Float']>;
  creditOriginCampaignData?: Maybe<
    UserCreditOperationCreditOriginCampaignDataInput
  >;
  userInformation?: Maybe<UserCreditOperationUserInformationInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserCreditOperationInput>;
  OR?: Maybe<Array<FilterFindManyUserCreditOperationInput>>;
  AND?: Maybe<Array<FilterFindManyUserCreditOperationInput>>;
};

export type FilterFindManyUserDeletedInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotificationsInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserDeletedInput>;
  OR?: Maybe<Array<FilterFindManyUserDeletedInput>>;
  AND?: Maybe<Array<FilterFindManyUserDeletedInput>>;
};

export type FilterFindManyUserHistoryBlockInput = {
  /** Responsável pelo bloqueio */
  owner?: Maybe<Scalars['String']>;
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserHistoryBlockInput>;
  OR?: Maybe<Array<FilterFindManyUserHistoryBlockInput>>;
  AND?: Maybe<Array<FilterFindManyUserHistoryBlockInput>>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
};

export type FilterFindManyUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  reconhece?: Maybe<UserReconheceInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  tour?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserInput>;
  OR?: Maybe<Array<FilterFindManyUserInput>>;
  AND?: Maybe<Array<FilterFindManyUserInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que NÃO derem match em um dos elementos do array informado */
  ninChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyUserWordsBlackListInput = {
  owner?: Maybe<Scalars['String']>;
  word?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserWordsBlackListInput>;
  OR?: Maybe<Array<FilterFindManyUserWordsBlackListInput>>;
  AND?: Maybe<Array<FilterFindManyUserWordsBlackListInput>>;
};

export type FilterFindOneBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneBannerInput>;
  OR?: Maybe<Array<FilterFindOneBannerInput>>;
  AND?: Maybe<Array<FilterFindOneBannerInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindOneCampaignContestationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  protocol?: Maybe<Scalars['Float']>;
  indicator?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  response?: Maybe<CampaignContestationResponseInput>;
  isResultContest?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignContestationInput>;
  OR?: Maybe<Array<FilterFindOneCampaignContestationInput>>;
  AND?: Maybe<Array<FilterFindOneCampaignContestationInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por usuario */
  user?: Maybe<UserFilterFilter>;
  status?: Maybe<ContestationStatusFilterEnum>;
  protocolSearch?: Maybe<Scalars['String']>;
};

export type FilterFindOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCategoryInput>;
  OR?: Maybe<Array<FilterFindOneCategoryInput>>;
  AND?: Maybe<Array<FilterFindOneCategoryInput>>;
};

export type FilterFindOneChannelInput = {
  _id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneChannelInput>;
  OR?: Maybe<Array<FilterFindOneChannelInput>>;
  AND?: Maybe<Array<FilterFindOneChannelInput>>;
};

export type FilterFindOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCommentInput>;
  OR?: Maybe<Array<FilterFindOneCommentInput>>;
  AND?: Maybe<Array<FilterFindOneCommentInput>>;
};

export type FilterFindOneEmailInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadByInput>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnReadInput>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEmailInput>;
  OR?: Maybe<Array<FilterFindOneEmailInput>>;
  AND?: Maybe<Array<FilterFindOneEmailInput>>;
};

export type FilterFindOneEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEmailStatsInput>;
  OR?: Maybe<Array<FilterFindOneEmailStatsInput>>;
  AND?: Maybe<Array<FilterFindOneEmailStatsInput>>;
};

export type FilterFindOneEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEventLiveInput>;
  OR?: Maybe<Array<FilterFindOneEventLiveInput>>;
  AND?: Maybe<Array<FilterFindOneEventLiveInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindOneIncentiveCampaignInput = {
  owner?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumIncentiveCampaignStatus>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriodInput>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContentInput>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin?: Maybe<Scalars['String']>;
  /** _id da carteira */
  walletId?: Maybe<Scalars['String']>;
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidityInput>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneIncentiveCampaignInput>;
  OR?: Maybe<Array<FilterFindOneIncentiveCampaignInput>>;
  AND?: Maybe<Array<FilterFindOneIncentiveCampaignInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Retorna os itens que incluem os status */
  statusIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retorna os itens que estão com os canais/cargos passados no filtro */
  partAvailableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneJobTitleInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneJobTitleInput>;
  OR?: Maybe<Array<FilterFindOneJobTitleInput>>;
  AND?: Maybe<Array<FilterFindOneJobTitleInput>>;
};

export type FilterFindOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneMaterialInput>;
  OR?: Maybe<Array<FilterFindOneMaterialInput>>;
  AND?: Maybe<Array<FilterFindOneMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
};

export type FilterFindOneMeuDiaPdvTableInput = {
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneMeuDiaPdvTableInput>;
  OR?: Maybe<Array<FilterFindOneMeuDiaPdvTableInput>>;
  AND?: Maybe<Array<FilterFindOneMeuDiaPdvTableInput>>;
};

export type FilterFindOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneNotificationInput>;
  OR?: Maybe<Array<FilterFindOneNotificationInput>>;
  AND?: Maybe<Array<FilterFindOneNotificationInput>>;
};

export type FilterFindOneRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRegionInput>;
  OR?: Maybe<Array<FilterFindOneRegionInput>>;
  AND?: Maybe<Array<FilterFindOneRegionInput>>;
};

export type FilterFindOneRegionItemInput = {
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRegionItemInput>;
  OR?: Maybe<Array<FilterFindOneRegionItemInput>>;
  AND?: Maybe<Array<FilterFindOneRegionItemInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneRemunerationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  remunerationId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRemunerationInput>;
  OR?: Maybe<Array<FilterFindOneRemunerationInput>>;
  AND?: Maybe<Array<FilterFindOneRemunerationInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindOneRoleGroupInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdmInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRoleGroupInput>;
  OR?: Maybe<Array<FilterFindOneRoleGroupInput>>;
  AND?: Maybe<Array<FilterFindOneRoleGroupInput>>;
};

export type FilterFindOneUserContentPermissionInput = {
  userId?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteContentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserContentPermissionInput>;
  OR?: Maybe<Array<FilterFindOneUserContentPermissionInput>>;
  AND?: Maybe<Array<FilterFindOneUserContentPermissionInput>>;
};

export type FilterFindOneUserDeletedInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotificationsInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserDeletedInput>;
  OR?: Maybe<Array<FilterFindOneUserDeletedInput>>;
  AND?: Maybe<Array<FilterFindOneUserDeletedInput>>;
};

export type FilterFindOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  reconhece?: Maybe<UserReconheceInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  tour?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserInput>;
  OR?: Maybe<Array<FilterFindOneUserInput>>;
  AND?: Maybe<Array<FilterFindOneUserInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que NÃO derem match em um dos elementos do array informado */
  ninChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterMaterialInput>;
  OR?: Maybe<Array<FilterMaterialInput>>;
  AND?: Maybe<Array<FilterMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
};

export type FilterRemoveManyFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveManyFolderInput>;
  OR?: Maybe<Array<FilterRemoveManyFolderInput>>;
  AND?: Maybe<Array<FilterRemoveManyFolderInput>>;
};

export type FilterRemoveManyPinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveManyPinInput>;
  OR?: Maybe<Array<FilterRemoveManyPinInput>>;
  AND?: Maybe<Array<FilterRemoveManyPinInput>>;
};

export type FilterRemoveOneFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneFolderInput>;
  OR?: Maybe<Array<FilterRemoveOneFolderInput>>;
  AND?: Maybe<Array<FilterRemoveOneFolderInput>>;
};

export type FilterRemoveOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneMaterialInput>;
  OR?: Maybe<Array<FilterRemoveOneMaterialInput>>;
  AND?: Maybe<Array<FilterRemoveOneMaterialInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
};

export type FilterRemoveOnePinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOnePinInput>;
  OR?: Maybe<Array<FilterRemoveOnePinInput>>;
  AND?: Maybe<Array<FilterRemoveOnePinInput>>;
};

export type FilterRemoveOneRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneRegionInput>;
  OR?: Maybe<Array<FilterRemoveOneRegionInput>>;
  AND?: Maybe<Array<FilterRemoveOneRegionInput>>;
};

export type FilterRemoveOneShareInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneShareInput>;
  OR?: Maybe<Array<FilterRemoveOneShareInput>>;
  AND?: Maybe<Array<FilterRemoveOneShareInput>>;
};

export type FilterRemunerationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  remunerationId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemunerationInput>;
  OR?: Maybe<Array<FilterRemunerationInput>>;
  AND?: Maybe<Array<FilterRemunerationInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneAnalyticsCommunicationsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsCommunicationsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneAnalyticsCommunicationsInput>;
  OR?: Maybe<Array<FilterUpdateOneAnalyticsCommunicationsInput>>;
  AND?: Maybe<Array<FilterUpdateOneAnalyticsCommunicationsInput>>;
};

export type FilterUpdateOneAnalyticsDocumentsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsDocumentsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneAnalyticsDocumentsInput>;
  OR?: Maybe<Array<FilterUpdateOneAnalyticsDocumentsInput>>;
  AND?: Maybe<Array<FilterUpdateOneAnalyticsDocumentsInput>>;
};

export type FilterUpdateOneAnswerResearchInput = {
  userId?: Maybe<Scalars['String']>;
  researchId?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneAnswerResearchInput>;
  OR?: Maybe<Array<FilterUpdateOneAnswerResearchInput>>;
  AND?: Maybe<Array<FilterUpdateOneAnswerResearchInput>>;
};

export type FilterUpdateOneBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneBannerInput>;
  OR?: Maybe<Array<FilterUpdateOneBannerInput>>;
  AND?: Maybe<Array<FilterUpdateOneBannerInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCategoryInput>;
  OR?: Maybe<Array<FilterUpdateOneCategoryInput>>;
  AND?: Maybe<Array<FilterUpdateOneCategoryInput>>;
};

export type FilterUpdateOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCommentInput>;
  OR?: Maybe<Array<FilterUpdateOneCommentInput>>;
  AND?: Maybe<Array<FilterUpdateOneCommentInput>>;
};

export type FilterUpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneEventLiveInput>;
  OR?: Maybe<Array<FilterUpdateOneEventLiveInput>>;
  AND?: Maybe<Array<FilterUpdateOneEventLiveInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneFolderInput>;
  OR?: Maybe<Array<FilterUpdateOneFolderInput>>;
  AND?: Maybe<Array<FilterUpdateOneFolderInput>>;
};

export type FilterUpdateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneImportUsersPlanInput>;
  OR?: Maybe<Array<FilterUpdateOneImportUsersPlanInput>>;
  AND?: Maybe<Array<FilterUpdateOneImportUsersPlanInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
};

export type FilterUpdateOneIncentiveCampaignInput = {
  owner?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumIncentiveCampaignStatus>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriodInput>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContentInput>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin?: Maybe<Scalars['String']>;
  /** _id da carteira */
  walletId?: Maybe<Scalars['String']>;
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidityInput>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneIncentiveCampaignInput>;
  OR?: Maybe<Array<FilterUpdateOneIncentiveCampaignInput>>;
  AND?: Maybe<Array<FilterUpdateOneIncentiveCampaignInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneMaterialInput>;
  OR?: Maybe<Array<FilterUpdateOneMaterialInput>>;
  AND?: Maybe<Array<FilterUpdateOneMaterialInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os materiais com os termos para busca orgânica */
  termsForMaterialSearch?: Maybe<Scalars['String']>;
};

export type FilterUpdateOneMeuDiaPdvTableInput = {
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneMeuDiaPdvTableInput>;
  OR?: Maybe<Array<FilterUpdateOneMeuDiaPdvTableInput>>;
  AND?: Maybe<Array<FilterUpdateOneMeuDiaPdvTableInput>>;
};

export type FilterUpdateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneNotificationInput>;
  OR?: Maybe<Array<FilterUpdateOneNotificationInput>>;
  AND?: Maybe<Array<FilterUpdateOneNotificationInput>>;
};

export type FilterUpdateOneRemunerationInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  remunerationId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneRemunerationInput>;
  OR?: Maybe<Array<FilterUpdateOneRemunerationInput>>;
  AND?: Maybe<Array<FilterUpdateOneRemunerationInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneResearchInput = {
  title?: Maybe<Scalars['String']>;
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidityInput>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneResearchInput>;
  OR?: Maybe<Array<FilterUpdateOneResearchInput>>;
  AND?: Maybe<Array<FilterUpdateOneResearchInput>>;
};

export type FilterUpdateOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  reconhece?: Maybe<UserReconheceInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  tour?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneUserInput>;
  OR?: Maybe<Array<FilterUpdateOneUserInput>>;
  AND?: Maybe<Array<FilterUpdateOneUserInput>>;
};

export type Folder = {
  __typename?: 'Folder';
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type FolderPagination = {
  __typename?: 'FolderPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Folder>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type FutureOperation = {
  __typename?: 'FutureOperation';
  userCreditOperationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  operation?: Maybe<EnumFutureOperationOperation>;
  /** Referências da collection de userCreditOperation para saber quais os débitos foram usados para zerar o valor de pointsLeft */
  userDebitOperations?: Maybe<Array<Maybe<FutureOperationUserDebitOperations>>>;
  pointsLeft?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FutureOperationUserDebitOperations = {
  __typename?: 'FutureOperationUserDebitOperations';
  debitedPoints?: Maybe<Scalars['Float']>;
  userIdCreditOperation?: Maybe<Scalars['String']>;
};

export type History = {
  __typename?: 'History';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<HistoryFact>>>;
};

export type HistoryFact = {
  __typename?: 'HistoryFact';
  date?: Maybe<Scalars['Date']>;
  author?: Maybe<Author>;
  updates?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type ImportUsersPlan = {
  __typename?: 'ImportUsersPlan';
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  file?: Maybe<File>;
};

/** List of items with pagination. */
export type ImportUsersPlanPagination = {
  __typename?: 'ImportUsersPlanPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type IncentiveCampaign = {
  __typename?: 'IncentiveCampaign';
  owner?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<IncentiveCampaignStatusOption>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriod>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContent>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin?: Maybe<Scalars['String']>;
  /** _id da carteira */
  walletId?: Maybe<Scalars['String']>;
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidity>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidity>;
  likes?: Maybe<Likes>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
  cardImageFile?: Maybe<File>;
  bannerContentFile?: Maybe<File>;
  regulationFile?: Maybe<File>;
  presentationFile?: Maybe<File>;
  paymentFileId?: Maybe<File>;
  views?: Maybe<MaterialViews>;
};

export type IncentiveCampaignCampaignValidity = {
  __typename?: 'IncentiveCampaignCampaignValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type IncentiveCampaignCampaignValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type IncentiveCampaignContestationPeriod = {
  __typename?: 'IncentiveCampaignContestationPeriod';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type IncentiveCampaignContestationPeriodInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type IncentiveCampaignFileContent = {
  __typename?: 'IncentiveCampaignFileContent';
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  partner?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type IncentiveCampaignFileContentInput = {
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  partner?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type IncentiveCampaignPagination = {
  __typename?: 'IncentiveCampaignPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<IncentiveCampaign>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type IncentiveCampaignStatusOption = {
  __typename?: 'IncentiveCampaignStatusOption';
  label: Scalars['String'];
  _id: Scalars['String'];
  value: EnumIncentiveCampaignStatus;
  description: Scalars['String'];
};

export type IncentiveCampaignValidity = {
  __typename?: 'IncentiveCampaignValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type IncentiveCampaignValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type JobTitle = {
  __typename?: 'JobTitle';
  _id: Scalars['String'];
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type KindOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type Likes = {
  __typename?: 'Likes';
  liked: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type LiveAuthor = {
  __typename?: 'LiveAuthor';
  name: Scalars['String'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
  iat: Scalars['Float'];
};

export type Material = {
  __typename?: 'Material';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidity>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories: Array<Maybe<Category>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<MaterialStatusOption>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfig>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Editor de documentos HTML */
  editor?: Maybe<Scalars['JSON']>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Likes>;
  changedBy?: Maybe<MaterialChangedBy>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfile>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  sameCategoryItems?: Maybe<MaterialPagination>;
  validityEnum?: Maybe<ValidityEnum>;
  kindName?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
  awaitingUsersApprover?: Maybe<Array<Maybe<AwaitingUsersApprover>>>;
  /** Retorna true se o usuário atual é o criador do mesmo */
  isOwner: Scalars['Boolean'];
  mainCategory?: Maybe<Category>;
  /** O usuário que criou o material (o mesmo que solicitante) */
  author?: Maybe<Author>;
  /** Ids dos materiais que serão listados no boletim */
  bulletinMaterials?: Maybe<Array<Maybe<Material>>>;
  email?: Maybe<Email>;
  sentEmail?: Maybe<SentEmailHtml>;
  views?: Maybe<MaterialViews>;
  /** Array de modificações no formato http://jsonpatch.com/#operations */
  history?: Maybe<History>;
  /** Config do template para exibição no feed da diretoria Residencial */
  templateConfig?: Maybe<TemplateConfigCommunication>;
};

export type MaterialSameCategoryItemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type MaterialFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type MaterialMainCategoryArgs = {
  sort?: Maybe<SortMenuEnum>;
  filter?: Maybe<Filter>;
};

export type MaterialEmailArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailInput>;
};

export type MaterialChangedBy = {
  __typename?: 'MaterialChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type MaterialChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type MaterialCommunicationItemsConfig = {
  __typename?: 'MaterialCommunicationItemsConfig';
  kind?: Maybe<EnumMaterialCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplateProps>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialCommunicationItemsConfigInput = {
  kind?: Maybe<EnumMaterialCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplatePropsInput>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialCommunicationItemsConfigTemplateProps = {
  __typename?: 'MaterialCommunicationItemsConfigTemplateProps';
  html?: Maybe<Scalars['String']>;
  HEADER?: Maybe<Scalars['String']>;
  FOOTER?: Maybe<Scalars['String']>;
  TITLE_1?: Maybe<Scalars['String']>;
  TITLE_2?: Maybe<Scalars['String']>;
  TITLE_3?: Maybe<Scalars['String']>;
  TEXT_1?: Maybe<Scalars['String']>;
  TEXT_2?: Maybe<Scalars['String']>;
  TEXT_3?: Maybe<Scalars['String']>;
  TEXT_4?: Maybe<Scalars['String']>;
  BUTTON_TITLE?: Maybe<Scalars['String']>;
  BUTTON_LINK?: Maybe<Scalars['String']>;
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
  HIDE_BUTTON?: Maybe<Scalars['Boolean']>;
};

export type MaterialCommunicationItemsConfigTemplatePropsInput = {
  html?: Maybe<Scalars['String']>;
  HEADER?: Maybe<Scalars['String']>;
  FOOTER?: Maybe<Scalars['String']>;
  TITLE_1?: Maybe<Scalars['String']>;
  TITLE_2?: Maybe<Scalars['String']>;
  TITLE_3?: Maybe<Scalars['String']>;
  TEXT_1?: Maybe<Scalars['String']>;
  TEXT_2?: Maybe<Scalars['String']>;
  TEXT_3?: Maybe<Scalars['String']>;
  TEXT_4?: Maybe<Scalars['String']>;
  BUTTON_TITLE?: Maybe<Scalars['String']>;
  BUTTON_LINK?: Maybe<Scalars['String']>;
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
  HIDE_BUTTON?: Maybe<Scalars['Boolean']>;
};

/** List of items with pagination. */
export type MaterialPagination = {
  __typename?: 'MaterialPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Material>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type MaterialProfile = {
  __typename?: 'MaterialProfile';
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MaterialProfileInput = {
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MaterialSearch = {
  __typename?: 'MaterialSearch';
  pageInfo: PaginationInfo;
  items?: Maybe<Array<Maybe<Material>>>;
  count?: Maybe<Scalars['Int']>;
  autocomplete?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialSearchOwners = {
  __typename?: 'MaterialSearchOwners';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type MaterialStatusOption = {
  __typename?: 'MaterialStatusOption';
  label: Scalars['String'];
  _id: Scalars['String'];
  value: EnumMaterialStatus;
  description: Scalars['String'];
};

export type MaterialValidity = {
  __typename?: 'MaterialValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialViews = {
  __typename?: 'MaterialViews';
  count: Scalars['Int'];
};

export type MeuDiaPdv = {
  __typename?: 'MeuDiaPdv';
  nome?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  vaga?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  diretoria?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type MeuDiaPdvTable = {
  __typename?: 'MeuDiaPdvTable';
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type MeuPdvReturn = {
  __typename?: 'MeuPdvReturn';
  table?: Maybe<Array<Maybe<MeuDiaPdvTable>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EventCreateOne?: Maybe<CreateOneEventPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  EventUpdateById?: Maybe<UpdateByIdEventPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  EventRemoveById?: Maybe<RemoveByIdEventPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FeedCreateOne?: Maybe<CreateOneFeedPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FeedUpdateById?: Maybe<UpdateByIdFeedPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  FeedRemoveById?: Maybe<RemoveByIdFeedPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  MeuDiaPdvTableCreateOne?: Maybe<CreateOneMeuDiaPdvTablePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MeuDiaPdvTableUpdateOne?: Maybe<UpdateOneMeuDiaPdvTablePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  MeuDiaPdvTableRemoveById?: Maybe<RemoveByIdMeuDiaPdvTablePayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  MeuDiaPdvTableCreateMany?: Maybe<CreateManyMeuDiaPdvTablePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  IncentiveCampaignCreateOne?: Maybe<CreateOneIncentiveCampaignPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  IncentiveCampaignUpdateOne?: Maybe<UpdateOneIncentiveCampaignPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  IncentiveCampaignRemoveById?: Maybe<RemoveByIdIncentiveCampaignPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CampaignContestationCreateOne?: Maybe<CreateOneCampaignContestationPayload>;
  CampaignContestationResolve?: Maybe<CampaignContestation>;
  IncentiveCampaignPayPoints?: Maybe<Scalars['Boolean']>;
  ReconheceReversePoints?: Maybe<Scalars['Boolean']>;
  ReconhecePayExtraPoints?: Maybe<Scalars['Boolean']>;
  IncentiveCampaignLikeToggle?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ResearchCreateOne?: Maybe<CreateOneResearchPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ResearchUpdateOne?: Maybe<UpdateOneResearchPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ResearchUpdateById?: Maybe<UpdateByIdResearchPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  ResearchRemoveById?: Maybe<RemoveByIdResearchPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AnswerResearchCreateOne?: Maybe<CreateOneAnswerResearchPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnswerResearchUpdateOne?: Maybe<UpdateOneAnswerResearchPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnswerResearchUpdateById?: Maybe<UpdateByIdAnswerResearchPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  AnswerResearchRemoveById?: Maybe<RemoveByIdAnswerResearchPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EmailTemplateCreateOne?: Maybe<CreateOneEmailTemplatePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  EmailTemplateUpdateById?: Maybe<UpdateByIdEmailTemplatePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  EmailTemplateRemoveById?: Maybe<RemoveByIdEmailTemplatePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AnalyticsDocumentsCreateOne?: Maybe<CreateOneAnalyticsDocumentsPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnalyticsDocumentsUpdateOne?: Maybe<UpdateOneAnalyticsDocumentsPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnalyticsDocumentsUpdateById?: Maybe<UpdateByIdAnalyticsDocumentsPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  AnalyticsDocumentsRemoveById?: Maybe<RemoveByIdAnalyticsDocumentsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AnalyticsCommunicationsCreateOne?: Maybe<
    CreateOneAnalyticsCommunicationsPayload
  >;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnalyticsCommunicationsUpdateOne?: Maybe<
    UpdateOneAnalyticsCommunicationsPayload
  >;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AnalyticsCommunicationsUpdateById?: Maybe<
    UpdateByIdAnalyticsCommunicationsPayload
  >;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  AnalyticsCommunicationsRemoveById?: Maybe<
    RemoveByIdAnalyticsCommunicationsPayload
  >;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  RegionRemoveOne?: Maybe<RemoveOneRegionPayload>;
  UserContentPermissionToggle?: Maybe<Scalars['Boolean']>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UserUpdateOne?: Maybe<UpdateOneUserPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  UserRemoveById?: Maybe<RemoveByIdUserPayload>;
  UserAcceptTerm?: Maybe<Scalars['Boolean']>;
  UserLoginWithPassword?: Maybe<User>;
  UserLoginWithToken?: Maybe<User>;
  UserLoginRenewToken?: Maybe<User>;
  UserSaveLogoutAnalytics?: Maybe<Scalars['Boolean']>;
  UserSaveLoginAnalyticsPCS?: Maybe<Scalars['Boolean']>;
  UserFillEmail?: Maybe<Scalars['Boolean']>;
  UserFillAvatarId?: Maybe<Scalars['Boolean']>;
  UserOptIn?: Maybe<Scalars['Boolean']>;
  UserMarkNotificationAsRead?: Maybe<Scalars['Boolean']>;
  UserDeleteNotification?: Maybe<Scalars['String']>;
  UserDeleteManyNotification?: Maybe<Array<Maybe<Scalars['String']>>>;
  UserRegistration?: Maybe<UserRegistrationPayload>;
  UserBlockComments?: Maybe<Scalars['Boolean']>;
  ChangeToUserTaNarede?: Maybe<UserTaNaRede>;
  UserChangePassword?: Maybe<Scalars['Boolean']>;
  UserFillEmailSecondary?: Maybe<Scalars['Boolean']>;
  UserConnectMovidesk?: Maybe<Scalars['String']>;
  UserDisable?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FileCreateOne?: Maybe<CreateOneFilePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  FileRemoveById?: Maybe<RemoveByIdFilePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FileUpdateById?: Maybe<UpdateByIdFilePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  MaterialCreateOne?: Maybe<CreateOneMaterialPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MaterialUpdateOne?: Maybe<UpdateOneMaterialPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MaterialUpdateById?: Maybe<UpdateByIdMaterialPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  MaterialRemoveOne?: Maybe<RemoveOneMaterialPayload>;
  CommunicationCreateOne?: Maybe<CreateOneMaterialPayload>;
  CommunicationUpdateOne?: Maybe<UpdateOneMaterialPayload>;
  CommunicationApproveChannel?: Maybe<Scalars['Boolean']>;
  CommunicationDisapproveChannel?: Maybe<Scalars['Boolean']>;
  CommunicationRemoveApprover?: Maybe<Scalars['Boolean']>;
  EmailFillTemplate: Scalars['String'];
  /** Marca um email como lido pelo destinatario informado pelo token recebido no e-mail */
  EmailMarkAsRead?: Maybe<Scalars['Boolean']>;
  SendEmailFront?: Maybe<Scalars['Boolean']>;
  MaterialSendTestEmail: Scalars['Boolean'];
  MaterialLikeToggle?: Maybe<Scalars['Boolean']>;
  UpdateDeteachedAndLatestVideo?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  NotificationCreateOne?: Maybe<CreateOneNotificationPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  NotificationCreateMany?: Maybe<CreateManyNotificationPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  NotificationUpdateOne?: Maybe<UpdateOneNotificationPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  NotificationRemoveById?: Maybe<RemoveByIdNotificationPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CategoryCreateOne?: Maybe<CreateOneCategoryPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  CategoryUpdateOne?: Maybe<UpdateOneCategoryPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  CategoryRemoveById?: Maybe<RemoveByIdCategoryPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  CategoryCreateMany?: Maybe<CreateManyCategoryPayload>;
  /** Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario. */
  WebPushSubscribe?: Maybe<Scalars['Boolean']>;
  /** Envia uma web push notification para uma lista de userIds */
  WebPushSendNotification?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PinCreateOne?: Maybe<CreateOnePinPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  PinRemoveOne?: Maybe<RemoveOnePinPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  PinRemoveMany?: Maybe<RemoveManyPinPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ShareCreateOne?: Maybe<CreateOneSharePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  ShareRemoveOne?: Maybe<RemoveOneSharePayload>;
  /** Remove o usuário atual de um compartilhamento. */
  ShareRemoveMe: Scalars['Boolean'];
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CommentCreateOne?: Maybe<CreateOneCommentPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  CommentUpdateOne?: Maybe<UpdateOneCommentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  CommentRemoveById?: Maybe<RemoveByIdCommentPayload>;
  CommentLikeToggle?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EventLiveCreateOne?: Maybe<CreateOneEventLivePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  EventLiveRemoveById?: Maybe<RemoveByIdEventLivePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  EventLiveUpdateOne?: Maybe<UpdateOneEventLivePayload>;
  EventLiveCloseRoom?: Maybe<Scalars['Boolean']>;
  EventLiveLikeToggle?: Maybe<Scalars['Boolean']>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  BannerRemoveById?: Maybe<RemoveByIdBannerPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  BannerCreateOne?: Maybe<CreateOneBannerPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  BannerUpdateOne?: Maybe<UpdateOneBannerPayload>;
  AnalyticsPageView?: Maybe<Scalars['Boolean']>;
  AnalyticsTrackEvent?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FolderCreateOne?: Maybe<CreateOneFolderPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  FolderRemoveOne?: Maybe<RemoveOneFolderPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  FolderRemoveMany?: Maybe<RemoveManyFolderPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FolderUpdateOne?: Maybe<UpdateOneFolderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  RecentsActivitiesCreateOne?: Maybe<CreateOneRecentsActivitiesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  UserWordsBlackListCreateOne?: Maybe<CreateOneUserWordsBlackListPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ImportUsersPlanCreateOne?: Maybe<CreateOneImportUsersPlanPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ImportUsersPlanUpdateOne?: Maybe<UpdateOneImportUsersPlanPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  UserHistoryBlockCreateOne?: Maybe<CreateOneUserHistoryBlockPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  EmailStatsCreateMany?: Maybe<CreateManyEmailStatsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EmailStatsCreateOne?: Maybe<CreateOneEmailStatsPayload>;
  UserCorpCheck?: Maybe<UserCorpCheckPayload>;
  MeuPdvParamsMutation?: Maybe<MeuDiaPdv>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  RemunerationCreateOne?: Maybe<CreateOneRemunerationPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  RemunerationUpdateOne?: Maybe<UpdateOneRemunerationPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  RemunerationRemoveById?: Maybe<RemoveByIdRemunerationPayload>;
  RemunerationLikeToggle?: Maybe<Scalars['Boolean']>;
  UserTourMutation?: Maybe<Scalars['Boolean']>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  ReconheceWalletRemoveById?: Maybe<RemoveByIdReconheceWalletPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ReconheceWalletCreateOne?: Maybe<CreateOneReconheceWalletPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ReconheceWalletUpdateById?: Maybe<UpdateByIdReconheceWalletPayload>;
  SetWarningAt?: Maybe<WarningAtResult>;
  /** Cria um "one time roken" que será enviado via url ao Reconhece */
  ReconheceCreateTempToken?: Maybe<Scalars['String']>;
};

export type MutationEventCreateOneArgs = {
  record: CreateOneEventInput;
  sort?: Maybe<Sort>;
};

export type MutationEventUpdateByIdArgs = {
  record: UpdateByIdEventInput;
  sort?: Maybe<Sort>;
};

export type MutationEventRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<Sort>;
};

export type MutationFeedCreateOneArgs = {
  record: CreateOneFeedInput;
  sort?: Maybe<Sort>;
};

export type MutationFeedUpdateByIdArgs = {
  record: UpdateByIdFeedInput;
  sort?: Maybe<Sort>;
};

export type MutationFeedRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<Sort>;
};

export type MutationMeuDiaPdvTableCreateOneArgs = {
  record: CreateOneMeuDiaPdvTableInput;
};

export type MutationMeuDiaPdvTableUpdateOneArgs = {
  record: UpdateOneMeuDiaPdvTableInput;
  filter?: Maybe<FilterUpdateOneMeuDiaPdvTableInput>;
  sort?: Maybe<SortUpdateOneMeuDiaPdvTableInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationMeuDiaPdvTableRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationMeuDiaPdvTableCreateManyArgs = {
  records: Array<CreateManyMeuDiaPdvTableInput>;
};

export type MutationIncentiveCampaignCreateOneArgs = {
  record: CreateOneIncentiveCampaignInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationIncentiveCampaignUpdateOneArgs = {
  record: UpdateOneIncentiveCampaignInput;
  filter?: Maybe<FilterUpdateOneIncentiveCampaignInput>;
  sort?: Maybe<SortUpdateOneIncentiveCampaignInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationIncentiveCampaignRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationCampaignContestationCreateOneArgs = {
  record: CreateOneCampaignContestationInput;
  filter?: Maybe<Filter>;
};

export type MutationCampaignContestationResolveArgs = {
  _id: Scalars['String'];
  title: Scalars['String'];
  status: EnumCampaignContestationResponseStatus;
  body: Scalars['String'];
  responseFileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationIncentiveCampaignPayPointsArgs = {
  campaignId: Scalars['String'];
  paymentFileId: Scalars['String'];
};

export type MutationReconheceReversePointsArgs = {
  paymentFileId: Scalars['String'];
  campaignId: Scalars['String'];
  reason: Scalars['String'];
};

export type MutationReconhecePayExtraPointsArgs = {
  paymentFileId: Scalars['String'];
  campaignId: Scalars['String'];
};

export type MutationIncentiveCampaignLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationResearchCreateOneArgs = {
  record: CreateOneResearchInput;
};

export type MutationResearchUpdateOneArgs = {
  record: UpdateOneResearchInput;
  filter?: Maybe<FilterUpdateOneResearchInput>;
  sort?: Maybe<SortUpdateOneResearchInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationResearchUpdateByIdArgs = {
  record: UpdateByIdResearchInput;
};

export type MutationResearchRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationAnswerResearchCreateOneArgs = {
  record: CreateOneAnswerResearchInput;
};

export type MutationAnswerResearchUpdateOneArgs = {
  record: UpdateOneAnswerResearchInput;
  filter?: Maybe<FilterUpdateOneAnswerResearchInput>;
  sort?: Maybe<SortUpdateOneAnswerResearchInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationAnswerResearchUpdateByIdArgs = {
  record: UpdateByIdAnswerResearchInput;
};

export type MutationAnswerResearchRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationEmailTemplateCreateOneArgs = {
  record: CreateOneEmailTemplateInput;
  sort?: Maybe<Sort>;
};

export type MutationEmailTemplateUpdateByIdArgs = {
  record: UpdateByIdEmailTemplateInput;
  sort?: Maybe<Sort>;
};

export type MutationEmailTemplateRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<Sort>;
};

export type MutationAnalyticsDocumentsCreateOneArgs = {
  record: CreateOneAnalyticsDocumentsInput;
};

export type MutationAnalyticsDocumentsUpdateOneArgs = {
  record: UpdateOneAnalyticsDocumentsInput;
  filter?: Maybe<FilterUpdateOneAnalyticsDocumentsInput>;
  sort?: Maybe<SortUpdateOneAnalyticsDocumentsInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationAnalyticsDocumentsUpdateByIdArgs = {
  record: UpdateByIdAnalyticsDocumentsInput;
};

export type MutationAnalyticsDocumentsRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationAnalyticsCommunicationsCreateOneArgs = {
  record: CreateOneAnalyticsCommunicationsInput;
};

export type MutationAnalyticsCommunicationsUpdateOneArgs = {
  record: UpdateOneAnalyticsCommunicationsInput;
  filter?: Maybe<FilterUpdateOneAnalyticsCommunicationsInput>;
  sort?: Maybe<SortUpdateOneAnalyticsCommunicationsInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationAnalyticsCommunicationsUpdateByIdArgs = {
  record: UpdateByIdAnalyticsCommunicationsInput;
};

export type MutationAnalyticsCommunicationsRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationRegionRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneRegionInput>;
  sort?: Maybe<SortRemoveOneRegionInput>;
};

export type MutationUserContentPermissionToggleArgs = {
  userId: Scalars['String'];
  roleGroups: Array<Maybe<Scalars['String']>>;
  regions: Array<Maybe<Scalars['String']>>;
  contentTypes: Array<Maybe<Scalars['String']>>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
};

export type MutationUserUpdateOneArgs = {
  record: UpdateOneUserInput;
  filter?: Maybe<FilterUpdateOneUserInput>;
  sort?: Maybe<SortUpdateOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationUserRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationUserAcceptTermArgs = {
  term: Scalars['String'];
};

export type MutationUserLoginWithPasswordArgs = {
  user: Scalars['String'];
  password: Scalars['String'];
  platformName?: Maybe<PlatformNamesEnum>;
};

export type MutationUserLoginWithTokenArgs = {
  token: Scalars['String'];
  platformName?: Maybe<PlatformNamesEnum>;
};

export type MutationUserLoginRenewTokenArgs = {
  token: Scalars['String'];
};

export type MutationUserFillEmailArgs = {
  email: Scalars['String'];
};

export type MutationUserFillAvatarIdArgs = {
  avatarId: Scalars['String'];
};

export type MutationUserOptInArgs = {
  accept: Scalars['Boolean'];
};

export type MutationUserMarkNotificationAsReadArgs = {
  isRead: Scalars['Boolean'];
  notifications: Array<Maybe<Scalars['String']>>;
};

export type MutationUserDeleteNotificationArgs = {
  notificationId: Scalars['String'];
};

export type MutationUserDeleteManyNotificationArgs = {
  notificationIds?: Maybe<Array<Scalars['String']>>;
};

export type MutationUserRegistrationArgs = {
  record: UserRegistrationTypeInput;
};

export type MutationUserBlockCommentsArgs = {
  userId: Scalars['String'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
};

export type MutationUserChangePasswordArgs = {
  newPassword: Scalars['String'];
};

export type MutationUserFillEmailSecondaryArgs = {
  emailSecondary: Scalars['String'];
};

export type MutationUserDisableArgs = {
  _id: Scalars['String'];
};

export type MutationFileCreateOneArgs = {
  record: CreateOneFileInput;
};

export type MutationFileRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationFileUpdateByIdArgs = {
  record: UpdateByIdFileInput;
};

export type MutationMaterialCreateOneArgs = {
  record: CreateOneMaterialInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationMaterialUpdateOneArgs = {
  record: UpdateOneMaterialInput;
  filter?: Maybe<FilterUpdateOneMaterialInput>;
  sort?: Maybe<SortUpdateOneMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationMaterialUpdateByIdArgs = {
  record: UpdateByIdMaterialInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationMaterialRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneMaterialInput>;
  sort?: Maybe<SortRemoveOneMaterialInput>;
};

export type MutationCommunicationCreateOneArgs = {
  record: CreateOneMaterialInput;
};

export type MutationCommunicationUpdateOneArgs = {
  communicationId: Scalars['String'];
  record?: Maybe<UpdateOneMaterialInput>;
};

export type MutationCommunicationApproveChannelArgs = {
  recordId: Scalars['String'];
};

export type MutationCommunicationDisapproveChannelArgs = {
  recordId: Scalars['String'];
  message: Scalars['String'];
};

export type MutationCommunicationRemoveApproverArgs = {
  communicationId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationEmailFillTemplateArgs = {
  template?: Maybe<EmailTemplateNames>;
  props?: Maybe<MaterialCommunicationItemsConfigTemplatePropsInput>;
};

export type MutationEmailMarkAsReadArgs = {
  token: Scalars['String'];
};

export type MutationSendEmailFrontArgs = {
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  attach?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationMaterialSendTestEmailArgs = {
  recordId: Scalars['String'];
};

export type MutationMaterialLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationUpdateDeteachedAndLatestVideoArgs = {
  idDetachedVideo?: Maybe<Scalars['String']>;
  idLatestVideo1?: Maybe<Scalars['String']>;
  idLatestVideo2?: Maybe<Scalars['String']>;
  idLatestVideo3?: Maybe<Scalars['String']>;
};

export type MutationNotificationCreateOneArgs = {
  record: CreateOneNotificationInput;
};

export type MutationNotificationCreateManyArgs = {
  records: Array<CreateManyNotificationInput>;
};

export type MutationNotificationUpdateOneArgs = {
  record: UpdateOneNotificationInput;
  filter?: Maybe<FilterUpdateOneNotificationInput>;
  sort?: Maybe<SortUpdateOneNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationNotificationRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationCategoryCreateOneArgs = {
  record: CreateOneCategoryInput;
  filter?: Maybe<Filter>;
};

export type MutationCategoryUpdateOneArgs = {
  record: UpdateOneCategoryInput;
  filter?: Maybe<FilterUpdateOneCategoryInput>;
  sort?: Maybe<SortUpdateOneCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationCategoryRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationCategoryCreateManyArgs = {
  records: Array<CreateManyCategoryInput>;
  filter?: Maybe<Filter>;
};

export type MutationWebPushSubscribeArgs = {
  subscription: Scalars['JSON'];
};

export type MutationWebPushSendNotificationArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  notificationOptions: Scalars['JSON'];
  sync?: Maybe<Scalars['Boolean']>;
};

export type MutationPinCreateOneArgs = {
  record: CreateOnePinInput;
};

export type MutationPinRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOnePinInput>;
  sort?: Maybe<SortRemoveOnePinInput>;
};

export type MutationPinRemoveManyArgs = {
  filter: FilterRemoveManyPinInput;
};

export type MutationShareCreateOneArgs = {
  record: CreateOneShareInput;
};

export type MutationShareRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneShareInput>;
  sort?: Maybe<SortRemoveOneShareInput>;
};

export type MutationShareRemoveMeArgs = {
  shareId?: Maybe<Scalars['String']>;
};

export type MutationCommentCreateOneArgs = {
  record: CreateOneCommentInput;
};

export type MutationCommentUpdateOneArgs = {
  record: UpdateOneCommentInput;
  filter?: Maybe<FilterUpdateOneCommentInput>;
  sort?: Maybe<SortUpdateOneCommentInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationCommentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationCommentLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationEventLiveCreateOneArgs = {
  record: CreateOneEventLiveInput;
  filter?: Maybe<Filter>;
};

export type MutationEventLiveRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationEventLiveUpdateOneArgs = {
  record: UpdateOneEventLiveInput;
  filter?: Maybe<FilterUpdateOneEventLiveInput>;
  sort?: Maybe<SortUpdateOneEventLiveInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationEventLiveCloseRoomArgs = {
  eventId: Scalars['String'];
};

export type MutationEventLiveLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationBannerRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationBannerCreateOneArgs = {
  record: CreateOneBannerInput;
  filter?: Maybe<Filter>;
};

export type MutationBannerUpdateOneArgs = {
  record: UpdateOneBannerInput;
  filter?: Maybe<FilterUpdateOneBannerInput>;
  sort?: Maybe<SortUpdateOneBannerInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationAnalyticsPageViewArgs = {
  title: Scalars['String'];
  host?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
};

export type MutationAnalyticsTrackEventArgs = {
  event: EnumAnalyticsEvent;
  kind: EnumAnalyticsParentKind;
  recordId?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MutationFolderCreateOneArgs = {
  record: CreateOneFolderInput;
};

export type MutationFolderRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneFolderInput>;
  sort?: Maybe<SortRemoveOneFolderInput>;
};

export type MutationFolderRemoveManyArgs = {
  filter: FilterRemoveManyFolderInput;
};

export type MutationFolderUpdateOneArgs = {
  record: UpdateOneFolderInput;
  filter?: Maybe<FilterUpdateOneFolderInput>;
  sort?: Maybe<SortUpdateOneFolderInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationRecentsActivitiesCreateOneArgs = {
  record: CreateOneRecentsActivitiesInput;
};

export type MutationUserWordsBlackListCreateOneArgs = {
  record: CreateOneUserWordsBlackListInput;
};

export type MutationImportUsersPlanCreateOneArgs = {
  record: CreateOneImportUsersPlanInput;
  filter?: Maybe<Filter>;
};

export type MutationImportUsersPlanUpdateOneArgs = {
  record: UpdateOneImportUsersPlanInput;
  filter?: Maybe<FilterUpdateOneImportUsersPlanInput>;
  sort?: Maybe<SortUpdateOneImportUsersPlanInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationUserHistoryBlockCreateOneArgs = {
  record: CreateOneUserHistoryBlockInput;
  sort?: Maybe<Sort>;
};

export type MutationEmailStatsCreateManyArgs = {
  records: Array<CreateManyEmailStatsInput>;
};

export type MutationEmailStatsCreateOneArgs = {
  record: CreateOneEmailStatsInput;
};

export type MutationUserCorpCheckArgs = {
  CPF: Scalars['String'];
};

export type MutationMeuPdvParamsMutationArgs = {
  nome?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  vaga?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  diretoria?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type MutationRemunerationCreateOneArgs = {
  record: CreateOneRemunerationInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationRemunerationUpdateOneArgs = {
  record: UpdateOneRemunerationInput;
  filter?: Maybe<FilterUpdateOneRemunerationInput>;
  sort?: Maybe<SortUpdateOneRemunerationInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationRemunerationRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationRemunerationLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationUserTourMutationArgs = {
  tour?: Maybe<Scalars['String']>;
};

export type MutationReconheceWalletRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationReconheceWalletCreateOneArgs = {
  record: CreateOneReconheceWalletInput;
};

export type MutationReconheceWalletUpdateByIdArgs = {
  record: UpdateByIdReconheceWalletInput;
};

export type MutationSetWarningAtArgs = {
  idUserCreditOperation: Scalars['String'];
};

export type NameOperatorsFilterFindManyChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterRemoveOneRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterUpdateOneUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Notification = {
  __typename?: 'Notification';
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  createdAt?: Maybe<Scalars['Date']>;
  owner?: Maybe<SocialUser>;
  event?: Maybe<EventLive>;
  material?: Maybe<Material>;
  wasRead?: Maybe<Scalars['Boolean']>;
};

export type NotificationEventArgs = {
  filter?: Maybe<Filter>;
};

export type NotificationMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type NotificationPagination = {
  __typename?: 'NotificationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Notifications = {
  __typename?: 'Notifications';
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OldPassword = {
  __typename?: 'OldPassword';
  password: Scalars['String'];
  createdAt: Scalars['Date'];
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterAnalyticsInput = {
  day?: Maybe<DayOperatorsFilterAnalyticsInput>;
  userId?: Maybe<UserIdOperatorsFilterAnalyticsInput>;
  event?: Maybe<EventOperatorsFilterAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterAnalyticsInput>;
  createdAt?: Maybe<CreatedAtOperatorsFilterAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAnalyticsCommunicationsInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAnalyticsCommunicationsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAnalyticsDocumentsInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAnalyticsDocumentsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAnalyticsInput = {
  day?: Maybe<DayOperatorsFilterFindManyAnalyticsInput>;
  userId?: Maybe<UserIdOperatorsFilterFindManyAnalyticsInput>;
  event?: Maybe<EventOperatorsFilterFindManyAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyAnalyticsInput>;
  createdAt?: Maybe<CreatedAtOperatorsFilterFindManyAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAnswerResearchInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAnswerResearchInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyBannerInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyBannerInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignContestationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyCampaignContestationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyCampaignContestationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyCampaignContestationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyCampaignContestationInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyCampaignContestationInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyCampaignContestationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindManyCampaignContestationInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignContestationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyCategoryInput>;
  availableAtRoles?: Maybe<
    AvailableAtRolesOperatorsFilterFindManyCategoryInput
  >;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyChannelInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyChannelInput>;
  name?: Maybe<NameOperatorsFilterFindManyChannelInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyCommentInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDownloadSensitiveFilesInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyDownloadSensitiveFilesInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyDownloadSensitiveFilesInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailStatsInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailStatsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailTemplateInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailTemplateInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEventInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyEventInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyEventInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyEventInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyEventInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterFindManyEventInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyEventInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindManyEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterFindManyEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFeedInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyFeedInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFileInput = {
  parentIds?: Maybe<ParentIdsOperatorsFilterFindManyFileInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyFileInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyFolderInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyImportUsersPlanInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyImportUsersPlanInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyIncentiveCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyIncentiveCampaignInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyIncentiveCampaignInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyIncentiveCampaignInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyIncentiveCampaignInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyIncentiveCampaignInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyIncentiveCampaignInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyIncentiveCampaignInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyIncentiveCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyJobTitleInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyJobTitleInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterFindManyMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterFindManyMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyMeuDiaPdvTableInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyMeuDiaPdvTableInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPinInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyPinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterFindManyPinInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyPinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRecentsActivitiesInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyRecentsActivitiesInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyRecentsActivitiesInput>;
  updatedAt?: Maybe<UpdatedAtOperatorsFilterFindManyRecentsActivitiesInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyReconheceInvoiceOriginInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyReconheceInvoiceOriginInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyReconheceTransactionInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyReconheceTransactionInput>;
  owner?: Maybe<OwnerOperatorsFilterFindManyReconheceTransactionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyReconheceWalletInput = {
  createdBy?: Maybe<CreatedByOperatorsFilterFindManyReconheceWalletInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyReconheceWalletInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegionInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegionInput>;
  name?: Maybe<NameOperatorsFilterFindManyRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegionItemInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegionItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRemunerationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyRemunerationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyRemunerationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyRemunerationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyRemunerationInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyRemunerationInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyRemunerationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindManyRemunerationInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyRemunerationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyResearchInput = {
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyResearchInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyResearchInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRoleGroupInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRoleGroupInput>;
  name?: Maybe<NameOperatorsFilterFindManyRoleGroupInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManySearchCountInput = {
  _id?: Maybe<_IdOperatorsFilterFindManySearchCountInput>;
  term?: Maybe<TermOperatorsFilterFindManySearchCountInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyShareInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyShareInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyShareInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserCreditOperationInput = {
  userId?: Maybe<UserIdOperatorsFilterFindManyUserCreditOperationInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserCreditOperationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserDeletedInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyUserDeletedInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserHistoryBlockInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyUserHistoryBlockInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserHistoryBlockInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserInput = {
  email?: Maybe<EmailOperatorsFilterFindManyUserInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserInput>;
  name?: Maybe<NameOperatorsFilterFindManyUserInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserWordsBlackListInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyUserWordsBlackListInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserWordsBlackListInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneBannerInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterFindOneBannerInput>;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindOneBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignContestationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneCampaignContestationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneCampaignContestationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneCampaignContestationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneCampaignContestationInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneCampaignContestationInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneCampaignContestationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindOneCampaignContestationInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignContestationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindOneCategoryInput>;
  availableAtRoles?: Maybe<AvailableAtRolesOperatorsFilterFindOneCategoryInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneChannelInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneChannelInput>;
  name?: Maybe<NameOperatorsFilterFindOneChannelInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneCommentInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEmailInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneEmailInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEmailStatsInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneEmailStatsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindOneEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterFindOneEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneIncentiveCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneIncentiveCampaignInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneIncentiveCampaignInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneIncentiveCampaignInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneIncentiveCampaignInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneIncentiveCampaignInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneIncentiveCampaignInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneIncentiveCampaignInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindOneIncentiveCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneJobTitleInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneJobTitleInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterFindOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterFindOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneMeuDiaPdvTableInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneMeuDiaPdvTableInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRegionInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRegionInput>;
  name?: Maybe<NameOperatorsFilterFindOneRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRegionItemInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRegionItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRemunerationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneRemunerationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneRemunerationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneRemunerationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneRemunerationInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneRemunerationInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneRemunerationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindOneRemunerationInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneRemunerationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRoleGroupInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRoleGroupInput>;
  name?: Maybe<NameOperatorsFilterFindOneRoleGroupInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserContentPermissionInput = {
  userId?: Maybe<UserIdOperatorsFilterFindOneUserContentPermissionInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneUserContentPermissionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserDeletedInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneUserDeletedInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserInput = {
  email?: Maybe<EmailOperatorsFilterFindOneUserInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneUserInput>;
  name?: Maybe<NameOperatorsFilterFindOneUserInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterMaterialInput = {
  availableAtRegions?: Maybe<AvailableAtRegionsOperatorsFilterMaterialInput>;
  availableAtChannels?: Maybe<AvailableAtChannelsOperatorsFilterMaterialInput>;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterMaterialInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterMaterialInput>;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveManyFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveManyFolderInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveManyFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveManyPinInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveManyPinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterRemoveManyPinInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveManyPinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOneFolderInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterRemoveOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterRemoveOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterRemoveOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterRemoveOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterRemoveOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOnePinInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOnePinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterRemoveOnePinInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOnePinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneRegionInput = {
  _id?: Maybe<_IdOperatorsFilterRemoveOneRegionInput>;
  name?: Maybe<NameOperatorsFilterRemoveOneRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneShareInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOneShareInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneShareInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemunerationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterRemunerationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterRemunerationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterRemunerationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterRemunerationInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterRemunerationInput>;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterRemunerationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterRemunerationInput>;
  _id?: Maybe<_IdOperatorsFilterRemunerationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneAnalyticsCommunicationsInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneAnalyticsCommunicationsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneAnalyticsDocumentsInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneAnalyticsDocumentsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneAnswerResearchInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneAnswerResearchInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneBannerInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneBannerInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneCategoryInput>;
  availableAtRoles?: Maybe<
    AvailableAtRolesOperatorsFilterUpdateOneCategoryInput
  >;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneCommentInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterUpdateOneEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterUpdateOneEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneFolderInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneImportUsersPlanInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneImportUsersPlanInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneIncentiveCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneIncentiveCampaignInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneIncentiveCampaignInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneIncentiveCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterUpdateOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterUpdateOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneMeuDiaPdvTableInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneMeuDiaPdvTableInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneRemunerationInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneRemunerationInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneRemunerationInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneRemunerationInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneRemunerationInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneRemunerationInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneRemunerationInput
  >;
  owner?: Maybe<OwnerOperatorsFilterUpdateOneRemunerationInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneRemunerationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneResearchInput = {
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneResearchInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneResearchInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneUserInput = {
  email?: Maybe<EmailOperatorsFilterUpdateOneUserInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneUserInput>;
  name?: Maybe<NameOperatorsFilterUpdateOneUserInput>;
};

export type OwnerOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyDownloadSensitiveFilesInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyPinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyReconheceTransactionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyShareInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyUserHistoryBlockInput = {
  /** Responsável pelo bloqueio */
  gt?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  gte?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  lt?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  lte?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  ne?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Responsável pelo bloqueio */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyUserWordsBlackListInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneCampaignContestationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveManyFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveManyPinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOnePinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneShareInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneIncentiveCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneRemunerationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount?: Maybe<Scalars['Int']>;
  itemCount?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type ParentIdsOperatorsFilterFindManyFileInput = {
  /** O _id dos materiais que usam este arquivo */
  gt?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  gte?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  lt?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  lte?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  ne?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O _id dos materiais que usam este arquivo */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Pin = {
  __typename?: 'Pin';
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  folder?: Maybe<Folder>;
  material?: Maybe<Material>;
  campaign?: Maybe<IncentiveCampaign>;
};

export type PinMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type PinPagination = {
  __typename?: 'PinPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Pin>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum PlatformNamesEnum {
  PwaPrecificador = 'PWA_PRECIFICADOR',
  PwaPositivador = 'PWA_POSITIVADOR',
  ComparadorAparelho = 'COMPARADOR_APARELHO'
}

export type PointsExpireType = {
  __typename?: 'PointsExpireType';
  value?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  /** Essa query retorna uma lista de menus */
  MenuResolvers?: Maybe<Scalars['JSON']>;
  EventFindById?: Maybe<Event>;
  EventFindMany?: Maybe<Array<Maybe<Event>>>;
  EventPagination?: Maybe<EventPagination>;
  FeedFindById?: Maybe<Feed>;
  FeedFindMany?: Maybe<Array<Maybe<Feed>>>;
  FeedPagination?: Maybe<FeedPagination>;
  FeedUpdateLike?: Maybe<FeedUpdateLike>;
  MeuDiaPdvTableFindOne?: Maybe<MeuDiaPdvTable>;
  MeuDiaPdvTableFindById?: Maybe<MeuDiaPdvTable>;
  MeuDiaPdvTableFindMany?: Maybe<Array<Maybe<MeuDiaPdvTable>>>;
  MeuDiaPdvTableFindByIds?: Maybe<Array<Maybe<MeuDiaPdvTable>>>;
  IncentiveCampaignPagination?: Maybe<IncentiveCampaignPagination>;
  IncentiveCampaignFindMany?: Maybe<Array<Maybe<IncentiveCampaign>>>;
  IncentiveCampaignFindOne?: Maybe<IncentiveCampaign>;
  IncentiveCampaignFindById?: Maybe<IncentiveCampaign>;
  CampaignContestationFindOne?: Maybe<CampaignContestation>;
  CampaignContestationPagination?: Maybe<CampaignContestationPagination>;
  IncentiveCampaignStatusOptions?: Maybe<
    Array<Maybe<IncentiveCampaignStatusOption>>
  >;
  ReconheceReverseUsersParse?: Maybe<
    Array<Maybe<ReconheceReverseUsersParsePayload>>
  >;
  AnalyticsIncentiveCampaign?: Maybe<Array<Maybe<AnalyticsIncentiveCampaign>>>;
  RegulationPresentation?: Maybe<Array<Maybe<RegulationPresentation>>>;
  ResearchFindById?: Maybe<Research>;
  ResearchFindMany?: Maybe<Array<Maybe<Research>>>;
  ResearchPagination?: Maybe<ResearchPagination>;
  AnswerResearchFindById?: Maybe<AnswerResearch>;
  AnswerResearchFindMany?: Maybe<Array<Maybe<AnswerResearch>>>;
  AnswerResearchPagination?: Maybe<AnswerResearchPagination>;
  ResearchAnswersDownload?: Maybe<BaseDownload>;
  EmailTemplateFindById?: Maybe<EmailTemplate>;
  EmailTemplateFindMany?: Maybe<Array<Maybe<EmailTemplate>>>;
  EmailTemplatePagination?: Maybe<EmailTemplatePagination>;
  AnalyticsDocumentsFindById?: Maybe<AnalyticsDocuments>;
  AnalyticsDocumentsFindMany?: Maybe<Array<Maybe<AnalyticsDocuments>>>;
  AnalyticsDocumentsPagination?: Maybe<AnalyticsDocumentsPagination>;
  AnalyticsCommunicationsFindById?: Maybe<AnalyticsCommunications>;
  AnalyticsCommunicationsFindMany?: Maybe<
    Array<Maybe<AnalyticsCommunications>>
  >;
  AnalyticsCommunicationsPagination?: Maybe<AnalyticsCommunicationsPagination>;
  RegionFindMany?: Maybe<Array<Maybe<Region>>>;
  RegionFindOne?: Maybe<Region>;
  RegionPagination?: Maybe<RegionPagination>;
  RegionItemFindMany?: Maybe<Array<Maybe<RegionItem>>>;
  RegionItemFindOne?: Maybe<RegionItem>;
  RegionItemPagination?: Maybe<RegionItemPagination>;
  /** Executa a busca dos itens de região */
  RegionItemDistinctDDD?: Maybe<RegionItemDistinctDdd>;
  /** Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro) */
  RegionItemDistinctRegionAndUF?: Maybe<RegionItemDistinctRegionAndUf>;
  JobTitleFindOne?: Maybe<JobTitle>;
  JobTitleFindMany?: Maybe<Array<Maybe<JobTitle>>>;
  RoleGroupFindOne?: Maybe<RoleGroup>;
  RoleGroupFindMany?: Maybe<Array<Maybe<RoleGroup>>>;
  RoleGroupPagination?: Maybe<RoleGroupPagination>;
  ChannelFindMany?: Maybe<Array<Maybe<Channel>>>;
  ChannelFindOne?: Maybe<Channel>;
  ChannelPagination?: Maybe<ChannelPagination>;
  UserContentPermissionFindOne?: Maybe<UserContentPermission>;
  /** Lista de possiveis content-permissions que os usuários podem ter. */
  ContentPermissionsHelper?: Maybe<
    Array<Maybe<ContentPermissionsHelperOption>>
  >;
  UserPagination?: Maybe<UserPagination>;
  UserFindByIds?: Maybe<Array<Maybe<User>>>;
  UserFindById?: Maybe<User>;
  UserFindOne?: Maybe<User>;
  UserFindMany?: Maybe<Array<Maybe<User>>>;
  User?: Maybe<User>;
  /** Executa a busca do usuario */
  UserSearch?: Maybe<Array<Maybe<UserSearch>>>;
  UserSearchPagination?: Maybe<UserPagination>;
  TextFindBlackList?: Maybe<TextFindBlackList>;
  /** Executa a busca do usuario */
  UserFindByEmails?: Maybe<Array<Maybe<UserSimpleResult>>>;
  UserFindByMatricula?: Maybe<Array<Maybe<User>>>;
  FileFindByIds?: Maybe<Array<Maybe<File>>>;
  FileFindById?: Maybe<File>;
  FilePagination?: Maybe<FilePagination>;
  MaterialFindById?: Maybe<Material>;
  MaterialFindMany?: Maybe<Array<Maybe<Material>>>;
  MaterialFindOne?: Maybe<Material>;
  MaterialPagination?: Maybe<MaterialPagination>;
  MaterialCount?: Maybe<Scalars['Int']>;
  MaterialStatusOptions?: Maybe<Array<Maybe<MaterialStatusOption>>>;
  MaterialSearchOwners?: Maybe<Array<Maybe<MaterialSearchOwners>>>;
  EmailFindMany?: Maybe<Array<Maybe<Email>>>;
  EmailPagination?: Maybe<EmailPagination>;
  EmailFindOne?: Maybe<Email>;
  /** Retorna true se o email foi marcado como lido pelo destinatario informado */
  EmailCheckRead?: Maybe<Scalars['Boolean']>;
  MaterialSearch?: Maybe<MaterialSearch>;
  MaterialFindPinned?: Maybe<MaterialPagination>;
  NotificationFindMany?: Maybe<Array<Maybe<Notification>>>;
  NotificationFindOne?: Maybe<Notification>;
  NotificationPagination?: Maybe<NotificationPagination>;
  HelperAddress?: Maybe<AddressHelperPayload>;
  CategoryFindOne?: Maybe<Category>;
  CategoryFindById?: Maybe<Category>;
  CategoryFindMany?: Maybe<Array<Maybe<Category>>>;
  CategoryFindByIds?: Maybe<Array<Maybe<Category>>>;
  CategoryPagination?: Maybe<CategoryPagination>;
  /** Retorna a chave publica (VAPID key) pra a subscription de web push notifications */
  WebPushPublicKey: Scalars['String'];
  PinFindMany?: Maybe<Array<Maybe<Pin>>>;
  PinPagination?: Maybe<PinPagination>;
  ShareFindMany?: Maybe<Array<Maybe<Share>>>;
  SharePagination?: Maybe<SharePagination>;
  CommentPagination?: Maybe<CommentPagination>;
  CommentFindOne?: Maybe<Comment>;
  CommentFindById?: Maybe<Comment>;
  CommentFindMany?: Maybe<Array<Maybe<Comment>>>;
  CommentSortPagination?: Maybe<CommentPagination>;
  EventLivePagination?: Maybe<EventLivePagination>;
  EventLiveFindOne?: Maybe<EventLive>;
  EventLiveFindById?: Maybe<EventLive>;
  BannerFindMany?: Maybe<Array<Maybe<Banner>>>;
  BannerFindOne?: Maybe<Banner>;
  AnalyticsCount?: Maybe<Scalars['Int']>;
  AnalyticsPagination?: Maybe<AnalyticsPagination>;
  AnalyticsUsersReport?: Maybe<Array<Maybe<AnalyticsUser>>>;
  AnalyticsFindReport?: Maybe<Array<Maybe<AnalyticsReportItem>>>;
  AnalyticsUserNotification?: Maybe<Array<Maybe<AnalyticsUserNotification>>>;
  AnalyticsPin?: Maybe<Array<Maybe<AnalyticsPin>>>;
  AnalyticsComment?: Maybe<Array<Maybe<AnalyticsComment>>>;
  AnalyticsPoints?: Maybe<Array<Maybe<AnalyticsPoints>>>;
  AnalyticsDownloads?: Maybe<Scalars['Boolean']>;
  SearchCountFindMany?: Maybe<Array<Maybe<SearchCount>>>;
  SearchCountPagination?: Maybe<SearchCountPagination>;
  FolderFindMany?: Maybe<Array<Maybe<Folder>>>;
  FolderFindById?: Maybe<Folder>;
  FolderPagination?: Maybe<FolderPagination>;
  RecentsActivitiesPagination?: Maybe<RecentsActivitiesPagination>;
  UserWordsBlackListFindMany?: Maybe<Array<Maybe<UserWordsBlackList>>>;
  UserWordsBlackListFindById?: Maybe<UserWordsBlackList>;
  DownloadSensitiveFilesPagination?: Maybe<DownloadSensitiveFilesPagination>;
  DownloadUsersInfo?: Maybe<DownloadUsersInfo>;
  DownloadAcceptedTerms?: Maybe<DownloadAcceptedTerms>;
  DownloadUsersAcceptedPoints?: Maybe<DownloadUsersAcceptedPoints>;
  DownloadUsersWithPoints?: Maybe<DownloadUsersWithPoints>;
  DownloadUsersBase?: Maybe<DownloadUsersInfo>;
  DownloadExtraCredit?: Maybe<DownloadExtraCreditUrl>;
  DownloadReversalPoints?: Maybe<DownloadReversalUrl>;
  DownloadExpirePoints?: Maybe<DownloadExpirePointsUrl>;
  DownloadDebitExpirePoints?: Maybe<DownloadDebitExpirePointsUrl>;
  ImportUsersPlanFindMany?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  ImportUsersPlanFindById?: Maybe<ImportUsersPlan>;
  ImportUsersPlanFindByIds?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  ImportUsersPlanPagination?: Maybe<ImportUsersPlanPagination>;
  UserDeletedFindByIds?: Maybe<Array<Maybe<UserDeleted>>>;
  UserDeletedFindOne?: Maybe<UserDeleted>;
  UserDeletedFindMany?: Maybe<Array<Maybe<UserDeleted>>>;
  UserHistoryBlockFindMany?: Maybe<Array<Maybe<UserHistoryBlock>>>;
  UserHistoryBlockPagination?: Maybe<UserHistoryBlockPagination>;
  FutureOperationFindById?: Maybe<FutureOperation>;
  EmailStatsFindOne?: Maybe<EmailStats>;
  EmailStatsFindMany?: Maybe<Array<Maybe<EmailStats>>>;
  MeuDiaPdvTableAll?: Maybe<MeuPdvReturn>;
  /** Esta query retorna uma lista com os usuários cadastrados no portal o total e a timestamp da ultima atualização do cache */
  UpdatedUsers?: Maybe<UpdatedUsersPayload>;
  SelectRegionalProfiles?: Maybe<Array<Maybe<RegionalProfiles>>>;
  EmailAnalytics?: Maybe<Array<Maybe<ResultEmailAnalytics>>>;
  /** Status de envio dos e-mails */
  EmailStatsAnalytics?: Maybe<Array<Maybe<EmailStatsAnalyticsPayload>>>;
  ReportMenuView?: Maybe<Array<Maybe<ReportMenuView>>>;
  RemunerationPagination?: Maybe<RemunerationPagination>;
  RemunerationFindMany?: Maybe<Array<Maybe<Remuneration>>>;
  RemunerationFindOne?: Maybe<Remuneration>;
  RemunerationFindById?: Maybe<Remuneration>;
  RemunerationCount?: Maybe<Scalars['Int']>;
  ReportRemuneration?: Maybe<Array<Maybe<ReportRemuneration>>>;
  /** Visualização de material por _id e registro de visualização no analytics */
  AnalyticsMaterialView?: Maybe<Material>;
  ReportTourUser?: Maybe<Array<Maybe<ReportTourUser>>>;
  AnalyticsMaterialDownload?: Maybe<Scalars['Boolean']>;
  ReportDocumentsActions?: Maybe<Array<Maybe<ReportDocumentsActions>>>;
  ReconheceWalletPagination?: Maybe<ReconheceWalletPagination>;
  ReconheceWalletFindMany?: Maybe<Array<Maybe<ReconheceWallet>>>;
  ReconheceWalletFindById?: Maybe<ReconheceWallet>;
  ReconheceWalletSum?: Maybe<ReconheceWalletSum>;
  UserCreditOperationFindById?: Maybe<UserCreditOperation>;
  UserCreditOperationPagination?: Maybe<UserCreditOperationPagination>;
  UserCreditOperationFindByIncentiveCampaignId?: Maybe<
    Array<Maybe<UserCreditOperation>>
  >;
  ExtractUserCreditPoints?: Maybe<Array<Maybe<UserCreditOperation>>>;
  DownloadtUserCreditPoints?: Maybe<DownloadtUserCreditPoints>;
  UserCreditOperationAnalytics?: Maybe<Array<Maybe<UserCreditOperation>>>;
  ReconheceTransactionPagination?: Maybe<ReconheceTransactionPagination>;
  ReconheceInvoiceOriginFindMany?: Maybe<Array<Maybe<ReconheceInvoiceOrigin>>>;
  ReconheceUserBalance?: Maybe<ReconheceUserBalancePayload>;
};

export type QueryEventFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryEventFindManyArgs = {
  filter?: Maybe<FilterFindManyEventInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEventInput>;
};

export type QueryEventPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEventInput>;
  sort?: Maybe<SortFindManyEventInput>;
};

export type QueryFeedFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryFeedFindManyArgs = {
  filter?: Maybe<FilterFindManyFeedInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyFeedInput>;
};

export type QueryFeedPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFeedInput>;
  sort?: Maybe<SortFindManyFeedInput>;
};

export type QueryFeedUpdateLikeArgs = {
  _id: Scalars['String'];
};

export type QueryMeuDiaPdvTableFindOneArgs = {
  filter?: Maybe<FilterFindOneMeuDiaPdvTableInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMeuDiaPdvTableInput>;
};

export type QueryMeuDiaPdvTableFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryMeuDiaPdvTableFindManyArgs = {
  filter?: Maybe<FilterFindManyMeuDiaPdvTableInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyMeuDiaPdvTableInput>;
};

export type QueryMeuDiaPdvTableFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsMeuDiaPdvTableInput>;
};

export type QueryIncentiveCampaignPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyIncentiveCampaignInput>;
  sort?: Maybe<SortFindManyIncentiveCampaignInput>;
};

export type QueryIncentiveCampaignFindManyArgs = {
  filter?: Maybe<FilterFindManyIncentiveCampaignInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyIncentiveCampaignInput>;
};

export type QueryIncentiveCampaignFindOneArgs = {
  filter?: Maybe<FilterFindOneIncentiveCampaignInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneIncentiveCampaignInput>;
};

export type QueryIncentiveCampaignFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryCampaignContestationFindOneArgs = {
  filter?: Maybe<FilterFindOneCampaignContestationInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCampaignContestationInput>;
};

export type QueryCampaignContestationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyCampaignContestationInput>;
  sort?: Maybe<SortFindManyCampaignContestationInput>;
};

export type QueryReconheceReverseUsersParseArgs = {
  paymentFileId: Scalars['String'];
  campaignId: Scalars['String'];
};

export type QueryAnalyticsIncentiveCampaignArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
};

export type QueryRegulationPresentationArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type QueryResearchFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryResearchFindManyArgs = {
  filter?: Maybe<FilterFindManyResearchInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyResearchInput>;
};

export type QueryResearchPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyResearchInput>;
  sort?: Maybe<SortFindManyResearchInput>;
};

export type QueryAnswerResearchFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryAnswerResearchFindManyArgs = {
  filter?: Maybe<FilterFindManyAnswerResearchInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyAnswerResearchInput>;
};

export type QueryAnswerResearchPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAnswerResearchInput>;
  sort?: Maybe<SortFindManyAnswerResearchInput>;
};

export type QueryResearchAnswersDownloadArgs = {
  researchId: Scalars['String'];
};

export type QueryEmailTemplateFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<Sort>;
};

export type QueryEmailTemplateFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailTemplateInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailTemplateInput>;
};

export type QueryEmailTemplatePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEmailTemplateInput>;
  sort?: Maybe<SortFindManyEmailTemplateInput>;
};

export type QueryAnalyticsDocumentsFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryAnalyticsDocumentsFindManyArgs = {
  filter?: Maybe<FilterFindManyAnalyticsDocumentsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyAnalyticsDocumentsInput>;
};

export type QueryAnalyticsDocumentsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAnalyticsDocumentsInput>;
  sort?: Maybe<SortFindManyAnalyticsDocumentsInput>;
};

export type QueryAnalyticsCommunicationsFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryAnalyticsCommunicationsFindManyArgs = {
  filter?: Maybe<FilterFindManyAnalyticsCommunicationsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyAnalyticsCommunicationsInput>;
};

export type QueryAnalyticsCommunicationsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAnalyticsCommunicationsInput>;
  sort?: Maybe<SortFindManyAnalyticsCommunicationsInput>;
};

export type QueryRegionFindManyArgs = {
  filter?: Maybe<FilterFindManyRegionInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionInput>;
};

export type QueryRegionFindOneArgs = {
  filter?: Maybe<FilterFindOneRegionInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRegionInput>;
};

export type QueryRegionPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegionInput>;
  sort?: Maybe<SortFindManyRegionInput>;
};

export type QueryRegionItemFindManyArgs = {
  filter?: Maybe<FilterFindManyRegionItemInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type QueryRegionItemFindOneArgs = {
  filter?: Maybe<FilterFindOneRegionItemInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRegionItemInput>;
};

export type QueryRegionItemPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegionItemInput>;
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type QueryJobTitleFindOneArgs = {
  filter?: Maybe<FilterFindOneJobTitleInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneJobTitleInput>;
};

export type QueryJobTitleFindManyArgs = {
  filter?: Maybe<FilterFindManyJobTitleInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyJobTitleInput>;
};

export type QueryRoleGroupFindOneArgs = {
  filter?: Maybe<FilterFindOneRoleGroupInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRoleGroupInput>;
};

export type QueryRoleGroupFindManyArgs = {
  filter?: Maybe<FilterFindManyRoleGroupInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type QueryRoleGroupPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRoleGroupInput>;
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type QueryChannelFindManyArgs = {
  filter?: Maybe<FilterFindManyChannelInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyChannelInput>;
};

export type QueryChannelFindOneArgs = {
  filter?: Maybe<FilterFindOneChannelInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneChannelInput>;
};

export type QueryChannelPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyChannelInput>;
  sort?: Maybe<SortFindManyChannelInput>;
};

export type QueryUserContentPermissionFindOneArgs = {
  filter?: Maybe<FilterFindOneUserContentPermissionInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserContentPermissionInput>;
};

export type QueryUserPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyUserInput>;
  sort?: Maybe<SortFindManyUserInput>;
};

export type QueryUserFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsUserInput>;
};

export type QueryUserFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryUserFindOneArgs = {
  filter?: Maybe<FilterFindOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserInput>;
};

export type QueryUserFindManyArgs = {
  filter?: Maybe<FilterFindManyUserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserInput>;
};

export type QueryUserSearchArgs = {
  search: Scalars['String'];
  roleGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryUserSearchPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<UserSearchPaginationFilterInput>;
  sort?: Maybe<SortFindManyUserInput>;
};

export type QueryTextFindBlackListArgs = {
  text: Scalars['String'];
};

export type QueryUserFindByEmailsArgs = {
  emails: Array<Maybe<Scalars['String']>>;
};

export type QueryUserFindByMatriculaArgs = {
  isMatriculaNull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryFileFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type QueryFileFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryFilePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFileInput>;
  sort?: Maybe<SortFindManyFileInput>;
};

export type QueryMaterialFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryMaterialFindManyArgs = {
  filter?: Maybe<FilterFindManyMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryMaterialFindOneArgs = {
  filter?: Maybe<FilterFindOneMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMaterialInput>;
};

export type QueryMaterialPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryMaterialCountArgs = {
  filter?: Maybe<FilterMaterialInput>;
  sort?: Maybe<Sort>;
};

export type QueryMaterialSearchOwnersArgs = {
  kind?: Maybe<EnumMaterialKind>;
};

export type QueryEmailFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailInput>;
};

export type QueryEmailPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEmailInput>;
  sort?: Maybe<SortFindManyEmailInput>;
};

export type QueryEmailFindOneArgs = {
  filter?: Maybe<FilterFindOneEmailInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailInput>;
};

export type QueryEmailCheckReadArgs = {
  emailId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type QueryMaterialSearchArgs = {
  search: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
};

export type QueryMaterialFindPinnedArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryNotificationFindManyArgs = {
  filter?: Maybe<FilterFindManyNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyNotificationInput>;
};

export type QueryNotificationFindOneArgs = {
  filter?: Maybe<FilterFindOneNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneNotificationInput>;
};

export type QueryNotificationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyNotificationInput>;
  sort?: Maybe<SortFindManyNotificationInput>;
};

export type QueryHelperAddressArgs = {
  zipCode: Scalars['String'];
};

export type QueryCategoryFindOneArgs = {
  filter?: Maybe<FilterFindOneCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCategoryInput>;
};

export type QueryCategoryFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortMenuEnum>;
  filter?: Maybe<Filter>;
};

export type QueryCategoryFindManyArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCategoryInput>;
};

export type QueryCategoryFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsCategoryInput>;
  filter?: Maybe<Filter>;
};

export type QueryCategoryPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyCategoryInput>;
  sort?: Maybe<SortFindManyCategoryInput>;
};

export type QueryPinFindManyArgs = {
  filter?: Maybe<FilterFindManyPinInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPinInput>;
};

export type QueryPinPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPinInput>;
  sort?: Maybe<SortFindManyPinInput>;
};

export type QueryShareFindManyArgs = {
  filter?: Maybe<FilterFindManyShareInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyShareInput>;
};

export type QuerySharePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyShareInput>;
  sort?: Maybe<SortFindManyShareInput>;
};

export type QueryCommentPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyCommentInput>;
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryCommentFindOneArgs = {
  filter?: Maybe<FilterFindOneCommentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCommentInput>;
};

export type QueryCommentFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryCommentFindManyArgs = {
  filter?: Maybe<FilterFindManyCommentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryCommentSortPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CommentSortPaginationFilterInput>;
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryEventLivePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEventLiveInput>;
  sort?: Maybe<SortFindManyEventLiveInput>;
};

export type QueryEventLiveFindOneArgs = {
  filter?: Maybe<FilterFindOneEventLiveInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEventLiveInput>;
};

export type QueryEventLiveFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryBannerFindManyArgs = {
  filter?: Maybe<FilterFindManyBannerInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyBannerInput>;
};

export type QueryBannerFindOneArgs = {
  filter?: Maybe<FilterFindOneBannerInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneBannerInput>;
};

export type QueryAnalyticsCountArgs = {
  filter?: Maybe<FilterAnalyticsInput>;
};

export type QueryAnalyticsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAnalyticsInput>;
  sort?: Maybe<SortFindManyAnalyticsInput>;
};

export type QueryAnalyticsUsersReportArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
};

export type QueryAnalyticsFindReportArgs = {
  filter?: Maybe<AnalyticsReportFilterInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsUserNotificationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsPinArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsCommentArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsPointsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsDownloadsArgs = {
  fileId?: Maybe<Scalars['String']>;
};

export type QuerySearchCountFindManyArgs = {
  filter?: Maybe<FilterFindManySearchCountInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManySearchCountInput>;
};

export type QuerySearchCountPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManySearchCountInput>;
  sort?: Maybe<SortFindManySearchCountInput>;
};

export type QueryFolderFindManyArgs = {
  filter?: Maybe<FilterFindManyFolderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyFolderInput>;
};

export type QueryFolderFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryFolderPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFolderInput>;
  sort?: Maybe<SortFindManyFolderInput>;
};

export type QueryRecentsActivitiesPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRecentsActivitiesInput>;
  sort?: Maybe<SortFindManyRecentsActivitiesInput>;
};

export type QueryUserWordsBlackListFindManyArgs = {
  filter?: Maybe<FilterFindManyUserWordsBlackListInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserWordsBlackListInput>;
};

export type QueryUserWordsBlackListFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryDownloadSensitiveFilesPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDownloadSensitiveFilesInput>;
  sort?: Maybe<SortFindManyDownloadSensitiveFilesInput>;
};

export type QueryDownloadAcceptedTermsArgs = {
  incentiveCampaignId: Scalars['String'];
};

export type QueryDownloadUsersAcceptedPointsArgs = {
  incentiveCampaignId: Scalars['String'];
};

export type QueryDownloadExtraCreditArgs = {
  campaignId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type QueryDownloadReversalPointsArgs = {
  campaignId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type QueryImportUsersPlanFindManyArgs = {
  filter?: Maybe<FilterFindManyImportUsersPlanInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyImportUsersPlanInput>;
};

export type QueryImportUsersPlanFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryImportUsersPlanFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsImportUsersPlanInput>;
  filter?: Maybe<Filter>;
};

export type QueryImportUsersPlanPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyImportUsersPlanInput>;
  sort?: Maybe<SortFindManyImportUsersPlanInput>;
};

export type QueryUserDeletedFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsUserDeletedInput>;
};

export type QueryUserDeletedFindOneArgs = {
  filter?: Maybe<FilterFindOneUserDeletedInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserDeletedInput>;
};

export type QueryUserDeletedFindManyArgs = {
  filter?: Maybe<FilterFindManyUserDeletedInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserDeletedInput>;
};

export type QueryUserHistoryBlockFindManyArgs = {
  filter?: Maybe<FilterFindManyUserHistoryBlockInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserHistoryBlockInput>;
};

export type QueryUserHistoryBlockPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyUserHistoryBlockInput>;
  sort?: Maybe<SortFindManyUserHistoryBlockInput>;
};

export type QueryFutureOperationFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryEmailStatsFindOneArgs = {
  filter?: Maybe<FilterFindOneEmailStatsInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailStatsInput>;
};

export type QueryEmailStatsFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailStatsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailStatsInput>;
};

export type QueryEmailAnalyticsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryEmailStatsAnalyticsArgs = {
  day: Scalars['String'];
  month: Scalars['String'];
  year: Scalars['String'];
};

export type QueryReportMenuViewArgs = {
  date: Scalars['String'];
  menu: Scalars['String'];
  kind: Scalars['String'];
};

export type QueryRemunerationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRemunerationInput>;
  sort?: Maybe<SortFindManyRemunerationInput>;
};

export type QueryRemunerationFindManyArgs = {
  filter?: Maybe<FilterFindManyRemunerationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRemunerationInput>;
};

export type QueryRemunerationFindOneArgs = {
  filter?: Maybe<FilterFindOneRemunerationInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRemunerationInput>;
};

export type QueryRemunerationFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryRemunerationCountArgs = {
  filter?: Maybe<FilterRemunerationInput>;
  sort?: Maybe<Sort>;
};

export type QueryAnalyticsMaterialViewArgs = {
  _id: Scalars['String'];
};

export type QueryReportTourUserArgs = {
  date: Scalars['String'];
};

export type QueryAnalyticsMaterialDownloadArgs = {
  fileId?: Maybe<Scalars['String']>;
};

export type QueryReportDocumentsActionsArgs = {
  date: Scalars['String'];
  menu: Scalars['String'];
};

export type QueryReconheceWalletPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyReconheceWalletInput>;
  sort?: Maybe<SortFindManyReconheceWalletInput>;
};

export type QueryReconheceWalletFindManyArgs = {
  filter?: Maybe<FilterFindManyReconheceWalletInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyReconheceWalletInput>;
};

export type QueryReconheceWalletFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryReconheceWalletSumArgs = {
  invoiceOrigin?: Maybe<Scalars['String']>;
  createdAtRange?: Maybe<ReconheceWalletCreatedAtRange>;
};

export type QueryUserCreditOperationFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryUserCreditOperationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyUserCreditOperationInput>;
  sort?: Maybe<SortFindManyUserCreditOperationInput>;
};

export type QueryUserCreditOperationFindByIncentiveCampaignIdArgs = {
  incentiveCampaignId: Scalars['String'];
};

export type QueryExtractUserCreditPointsArgs = {
  incentiveCampaignId?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type QueryDownloadtUserCreditPointsArgs = {
  incentiveCampaignIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  cpf?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type QueryUserCreditOperationAnalyticsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryReconheceTransactionPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyReconheceTransactionInput>;
  sort?: Maybe<SortFindManyReconheceTransactionInput>;
};

export type QueryReconheceInvoiceOriginFindManyArgs = {
  filter?: Maybe<FilterFindManyReconheceInvoiceOriginInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyReconheceInvoiceOriginInput>;
};

export type RecentsActivities = {
  __typename?: 'RecentsActivities';
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  material?: Maybe<Material>;
  campaign?: Maybe<IncentiveCampaign>;
};

/** List of items with pagination. */
export type RecentsActivitiesPagination = {
  __typename?: 'RecentsActivitiesPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RecentsActivities>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ReconheceAnalytics = {
  __typename?: 'ReconheceAnalytics';
  availablePoints?: Maybe<Scalars['Float']>;
  reservedPoints?: Maybe<Scalars['Float']>;
  usedPoints?: Maybe<Scalars['Float']>;
};

export type ReconheceInvoiceOrigin = {
  __typename?: 'ReconheceInvoiceOrigin';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ReconheceReverseUsersParsePayload = {
  __typename?: 'ReconheceReverseUsersParsePayload';
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  userPoints?: Maybe<Scalars['Float']>;
  userCampaignPoints?: Maybe<Scalars['Float']>;
  reversalPoints?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type ReconheceTransaction = {
  __typename?: 'ReconheceTransaction';
  _id: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  status?: Maybe<EnumReconheceTransactionStatus>;
  value?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  originWallets?: Maybe<Array<Maybe<ReconheceTransactionOriginWallets>>>;
  walletIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<EnumReconheceTransactionKind>;
  error?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type ReconheceTransactionOriginWallets = {
  __typename?: 'ReconheceTransactionOriginWallets';
  walletId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ReconheceTransactionOriginWalletsInput = {
  walletId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type ReconheceTransactionPagination = {
  __typename?: 'ReconheceTransactionPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<ReconheceTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ReconheceUserBalancePayload = {
  __typename?: 'ReconheceUserBalancePayload';
  balance?: Maybe<Scalars['Float']>;
  expiredPoints?: Maybe<Scalars['Int']>;
  reservedPoints?: Maybe<Scalars['Int']>;
  usedPoints?: Maybe<Scalars['Int']>;
  sessionToken?: Maybe<Scalars['String']>;
  tempToken?: Maybe<Scalars['String']>;
};

export type ReconheceWallet = {
  __typename?: 'ReconheceWallet';
  createdBy?: Maybe<Scalars['String']>;
  /** Data em que o status foi alterado para `Disponivel` */
  paidAt?: Maybe<Scalars['Date']>;
  initialCredit?: Maybe<Scalars['Float']>;
  usedCredit?: Maybe<Scalars['Float']>;
  availableCredit?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumReconheceWalletStatus>;
  /** identificador da ordem de pagamento */
  POIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem de pagamento */
  POImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** identificador da NF */
  NFIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem da NF */
  NFImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceOrigin?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  NFImageUrls?: Maybe<Array<Maybe<File>>>;
  POImageUrls?: Maybe<Array<Maybe<File>>>;
};

export type ReconheceWalletNfImageUrlsArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type ReconheceWalletPoImageUrlsArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type ReconheceWalletCreatedAtRange = {
  dateStart?: Maybe<Scalars['Date']>;
  dateEnd?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type ReconheceWalletPagination = {
  __typename?: 'ReconheceWalletPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<ReconheceWallet>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ReconheceWalletSum = {
  __typename?: 'ReconheceWalletSum';
  initialCredit?: Maybe<Scalars['Float']>;
  usedCredit?: Maybe<Scalars['Float']>;
  availableCredit?: Maybe<Scalars['Float']>;
};

export type RecordIdOperatorsFilterFindManyPinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecordIdOperatorsFilterRemoveManyPinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecordIdOperatorsFilterRemoveOnePinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Region = {
  __typename?: 'Region';
  _id: Scalars['String'];
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  items?: Maybe<Array<Maybe<RegionItem>>>;
};

export type RegionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type RegionalProfileOption = {
  __typename?: 'RegionalProfileOption';
  value: Scalars['String'];
  label: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type RegionalProfiles = {
  __typename?: 'RegionalProfiles';
  roleGroupName: Scalars['String'];
  roleGroupId: Scalars['String'];
  channelId: Scalars['String'];
  channelName: Scalars['String'];
  sort: Scalars['Int'];
  options?: Maybe<Array<Maybe<RegionalProfileOption>>>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  /** _id do parent - ex: para a cidade de Sorocaba o parent seria _15_ para 15 seria _SP_, para SP seria _BR_ */
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  items?: Maybe<Array<Maybe<RegionItem>>>;
};

export type RegionItemItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type RegionItemDistinctDdd = {
  __typename?: 'RegionItemDistinctDDD';
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RegionItemDistinctRegionAndUf = {
  __typename?: 'RegionItemDistinctRegionAndUF';
  items?: Maybe<Array<Maybe<RegionItemDistinctRegionAndUfItem>>>;
};

export type RegionItemDistinctRegionAndUfItem = {
  __typename?: 'RegionItemDistinctRegionAndUFItem';
  region?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type RegionItemPagination = {
  __typename?: 'RegionItemPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RegionItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

/** List of items with pagination. */
export type RegionPagination = {
  __typename?: 'RegionPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Region>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RegulationPresentation = {
  __typename?: 'RegulationPresentation';
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
  campaignStatus?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<UserRegulationPresentation>>>;
};

export type RemoveByIdAnalyticsCommunicationsPayload = {
  __typename?: 'RemoveByIdAnalyticsCommunicationsPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<AnalyticsCommunications>;
};

export type RemoveByIdAnalyticsDocumentsPayload = {
  __typename?: 'RemoveByIdAnalyticsDocumentsPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<AnalyticsDocuments>;
};

export type RemoveByIdAnswerResearchPayload = {
  __typename?: 'RemoveByIdAnswerResearchPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<AnswerResearch>;
};

export type RemoveByIdBannerPayload = {
  __typename?: 'RemoveByIdBannerPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Banner>;
};

export type RemoveByIdCategoryPayload = {
  __typename?: 'RemoveByIdCategoryPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Category>;
};

export type RemoveByIdCommentPayload = {
  __typename?: 'RemoveByIdCommentPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Comment>;
};

export type RemoveByIdEmailTemplatePayload = {
  __typename?: 'RemoveByIdEmailTemplatePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<EmailTemplate>;
};

export type RemoveByIdEventLivePayload = {
  __typename?: 'RemoveByIdEventLivePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<EventLive>;
};

export type RemoveByIdEventPayload = {
  __typename?: 'RemoveByIdEventPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Event>;
};

export type RemoveByIdFeedPayload = {
  __typename?: 'RemoveByIdFeedPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Feed>;
};

export type RemoveByIdFilePayload = {
  __typename?: 'RemoveByIdFilePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<File>;
};

export type RemoveByIdIncentiveCampaignPayload = {
  __typename?: 'RemoveByIdIncentiveCampaignPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<IncentiveCampaign>;
};

export type RemoveByIdMeuDiaPdvTablePayload = {
  __typename?: 'RemoveByIdMeuDiaPdvTablePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<MeuDiaPdvTable>;
};

export type RemoveByIdNotificationPayload = {
  __typename?: 'RemoveByIdNotificationPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Notification>;
};

export type RemoveByIdReconheceWalletPayload = {
  __typename?: 'RemoveByIdReconheceWalletPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<ReconheceWallet>;
};

export type RemoveByIdRemunerationPayload = {
  __typename?: 'RemoveByIdRemunerationPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Remuneration>;
};

export type RemoveByIdResearchPayload = {
  __typename?: 'RemoveByIdResearchPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Research>;
};

export type RemoveByIdUserPayload = {
  __typename?: 'RemoveByIdUserPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<User>;
};

export type RemoveManyFolderPayload = {
  __typename?: 'RemoveManyFolderPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type RemoveManyPinPayload = {
  __typename?: 'RemoveManyPinPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type RemoveOneFolderPayload = {
  __typename?: 'RemoveOneFolderPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Folder>;
};

export type RemoveOneMaterialPayload = {
  __typename?: 'RemoveOneMaterialPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Material>;
};

export type RemoveOnePinPayload = {
  __typename?: 'RemoveOnePinPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Pin>;
};

export type RemoveOneRegionPayload = {
  __typename?: 'RemoveOneRegionPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Region>;
};

export type RemoveOneSharePayload = {
  __typename?: 'RemoveOneSharePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Share>;
};

export type Remuneration = {
  __typename?: 'Remuneration';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidity>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidity>;
  likes?: Maybe<Likes>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfig>>
  >;
  mailing?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  remunerationId?: Maybe<Scalars['Float']>;
  cardImageFile?: Maybe<File>;
  bannerContentFile?: Maybe<File>;
  regulationFile?: Maybe<File>;
  presentationFile?: Maybe<File>;
  documentFile?: Maybe<File>;
  mailingFile?: Maybe<File>;
  user?: Maybe<User>;
  views?: Maybe<MaterialViews>;
};

export type RemunerationCommunicationItemsConfig = {
  __typename?: 'RemunerationCommunicationItemsConfig';
  kind?: Maybe<EnumRemunerationCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplateProps>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RemunerationCommunicationItemsConfigInput = {
  kind?: Maybe<EnumRemunerationCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplatePropsInput>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type RemunerationPagination = {
  __typename?: 'RemunerationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Remuneration>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RemunerationRemunerationValidity = {
  __typename?: 'RemunerationRemunerationValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type RemunerationRemunerationValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type RemunerationValidity = {
  __typename?: 'RemunerationValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type RemunerationValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type ReportDocumentsActions = {
  __typename?: 'ReportDocumentsActions';
  numericId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  menu?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdByUserName?: Maybe<Scalars['String']>;
  createdByUserMatricula?: Maybe<Scalars['String']>;
  createdByUserRoleGroup?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedByUserName?: Maybe<Scalars['String']>;
  updatedByUserMatricula?: Maybe<Scalars['String']>;
  updatedByUserRoleGroup?: Maybe<Scalars['String']>;
};

export type ReportMaterialView = {
  __typename?: 'ReportMaterialView';
  title?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  menu?: Maybe<Scalars['String']>;
};

export type ReportMenuView = {
  __typename?: 'ReportMenuView';
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  user?: Maybe<ReportUserMenuView>;
  material?: Maybe<ReportMaterialView>;
  dateView?: Maybe<Scalars['String']>;
  dateDownload?: Maybe<Scalars['String']>;
};

export type ReportRemuneration = {
  __typename?: 'ReportRemuneration';
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dateAccepted?: Maybe<Scalars['String']>;
};

export type ReportTourUser = {
  __typename?: 'ReportTourUser';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  tour?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['String']>;
  disabledWithPoints?: Maybe<Scalars['String']>;
  disabledAt?: Maybe<Scalars['String']>;
};

export type ReportUserMenuView = {
  __typename?: 'ReportUserMenuView';
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Scalars['String']>;
};

export type Research = {
  __typename?: 'Research';
  title?: Maybe<Scalars['String']>;
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidity>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  answers?: Maybe<AnswerResearch>;
};

/** List of items with pagination. */
export type ResearchPagination = {
  __typename?: 'ResearchPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Research>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ResearchValidity = {
  __typename?: 'ResearchValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type ResearchValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type ResultEmailAnalytics = {
  __typename?: 'ResultEmailAnalytics';
  _id?: Maybe<Scalars['String']>;
  statsId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Date']>;
  menu?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
};

/** Perfil do usuário. Contém um conjunto de permissoes. */
export type RoleGroup = {
  __typename?: 'RoleGroup';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdm>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type RoleGroupIdm = {
  __typename?: 'RoleGroupIdm';
  type?: Maybe<EnumRoleGroupIdmType>;
  items?: Maybe<Array<Maybe<RoleGroupIdmItems>>>;
};

export type RoleGroupIdmInput = {
  type?: Maybe<EnumRoleGroupIdmType>;
  items?: Maybe<Array<Maybe<RoleGroupIdmItemsInput>>>;
};

export type RoleGroupIdmItems = {
  __typename?: 'RoleGroupIdmItems';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type RoleGroupIdmItemsInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type RoleGroupPagination = {
  __typename?: 'RoleGroupPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RoleGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SearchCount = {
  __typename?: 'SearchCount';
  _id: Scalars['String'];
  term?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type SearchCountPagination = {
  __typename?: 'SearchCountPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<SearchCount>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SentEmailHtml = {
  __typename?: 'SentEmailHTML';
  body?: Maybe<Scalars['String']>;
};

export type Share = {
  __typename?: 'Share';
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  material?: Maybe<Material>;
};

export type ShareMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type SharePagination = {
  __typename?: 'SharePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Share>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SocialUser = {
  __typename?: 'SocialUser';
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  blockedCommenting?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export enum Sort {
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindByIdsCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindByIdsFileInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ParentidsAsc = 'PARENTIDS_ASC',
  ParentidsDesc = 'PARENTIDS_DESC',
  ParentidsKindAsc = 'PARENTIDS__KIND_ASC',
  ParentidsKindDesc = 'PARENTIDS__KIND_DESC'
}

export enum SortFindByIdsImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindByIdsMeuDiaPdvTableInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindByIdsUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindByIdsUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export enum SortFindManyAnalyticsCommunicationsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyAnalyticsDocumentsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  EventAsc = 'EVENT_ASC',
  EventDesc = 'EVENT_DESC',
  EventParentidAsc = 'EVENT__PARENTID_ASC',
  EventParentidDesc = 'EVENT__PARENTID_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC'
}

export enum SortFindManyAnswerResearchInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC'
}

export enum SortFindManyCampaignContestationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyChannelInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  LikecountAsc = 'LIKECOUNT_ASC',
  LikecountDesc = 'LIKECOUNT_DESC',
  SubcommentcountAsc = 'SUBCOMMENTCOUNT_ASC',
  SubcommentcountDesc = 'SUBCOMMENTCOUNT_DESC'
}

export enum SortFindManyDownloadSensitiveFilesInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyEmailInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyEmailStatsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyEmailTemplateInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyEventInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortFindManyFeedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyFileInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ParentidsAsc = 'PARENTIDS_ASC',
  ParentidsDesc = 'PARENTIDS_DESC',
  ParentidsKindAsc = 'PARENTIDS__KIND_ASC',
  ParentidsKindDesc = 'PARENTIDS__KIND_DESC'
}

export enum SortFindManyFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyIncentiveCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyJobTitleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyMeuDiaPdvTableInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyPinInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  RecordidAsc = 'RECORDID_ASC',
  RecordidDesc = 'RECORDID_DESC'
}

export enum SortFindManyRecentsActivitiesInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventAsc = 'EVENT_ASC',
  EventDesc = 'EVENT_DESC',
  EventParentidAsc = 'EVENT__PARENTID_ASC',
  EventParentidDesc = 'EVENT__PARENTID_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyReconheceInvoiceOriginInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyReconheceTransactionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyReconheceWalletInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyRegionItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyRemunerationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export enum SortFindManyResearchInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyRoleGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManySearchCountInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC'
}

export enum SortFindManyShareInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyUserCreditOperationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC'
}

export enum SortFindManyUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyUserHistoryBlockInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyUserWordsBlackListInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC'
}

export enum SortFindOneCampaignContestationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneChannelInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneEmailInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEmailStatsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortFindOneIncentiveCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneJobTitleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneMeuDiaPdvTableInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindOneRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneRegionItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneRemunerationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export enum SortFindOneRoleGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneUserContentPermissionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC'
}

export enum SortFindOneUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export enum SortMenuEnum {
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortRemoveOneFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortRemoveOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortRemoveOnePinInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  RecordidAsc = 'RECORDID_ASC',
  RecordidDesc = 'RECORDID_DESC'
}

export enum SortRemoveOneRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortRemoveOneShareInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneAnalyticsCommunicationsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneAnalyticsDocumentsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneAnswerResearchInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC'
}

export enum SortUpdateOneCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortUpdateOneFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneIncentiveCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortUpdateOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortUpdateOneMeuDiaPdvTableInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneRemunerationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC'
}

export enum SortUpdateOneResearchInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export type TemplateConfigCommunication = {
  __typename?: 'TemplateConfigCommunication';
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<TemplatePropsCommunication>;
};

export type TemplatePropsCommunication = {
  __typename?: 'TemplatePropsCommunication';
  TEXT_1?: Maybe<Scalars['String']>;
  TEXT_2?: Maybe<Scalars['String']>;
  BUTTON_TITLE?: Maybe<Scalars['String']>;
  BUTTON_LINK?: Maybe<Scalars['String']>;
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
  HIDE_BUTTON?: Maybe<Scalars['Boolean']>;
};

export type TermOperatorsFilterFindManySearchCountInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TextFindBlackList = {
  __typename?: 'TextFindBlackList';
  isOk?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdAnalyticsCommunicationsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsCommunicationsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdAnalyticsCommunicationsPayload = {
  __typename?: 'UpdateByIdAnalyticsCommunicationsPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnalyticsCommunications>;
};

export type UpdateByIdAnalyticsDocumentsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsDocumentsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdAnalyticsDocumentsPayload = {
  __typename?: 'UpdateByIdAnalyticsDocumentsPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnalyticsDocuments>;
};

export type UpdateByIdAnswerResearchInput = {
  userId?: Maybe<Scalars['String']>;
  researchId?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdAnswerResearchPayload = {
  __typename?: 'UpdateByIdAnswerResearchPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnswerResearch>;
};

export type UpdateByIdEmailTemplateInput = {
  title?: Maybe<Scalars['String']>;
  headerFileId?: Maybe<Scalars['String']>;
  footerFileId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdEmailTemplatePayload = {
  __typename?: 'UpdateByIdEmailTemplatePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<EmailTemplate>;
};

export type UpdateByIdEventInput = {
  title?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventStatus>;
  validity?: Maybe<EventValidityInput>;
  likes?: Maybe<Scalars['Float']>;
  publishDate?: Maybe<Scalars['Date']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  prePublishMail?: Maybe<Scalars['String']>;
  onPublishMail?: Maybe<Scalars['String']>;
  onUpdateMail?: Maybe<Scalars['String']>;
  sentEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  schedulles?: Maybe<Scalars['JSON']>;
  gallery?: Maybe<Scalars['JSON']>;
  guides?: Maybe<Scalars['JSON']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdEventPayload = {
  __typename?: 'UpdateByIdEventPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Event>;
};

export type UpdateByIdFeedInput = {
  eventId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  justification?: Maybe<Scalars['String']>;
  status?: Maybe<EnumFeedStatus>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdFeedPayload = {
  __typename?: 'UpdateByIdFeedPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Feed>;
};

export type UpdateByIdFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfoInput>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdFilePayload = {
  __typename?: 'UpdateByIdFilePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<File>;
};

export type UpdateByIdMaterialInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdMaterialPayload = {
  __typename?: 'UpdateByIdMaterialPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Material>;
};

export type UpdateByIdReconheceWalletInput = {
  /** Data em que o status foi alterado para `Disponivel` */
  paidAt?: Maybe<Scalars['Date']>;
  initialCredit?: Maybe<Scalars['Float']>;
  status?: Maybe<EnumReconheceWalletStatus>;
  /** identificador da ordem de pagamento */
  POIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem de pagamento */
  POImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** identificador da NF */
  NFIdentifier?: Maybe<Scalars['String']>;
  /** imagem da ordem da NF */
  NFImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceOrigin?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdReconheceWalletPayload = {
  __typename?: 'UpdateByIdReconheceWalletPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<ReconheceWallet>;
};

export type UpdateByIdResearchInput = {
  title?: Maybe<Scalars['String']>;
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidityInput>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdResearchPayload = {
  __typename?: 'UpdateByIdResearchPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Research>;
};

export type UpdatedAtOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type UpdatedUsersPayload = {
  __typename?: 'UpdatedUsersPayload';
  stamp: Scalars['String'];
  count: Scalars['Int'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type UpdateOneAnalyticsCommunicationsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsCommunicationsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneAnalyticsCommunicationsPayload = {
  __typename?: 'UpdateOneAnalyticsCommunicationsPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnalyticsCommunications>;
};

export type UpdateOneAnalyticsDocumentsInput = {
  userId?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  action?: Maybe<EnumAnalyticsDocumentsAction>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneAnalyticsDocumentsPayload = {
  __typename?: 'UpdateOneAnalyticsDocumentsPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnalyticsDocuments>;
};

export type UpdateOneAnswerResearchInput = {
  userId?: Maybe<Scalars['String']>;
  researchId?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JSON']>;
};

export type UpdateOneAnswerResearchPayload = {
  __typename?: 'UpdateOneAnswerResearchPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AnswerResearch>;
};

export type UpdateOneBannerInput = {
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
};

export type UpdateOneBannerPayload = {
  __typename?: 'UpdateOneBannerPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Banner>;
};

export type UpdateOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type UpdateOneCategoryPayload = {
  __typename?: 'UpdateOneCategoryPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Category>;
};

export type UpdateOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
};

export type UpdateOneCommentPayload = {
  __typename?: 'UpdateOneCommentPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Comment>;
};

export type UpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
};

export type UpdateOneEventLivePayload = {
  __typename?: 'UpdateOneEventLivePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<EventLive>;
};

export type UpdateOneFolderInput = {
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateOneFolderPayload = {
  __typename?: 'UpdateOneFolderPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Folder>;
};

export type UpdateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneImportUsersPlanPayload = {
  __typename?: 'UpdateOneImportUsersPlanPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<ImportUsersPlan>;
};

export type UpdateOneIncentiveCampaignInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumIncentiveCampaignStatus>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  contestationPeriod?: Maybe<IncentiveCampaignContestationPeriodInput>;
  bannerContent?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Boolean']>;
  fileContent?: Maybe<Array<Maybe<IncentiveCampaignFileContentInput>>>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** Origem da fatura */
  invoiceOrigin?: Maybe<Scalars['String']>;
  /** _id da carteira */
  walletId?: Maybe<Scalars['String']>;
  /** Id da reserva de TIM Coins usada para o pagamento de TIM Coins aos usuarios */
  reserveId?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<IncentiveCampaignValidityInput>;
  campaignValidity?: Maybe<IncentiveCampaignCampaignValidityInput>;
};

export type UpdateOneIncentiveCampaignPayload = {
  __typename?: 'UpdateOneIncentiveCampaignPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<IncentiveCampaign>;
};

export type UpdateOneMaterialInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  download?: Maybe<Scalars['Boolean']>;
  pinnedForAll?: Maybe<Scalars['Boolean']>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<MaterialProfileInput>;
  termsForSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneMaterialPayload = {
  __typename?: 'UpdateOneMaterialPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Material>;
};

export type UpdateOneMeuDiaPdvTableInput = {
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  municipio?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  bairro?: Maybe<Scalars['String']>;
  loja?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  manha1011?: Maybe<Scalars['Float']>;
  manha1112?: Maybe<Scalars['Float']>;
  tarde1415?: Maybe<Scalars['Float']>;
  tarde1516?: Maybe<Scalars['Float']>;
  tarde1617?: Maybe<Scalars['Float']>;
};

export type UpdateOneMeuDiaPdvTablePayload = {
  __typename?: 'UpdateOneMeuDiaPdvTablePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<MeuDiaPdvTable>;
};

export type UpdateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type UpdateOneNotificationPayload = {
  __typename?: 'UpdateOneNotificationPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Notification>;
};

export type UpdateOneRemunerationInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  cardImage?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  bannerContent?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  faq?: Maybe<Scalars['String']>;
  validity?: Maybe<RemunerationValidityInput>;
  remunerationValidity?: Maybe<RemunerationRemunerationValidityInput>;
  status?: Maybe<EnumRemunerationStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<RemunerationCommunicationItemsConfigInput>>
  >;
  mailing?: Maybe<Scalars['String']>;
};

export type UpdateOneRemunerationPayload = {
  __typename?: 'UpdateOneRemunerationPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Remuneration>;
};

export type UpdateOneResearchInput = {
  title?: Maybe<Scalars['String']>;
  ramification?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  validity?: Maybe<ResearchValidityInput>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneResearchPayload = {
  __typename?: 'UpdateOneResearchPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Research>;
};

export type UpdateOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  reconhece?: Maybe<UserReconheceInput>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  tour?: Maybe<Scalars['Date']>;
};

export type UpdateOneUserPayload = {
  __typename?: 'UpdateOneUserPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  reconhece?: Maybe<UserReconhece>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTerms>>>;
  /** Vetor de senhas já utilizadas pelo usuário. */
  oldPasswords?: Maybe<Array<Maybe<OldPassword>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** Perfil do usuario. Contem um conjunto de permissoes. */
  roleGroup?: Maybe<RoleGroup>;
  jobTitle?: Maybe<JobTitle>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  tour?: Maybe<Scalars['Date']>;
  isTempEmail?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Notifications>;
  /** Um campo virtual, aponta para as roles do perfil do user. */
  roles: Array<Maybe<Scalars['String']>>;
  token?: Maybe<LoginToken>;
  isTempPassword?: Maybe<Scalars['Boolean']>;
  blockedCommenting?: Maybe<Scalars['Boolean']>;
  failedAttempts?: Maybe<Scalars['Int']>;
  avatar?: Maybe<File>;
};

export type UserAcceptedTerms = {
  __typename?: 'UserAcceptedTerms';
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserAcceptedTermsInput = {
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserContentPermission = {
  __typename?: 'UserContentPermission';
  userId?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteContentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserCorpCheckPayload = {
  __typename?: 'UserCorpCheckPayload';
  CPF?: Maybe<Scalars['String']>;
  isTempPassword?: Maybe<Scalars['Boolean']>;
  isSendRecoveryEmail?: Maybe<Scalars['Boolean']>;
};

export type UserCreditOperation = {
  __typename?: 'UserCreditOperation';
  userId?: Maybe<Scalars['String']>;
  op?: Maybe<EnumUserCreditOperationOp>;
  value?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EnumUserCreditOperationStatus>;
  externalExtractId?: Maybe<Scalars['String']>;
  externalRequestNumber?: Maybe<Scalars['Float']>;
  creditOriginCampaignData?: Maybe<UserCreditOperationCreditOriginCampaignData>;
  userInformation?: Maybe<UserCreditOperationUserInformation>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  User?: Maybe<User>;
};

export type UserCreditOperationCreditOriginCampaignData = {
  __typename?: 'UserCreditOperationCreditOriginCampaignData';
  campaignTitle?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  userCampaignPoints?: Maybe<Scalars['Float']>;
  internalCampaignId?: Maybe<Scalars['String']>;
  addedAt?: Maybe<Scalars['Date']>;
  warningAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserCreditOperationCreditOriginCampaignDataInput = {
  campaignTitle?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  userCampaignPoints?: Maybe<Scalars['Float']>;
  internalCampaignId?: Maybe<Scalars['String']>;
  addedAt?: Maybe<Scalars['Date']>;
  warningAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type UserCreditOperationPagination = {
  __typename?: 'UserCreditOperationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<UserCreditOperation>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserCreditOperationUserInformation = {
  __typename?: 'UserCreditOperationUserInformation';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type UserCreditOperationUserInformationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type UserDeleted = {
  __typename?: 'UserDeleted';
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotifications>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UserDeletedNotifications = {
  __typename?: 'UserDeletedNotifications';
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDeletedNotificationsInput = {
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserFilterFilter = {
  channel?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
};

export type UserHistoryBlock = {
  __typename?: 'UserHistoryBlock';
  /** Responsável pelo bloqueio */
  owner?: Maybe<Scalars['String']>;
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  Owner?: Maybe<User>;
  User?: Maybe<User>;
  Material?: Maybe<Material>;
};

export type UserHistoryBlockMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type UserHistoryBlockPagination = {
  __typename?: 'UserHistoryBlockPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<UserHistoryBlock>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserIdOperatorsFilterAnalyticsInput = {
  /** Id do usuário que executou a ação */
  gt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  gte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  ne?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Id do usuário que executou a ação */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindManyAnalyticsInput = {
  /** Id do usuário que executou a ação */
  gt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  gte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  ne?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Id do usuário que executou a ação */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindManyUserCreditOperationInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindOneUserContentPermissionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserOldPasswordsInput = {
  password?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type UserPagination = {
  __typename?: 'UserPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserReconhece = {
  __typename?: 'UserReconhece';
  /** Creditos adicionados ao usuario */
  campaigns?: Maybe<Array<Maybe<UserReconheceCampaigns>>>;
  /** TIM Coins reservados pela loja externa por meio da api rest /reconhece/... */
  reservedPoints?: Maybe<Scalars['Float']>;
  /** TIM Coins já usados. Os TIM Coins continuam no array campaigns para registro. */
  usedPoints?: Maybe<Scalars['Float']>;
  /** Historico de alterações executadas pela loja externa por meio da api rest /reconhece/... */
  history?: Maybe<Array<Maybe<UserReconheceHistory>>>;
};

export type UserReconheceCampaigns = {
  __typename?: 'UserReconheceCampaigns';
  points?: Maybe<Scalars['Float']>;
  internalCampaignId?: Maybe<Scalars['String']>;
  addedAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserReconheceCampaignsInput = {
  points?: Maybe<Scalars['Float']>;
  internalCampaignId?: Maybe<Scalars['String']>;
  addedAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserReconheceHistory = {
  __typename?: 'UserReconheceHistory';
  /** O id do extrado na loja do reconhece (externa) que deu origem a operação */
  externalExtractId?: Maybe<Scalars['String']>;
  op?: Maybe<EnumUserReconheceHistoryOp>;
  time?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
  externalRequestNumber?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserReconheceHistoryInput = {
  /** O id do extrado na loja do reconhece (externa) que deu origem a operação */
  externalExtractId?: Maybe<Scalars['String']>;
  op?: Maybe<EnumUserReconheceHistoryOp>;
  time?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
  externalRequestNumber?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserReconheceInput = {
  /** Creditos adicionados ao usuario */
  campaigns?: Maybe<Array<Maybe<UserReconheceCampaignsInput>>>;
  /** TIM Coins reservados pela loja externa por meio da api rest /reconhece/... */
  reservedPoints?: Maybe<Scalars['Float']>;
  /** TIM Coins já usados. Os TIM Coins continuam no array campaigns para registro. */
  usedPoints?: Maybe<Scalars['Float']>;
  /** Historico de alterações executadas pela loja externa por meio da api rest /reconhece/... */
  history?: Maybe<Array<Maybe<UserReconheceHistoryInput>>>;
};

export type UserRegistrationPayload = {
  __typename?: 'UserRegistrationPayload';
  record?: Maybe<User>;
  recordId?: Maybe<Scalars['String']>;
};

export type UserRegistrationTypeInput = {
  /** Nome completo do usuário */
  name: Scalars['String'];
  email: Scalars['String'];
  matricula?: Maybe<Scalars['String']>;
  /** Id do perfil do usuario.
   *  ex de query para obter a lista de opcoes:
   *  `query roles { RoleGroupFindMany {_id name roles}}`.
   */
  roleGroup: Scalars['String'];
  /** Lista de ids dos canais a que o usuario tem acesso. consultar valores: `query canais { ChannelFindMany { _id name } }` */
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Se true o usuario sera salvo como inativo */
  disabled?: Maybe<Scalars['Boolean']>;
  /** Id do usuario na origem. ex: id do usuario no PCS */
  externalId?: Maybe<Scalars['String']>;
  /** CPF do usuário cadastrado. */
  CPF?: Maybe<Scalars['String']>;
  /** CNPJ do usuário cadastrado. */
  CNPJ?: Maybe<Scalars['String']>;
  /** Sigla da unidade de federação. Ex: RJ */
  UF?: Maybe<Scalars['String']>;
  /** Telefone de contato do usuario, incluindo o DDD - apenas numeros. */
  phone?: Maybe<Scalars['String']>;
  /** Id do descritivo do cargo do usuario: ex: `especialista` - consultar opcoes:
   * `query jobTitle { JobTitleFindMany { _id name } }`
   */
  jobTitle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  /** Id da regiao do usuario. Para uma lista de valores aceitos, consultar a query: `query regioes { RegionFindMany { _id } }` */
  region?: Maybe<Scalars['String']>;
};

export type UserRegulationPresentation = {
  __typename?: 'UserRegulationPresentation';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regulation?: Maybe<Scalars['Int']>;
  presentation?: Maybe<Scalars['Int']>;
};

export type UserSearch = {
  __typename?: 'UserSearch';
  _id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UserSearchPaginationFilterInput = {
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UserSimpleResult = {
  __typename?: 'UserSimpleResult';
  _id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UserTaNaRede = {
  __typename?: 'UserTaNaRede';
  token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserWordsBlackList = {
  __typename?: 'UserWordsBlackList';
  owner?: Maybe<Scalars['String']>;
  word?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  User?: Maybe<User>;
};

export enum ValidityEnum {
  Expired = 'EXPIRED',
  Future = 'FUTURE',
  Current = 'CURRENT',
  Any = 'ANY'
}

export type WarningAtResult = {
  __typename?: 'WarningAtResult';
  result?: Maybe<Scalars['Boolean']>;
};

export interface Methods extends MethodsDict {
  MenuResolvers: Method<{}, Query['MenuResolvers']>;

  EventFindById: Method<QueryEventFindByIdArgs, Query['EventFindById']>;

  EventFindMany: Method<QueryEventFindManyArgs, Query['EventFindMany']>;

  EventPagination: Method<QueryEventPaginationArgs, Query['EventPagination']>;

  FeedFindById: Method<QueryFeedFindByIdArgs, Query['FeedFindById']>;

  FeedFindMany: Method<QueryFeedFindManyArgs, Query['FeedFindMany']>;

  FeedPagination: Method<QueryFeedPaginationArgs, Query['FeedPagination']>;

  FeedUpdateLike: Method<QueryFeedUpdateLikeArgs, Query['FeedUpdateLike']>;

  MeuDiaPdvTableFindOne: Method<
    QueryMeuDiaPdvTableFindOneArgs,
    Query['MeuDiaPdvTableFindOne']
  >;

  MeuDiaPdvTableFindById: Method<
    QueryMeuDiaPdvTableFindByIdArgs,
    Query['MeuDiaPdvTableFindById']
  >;

  MeuDiaPdvTableFindMany: Method<
    QueryMeuDiaPdvTableFindManyArgs,
    Query['MeuDiaPdvTableFindMany']
  >;

  MeuDiaPdvTableFindByIds: Method<
    QueryMeuDiaPdvTableFindByIdsArgs,
    Query['MeuDiaPdvTableFindByIds']
  >;

  IncentiveCampaignPagination: Method<
    QueryIncentiveCampaignPaginationArgs,
    Query['IncentiveCampaignPagination']
  >;

  IncentiveCampaignFindMany: Method<
    QueryIncentiveCampaignFindManyArgs,
    Query['IncentiveCampaignFindMany']
  >;

  IncentiveCampaignFindOne: Method<
    QueryIncentiveCampaignFindOneArgs,
    Query['IncentiveCampaignFindOne']
  >;

  IncentiveCampaignFindById: Method<
    QueryIncentiveCampaignFindByIdArgs,
    Query['IncentiveCampaignFindById']
  >;

  CampaignContestationFindOne: Method<
    QueryCampaignContestationFindOneArgs,
    Query['CampaignContestationFindOne']
  >;

  CampaignContestationPagination: Method<
    QueryCampaignContestationPaginationArgs,
    Query['CampaignContestationPagination']
  >;

  IncentiveCampaignStatusOptions: Method<
    {},
    Query['IncentiveCampaignStatusOptions']
  >;

  ReconheceReverseUsersParse: Method<
    QueryReconheceReverseUsersParseArgs,
    Query['ReconheceReverseUsersParse']
  >;

  AnalyticsIncentiveCampaign: Method<
    QueryAnalyticsIncentiveCampaignArgs,
    Query['AnalyticsIncentiveCampaign']
  >;

  RegulationPresentation: Method<
    QueryRegulationPresentationArgs,
    Query['RegulationPresentation']
  >;

  ResearchFindById: Method<
    QueryResearchFindByIdArgs,
    Query['ResearchFindById']
  >;

  ResearchFindMany: Method<
    QueryResearchFindManyArgs,
    Query['ResearchFindMany']
  >;

  ResearchPagination: Method<
    QueryResearchPaginationArgs,
    Query['ResearchPagination']
  >;

  AnswerResearchFindById: Method<
    QueryAnswerResearchFindByIdArgs,
    Query['AnswerResearchFindById']
  >;

  AnswerResearchFindMany: Method<
    QueryAnswerResearchFindManyArgs,
    Query['AnswerResearchFindMany']
  >;

  AnswerResearchPagination: Method<
    QueryAnswerResearchPaginationArgs,
    Query['AnswerResearchPagination']
  >;

  ResearchAnswersDownload: Method<
    QueryResearchAnswersDownloadArgs,
    Query['ResearchAnswersDownload']
  >;

  EmailTemplateFindById: Method<
    QueryEmailTemplateFindByIdArgs,
    Query['EmailTemplateFindById']
  >;

  EmailTemplateFindMany: Method<
    QueryEmailTemplateFindManyArgs,
    Query['EmailTemplateFindMany']
  >;

  EmailTemplatePagination: Method<
    QueryEmailTemplatePaginationArgs,
    Query['EmailTemplatePagination']
  >;

  AnalyticsDocumentsFindById: Method<
    QueryAnalyticsDocumentsFindByIdArgs,
    Query['AnalyticsDocumentsFindById']
  >;

  AnalyticsDocumentsFindMany: Method<
    QueryAnalyticsDocumentsFindManyArgs,
    Query['AnalyticsDocumentsFindMany']
  >;

  AnalyticsDocumentsPagination: Method<
    QueryAnalyticsDocumentsPaginationArgs,
    Query['AnalyticsDocumentsPagination']
  >;

  AnalyticsCommunicationsFindById: Method<
    QueryAnalyticsCommunicationsFindByIdArgs,
    Query['AnalyticsCommunicationsFindById']
  >;

  AnalyticsCommunicationsFindMany: Method<
    QueryAnalyticsCommunicationsFindManyArgs,
    Query['AnalyticsCommunicationsFindMany']
  >;

  AnalyticsCommunicationsPagination: Method<
    QueryAnalyticsCommunicationsPaginationArgs,
    Query['AnalyticsCommunicationsPagination']
  >;

  RegionFindMany: Method<QueryRegionFindManyArgs, Query['RegionFindMany']>;

  RegionFindOne: Method<QueryRegionFindOneArgs, Query['RegionFindOne']>;

  RegionPagination: Method<
    QueryRegionPaginationArgs,
    Query['RegionPagination']
  >;

  RegionItemFindMany: Method<
    QueryRegionItemFindManyArgs,
    Query['RegionItemFindMany']
  >;

  RegionItemFindOne: Method<
    QueryRegionItemFindOneArgs,
    Query['RegionItemFindOne']
  >;

  RegionItemPagination: Method<
    QueryRegionItemPaginationArgs,
    Query['RegionItemPagination']
  >;

  RegionItemDistinctDDD: Method<{}, Query['RegionItemDistinctDDD']>;

  RegionItemDistinctRegionAndUF: Method<
    {},
    Query['RegionItemDistinctRegionAndUF']
  >;

  JobTitleFindOne: Method<QueryJobTitleFindOneArgs, Query['JobTitleFindOne']>;

  JobTitleFindMany: Method<
    QueryJobTitleFindManyArgs,
    Query['JobTitleFindMany']
  >;

  RoleGroupFindOne: Method<
    QueryRoleGroupFindOneArgs,
    Query['RoleGroupFindOne']
  >;

  RoleGroupFindMany: Method<
    QueryRoleGroupFindManyArgs,
    Query['RoleGroupFindMany']
  >;

  RoleGroupPagination: Method<
    QueryRoleGroupPaginationArgs,
    Query['RoleGroupPagination']
  >;

  ChannelFindMany: Method<QueryChannelFindManyArgs, Query['ChannelFindMany']>;

  ChannelFindOne: Method<QueryChannelFindOneArgs, Query['ChannelFindOne']>;

  ChannelPagination: Method<
    QueryChannelPaginationArgs,
    Query['ChannelPagination']
  >;

  UserContentPermissionFindOne: Method<
    QueryUserContentPermissionFindOneArgs,
    Query['UserContentPermissionFindOne']
  >;

  ContentPermissionsHelper: Method<{}, Query['ContentPermissionsHelper']>;

  UserPagination: Method<QueryUserPaginationArgs, Query['UserPagination']>;

  UserFindByIds: Method<QueryUserFindByIdsArgs, Query['UserFindByIds']>;

  UserFindById: Method<QueryUserFindByIdArgs, Query['UserFindById']>;

  UserFindOne: Method<QueryUserFindOneArgs, Query['UserFindOne']>;

  UserFindMany: Method<QueryUserFindManyArgs, Query['UserFindMany']>;

  User: Method<{}, Query['User']>;

  UserSearch: Method<QueryUserSearchArgs, Query['UserSearch']>;

  UserSearchPagination: Method<
    QueryUserSearchPaginationArgs,
    Query['UserSearchPagination']
  >;

  TextFindBlackList: Method<
    QueryTextFindBlackListArgs,
    Query['TextFindBlackList']
  >;

  UserFindByEmails: Method<
    QueryUserFindByEmailsArgs,
    Query['UserFindByEmails']
  >;

  UserFindByMatricula: Method<
    QueryUserFindByMatriculaArgs,
    Query['UserFindByMatricula']
  >;

  FileFindByIds: Method<QueryFileFindByIdsArgs, Query['FileFindByIds']>;

  FileFindById: Method<QueryFileFindByIdArgs, Query['FileFindById']>;

  FilePagination: Method<QueryFilePaginationArgs, Query['FilePagination']>;

  MaterialFindById: Method<
    QueryMaterialFindByIdArgs,
    Query['MaterialFindById']
  >;

  MaterialFindMany: Method<
    QueryMaterialFindManyArgs,
    Query['MaterialFindMany']
  >;

  MaterialFindOne: Method<QueryMaterialFindOneArgs, Query['MaterialFindOne']>;

  MaterialPagination: Method<
    QueryMaterialPaginationArgs,
    Query['MaterialPagination']
  >;

  MaterialCount: Method<QueryMaterialCountArgs, Query['MaterialCount']>;

  MaterialStatusOptions: Method<{}, Query['MaterialStatusOptions']>;

  MaterialSearchOwners: Method<
    QueryMaterialSearchOwnersArgs,
    Query['MaterialSearchOwners']
  >;

  EmailFindMany: Method<QueryEmailFindManyArgs, Query['EmailFindMany']>;

  EmailPagination: Method<QueryEmailPaginationArgs, Query['EmailPagination']>;

  EmailFindOne: Method<QueryEmailFindOneArgs, Query['EmailFindOne']>;

  EmailCheckRead: Method<QueryEmailCheckReadArgs, Query['EmailCheckRead']>;

  MaterialSearch: Method<QueryMaterialSearchArgs, Query['MaterialSearch']>;

  MaterialFindPinned: Method<
    QueryMaterialFindPinnedArgs,
    Query['MaterialFindPinned']
  >;

  NotificationFindMany: Method<
    QueryNotificationFindManyArgs,
    Query['NotificationFindMany']
  >;

  NotificationFindOne: Method<
    QueryNotificationFindOneArgs,
    Query['NotificationFindOne']
  >;

  NotificationPagination: Method<
    QueryNotificationPaginationArgs,
    Query['NotificationPagination']
  >;

  HelperAddress: Method<QueryHelperAddressArgs, Query['HelperAddress']>;

  CategoryFindOne: Method<QueryCategoryFindOneArgs, Query['CategoryFindOne']>;

  CategoryFindById: Method<
    QueryCategoryFindByIdArgs,
    Query['CategoryFindById']
  >;

  CategoryFindMany: Method<
    QueryCategoryFindManyArgs,
    Query['CategoryFindMany']
  >;

  CategoryFindByIds: Method<
    QueryCategoryFindByIdsArgs,
    Query['CategoryFindByIds']
  >;

  CategoryPagination: Method<
    QueryCategoryPaginationArgs,
    Query['CategoryPagination']
  >;

  WebPushPublicKey: Method<{}, Query['WebPushPublicKey']>;

  PinFindMany: Method<QueryPinFindManyArgs, Query['PinFindMany']>;

  PinPagination: Method<QueryPinPaginationArgs, Query['PinPagination']>;

  ShareFindMany: Method<QueryShareFindManyArgs, Query['ShareFindMany']>;

  SharePagination: Method<QuerySharePaginationArgs, Query['SharePagination']>;

  CommentPagination: Method<
    QueryCommentPaginationArgs,
    Query['CommentPagination']
  >;

  CommentFindOne: Method<QueryCommentFindOneArgs, Query['CommentFindOne']>;

  CommentFindById: Method<QueryCommentFindByIdArgs, Query['CommentFindById']>;

  CommentFindMany: Method<QueryCommentFindManyArgs, Query['CommentFindMany']>;

  CommentSortPagination: Method<
    QueryCommentSortPaginationArgs,
    Query['CommentSortPagination']
  >;

  EventLivePagination: Method<
    QueryEventLivePaginationArgs,
    Query['EventLivePagination']
  >;

  EventLiveFindOne: Method<
    QueryEventLiveFindOneArgs,
    Query['EventLiveFindOne']
  >;

  EventLiveFindById: Method<
    QueryEventLiveFindByIdArgs,
    Query['EventLiveFindById']
  >;

  BannerFindMany: Method<QueryBannerFindManyArgs, Query['BannerFindMany']>;

  BannerFindOne: Method<QueryBannerFindOneArgs, Query['BannerFindOne']>;

  AnalyticsCount: Method<QueryAnalyticsCountArgs, Query['AnalyticsCount']>;

  AnalyticsPagination: Method<
    QueryAnalyticsPaginationArgs,
    Query['AnalyticsPagination']
  >;

  AnalyticsUsersReport: Method<
    QueryAnalyticsUsersReportArgs,
    Query['AnalyticsUsersReport']
  >;

  AnalyticsFindReport: Method<
    QueryAnalyticsFindReportArgs,
    Query['AnalyticsFindReport']
  >;

  AnalyticsUserNotification: Method<
    QueryAnalyticsUserNotificationArgs,
    Query['AnalyticsUserNotification']
  >;

  AnalyticsPin: Method<QueryAnalyticsPinArgs, Query['AnalyticsPin']>;

  AnalyticsComment: Method<
    QueryAnalyticsCommentArgs,
    Query['AnalyticsComment']
  >;

  AnalyticsPoints: Method<QueryAnalyticsPointsArgs, Query['AnalyticsPoints']>;

  AnalyticsDownloads: Method<
    QueryAnalyticsDownloadsArgs,
    Query['AnalyticsDownloads']
  >;

  SearchCountFindMany: Method<
    QuerySearchCountFindManyArgs,
    Query['SearchCountFindMany']
  >;

  SearchCountPagination: Method<
    QuerySearchCountPaginationArgs,
    Query['SearchCountPagination']
  >;

  FolderFindMany: Method<QueryFolderFindManyArgs, Query['FolderFindMany']>;

  FolderFindById: Method<QueryFolderFindByIdArgs, Query['FolderFindById']>;

  FolderPagination: Method<
    QueryFolderPaginationArgs,
    Query['FolderPagination']
  >;

  RecentsActivitiesPagination: Method<
    QueryRecentsActivitiesPaginationArgs,
    Query['RecentsActivitiesPagination']
  >;

  UserWordsBlackListFindMany: Method<
    QueryUserWordsBlackListFindManyArgs,
    Query['UserWordsBlackListFindMany']
  >;

  UserWordsBlackListFindById: Method<
    QueryUserWordsBlackListFindByIdArgs,
    Query['UserWordsBlackListFindById']
  >;

  DownloadSensitiveFilesPagination: Method<
    QueryDownloadSensitiveFilesPaginationArgs,
    Query['DownloadSensitiveFilesPagination']
  >;

  DownloadUsersInfo: Method<{}, Query['DownloadUsersInfo']>;

  DownloadAcceptedTerms: Method<
    QueryDownloadAcceptedTermsArgs,
    Query['DownloadAcceptedTerms']
  >;

  DownloadUsersAcceptedPoints: Method<
    QueryDownloadUsersAcceptedPointsArgs,
    Query['DownloadUsersAcceptedPoints']
  >;

  DownloadUsersWithPoints: Method<{}, Query['DownloadUsersWithPoints']>;

  DownloadUsersBase: Method<{}, Query['DownloadUsersBase']>;

  DownloadExtraCredit: Method<
    QueryDownloadExtraCreditArgs,
    Query['DownloadExtraCredit']
  >;

  DownloadReversalPoints: Method<
    QueryDownloadReversalPointsArgs,
    Query['DownloadReversalPoints']
  >;

  DownloadExpirePoints: Method<{}, Query['DownloadExpirePoints']>;

  DownloadDebitExpirePoints: Method<{}, Query['DownloadDebitExpirePoints']>;

  ImportUsersPlanFindMany: Method<
    QueryImportUsersPlanFindManyArgs,
    Query['ImportUsersPlanFindMany']
  >;

  ImportUsersPlanFindById: Method<
    QueryImportUsersPlanFindByIdArgs,
    Query['ImportUsersPlanFindById']
  >;

  ImportUsersPlanFindByIds: Method<
    QueryImportUsersPlanFindByIdsArgs,
    Query['ImportUsersPlanFindByIds']
  >;

  ImportUsersPlanPagination: Method<
    QueryImportUsersPlanPaginationArgs,
    Query['ImportUsersPlanPagination']
  >;

  UserDeletedFindByIds: Method<
    QueryUserDeletedFindByIdsArgs,
    Query['UserDeletedFindByIds']
  >;

  UserDeletedFindOne: Method<
    QueryUserDeletedFindOneArgs,
    Query['UserDeletedFindOne']
  >;

  UserDeletedFindMany: Method<
    QueryUserDeletedFindManyArgs,
    Query['UserDeletedFindMany']
  >;

  UserHistoryBlockFindMany: Method<
    QueryUserHistoryBlockFindManyArgs,
    Query['UserHistoryBlockFindMany']
  >;

  UserHistoryBlockPagination: Method<
    QueryUserHistoryBlockPaginationArgs,
    Query['UserHistoryBlockPagination']
  >;

  FutureOperationFindById: Method<
    QueryFutureOperationFindByIdArgs,
    Query['FutureOperationFindById']
  >;

  EmailStatsFindOne: Method<
    QueryEmailStatsFindOneArgs,
    Query['EmailStatsFindOne']
  >;

  EmailStatsFindMany: Method<
    QueryEmailStatsFindManyArgs,
    Query['EmailStatsFindMany']
  >;

  MeuDiaPdvTableAll: Method<{}, Query['MeuDiaPdvTableAll']>;

  UpdatedUsers: Method<{}, Query['UpdatedUsers']>;

  SelectRegionalProfiles: Method<{}, Query['SelectRegionalProfiles']>;

  EmailAnalytics: Method<QueryEmailAnalyticsArgs, Query['EmailAnalytics']>;

  EmailStatsAnalytics: Method<
    QueryEmailStatsAnalyticsArgs,
    Query['EmailStatsAnalytics']
  >;

  ReportMenuView: Method<QueryReportMenuViewArgs, Query['ReportMenuView']>;

  RemunerationPagination: Method<
    QueryRemunerationPaginationArgs,
    Query['RemunerationPagination']
  >;

  RemunerationFindMany: Method<
    QueryRemunerationFindManyArgs,
    Query['RemunerationFindMany']
  >;

  RemunerationFindOne: Method<
    QueryRemunerationFindOneArgs,
    Query['RemunerationFindOne']
  >;

  RemunerationFindById: Method<
    QueryRemunerationFindByIdArgs,
    Query['RemunerationFindById']
  >;

  RemunerationCount: Method<
    QueryRemunerationCountArgs,
    Query['RemunerationCount']
  >;

  ReportRemuneration: Method<{}, Query['ReportRemuneration']>;

  AnalyticsMaterialView: Method<
    QueryAnalyticsMaterialViewArgs,
    Query['AnalyticsMaterialView']
  >;

  ReportTourUser: Method<QueryReportTourUserArgs, Query['ReportTourUser']>;

  AnalyticsMaterialDownload: Method<
    QueryAnalyticsMaterialDownloadArgs,
    Query['AnalyticsMaterialDownload']
  >;

  ReportDocumentsActions: Method<
    QueryReportDocumentsActionsArgs,
    Query['ReportDocumentsActions']
  >;

  ReconheceWalletPagination: Method<
    QueryReconheceWalletPaginationArgs,
    Query['ReconheceWalletPagination']
  >;

  ReconheceWalletFindMany: Method<
    QueryReconheceWalletFindManyArgs,
    Query['ReconheceWalletFindMany']
  >;

  ReconheceWalletFindById: Method<
    QueryReconheceWalletFindByIdArgs,
    Query['ReconheceWalletFindById']
  >;

  ReconheceWalletSum: Method<
    QueryReconheceWalletSumArgs,
    Query['ReconheceWalletSum']
  >;

  UserCreditOperationFindById: Method<
    QueryUserCreditOperationFindByIdArgs,
    Query['UserCreditOperationFindById']
  >;

  UserCreditOperationPagination: Method<
    QueryUserCreditOperationPaginationArgs,
    Query['UserCreditOperationPagination']
  >;

  UserCreditOperationFindByIncentiveCampaignId: Method<
    QueryUserCreditOperationFindByIncentiveCampaignIdArgs,
    Query['UserCreditOperationFindByIncentiveCampaignId']
  >;

  ExtractUserCreditPoints: Method<
    QueryExtractUserCreditPointsArgs,
    Query['ExtractUserCreditPoints']
  >;

  DownloadtUserCreditPoints: Method<
    QueryDownloadtUserCreditPointsArgs,
    Query['DownloadtUserCreditPoints']
  >;

  UserCreditOperationAnalytics: Method<
    QueryUserCreditOperationAnalyticsArgs,
    Query['UserCreditOperationAnalytics']
  >;

  ReconheceTransactionPagination: Method<
    QueryReconheceTransactionPaginationArgs,
    Query['ReconheceTransactionPagination']
  >;

  ReconheceInvoiceOriginFindMany: Method<
    QueryReconheceInvoiceOriginFindManyArgs,
    Query['ReconheceInvoiceOriginFindMany']
  >;

  ReconheceUserBalance: Method<{}, Query['ReconheceUserBalance']>;

  EventCreateOne: Method<
    MutationEventCreateOneArgs,
    Mutation['EventCreateOne']
  >;

  EventUpdateById: Method<
    MutationEventUpdateByIdArgs,
    Mutation['EventUpdateById']
  >;

  EventRemoveById: Method<
    MutationEventRemoveByIdArgs,
    Mutation['EventRemoveById']
  >;

  FeedCreateOne: Method<MutationFeedCreateOneArgs, Mutation['FeedCreateOne']>;

  FeedUpdateById: Method<
    MutationFeedUpdateByIdArgs,
    Mutation['FeedUpdateById']
  >;

  FeedRemoveById: Method<
    MutationFeedRemoveByIdArgs,
    Mutation['FeedRemoveById']
  >;

  MeuDiaPdvTableCreateOne: Method<
    MutationMeuDiaPdvTableCreateOneArgs,
    Mutation['MeuDiaPdvTableCreateOne']
  >;

  MeuDiaPdvTableUpdateOne: Method<
    MutationMeuDiaPdvTableUpdateOneArgs,
    Mutation['MeuDiaPdvTableUpdateOne']
  >;

  MeuDiaPdvTableRemoveById: Method<
    MutationMeuDiaPdvTableRemoveByIdArgs,
    Mutation['MeuDiaPdvTableRemoveById']
  >;

  MeuDiaPdvTableCreateMany: Method<
    MutationMeuDiaPdvTableCreateManyArgs,
    Mutation['MeuDiaPdvTableCreateMany']
  >;

  IncentiveCampaignCreateOne: Method<
    MutationIncentiveCampaignCreateOneArgs,
    Mutation['IncentiveCampaignCreateOne']
  >;

  IncentiveCampaignUpdateOne: Method<
    MutationIncentiveCampaignUpdateOneArgs,
    Mutation['IncentiveCampaignUpdateOne']
  >;

  IncentiveCampaignRemoveById: Method<
    MutationIncentiveCampaignRemoveByIdArgs,
    Mutation['IncentiveCampaignRemoveById']
  >;

  CampaignContestationCreateOne: Method<
    MutationCampaignContestationCreateOneArgs,
    Mutation['CampaignContestationCreateOne']
  >;

  CampaignContestationResolve: Method<
    MutationCampaignContestationResolveArgs,
    Mutation['CampaignContestationResolve']
  >;

  IncentiveCampaignPayPoints: Method<
    MutationIncentiveCampaignPayPointsArgs,
    Mutation['IncentiveCampaignPayPoints']
  >;

  ReconheceReversePoints: Method<
    MutationReconheceReversePointsArgs,
    Mutation['ReconheceReversePoints']
  >;

  ReconhecePayExtraPoints: Method<
    MutationReconhecePayExtraPointsArgs,
    Mutation['ReconhecePayExtraPoints']
  >;

  IncentiveCampaignLikeToggle: Method<
    MutationIncentiveCampaignLikeToggleArgs,
    Mutation['IncentiveCampaignLikeToggle']
  >;

  ResearchCreateOne: Method<
    MutationResearchCreateOneArgs,
    Mutation['ResearchCreateOne']
  >;

  ResearchUpdateOne: Method<
    MutationResearchUpdateOneArgs,
    Mutation['ResearchUpdateOne']
  >;

  ResearchUpdateById: Method<
    MutationResearchUpdateByIdArgs,
    Mutation['ResearchUpdateById']
  >;

  ResearchRemoveById: Method<
    MutationResearchRemoveByIdArgs,
    Mutation['ResearchRemoveById']
  >;

  AnswerResearchCreateOne: Method<
    MutationAnswerResearchCreateOneArgs,
    Mutation['AnswerResearchCreateOne']
  >;

  AnswerResearchUpdateOne: Method<
    MutationAnswerResearchUpdateOneArgs,
    Mutation['AnswerResearchUpdateOne']
  >;

  AnswerResearchUpdateById: Method<
    MutationAnswerResearchUpdateByIdArgs,
    Mutation['AnswerResearchUpdateById']
  >;

  AnswerResearchRemoveById: Method<
    MutationAnswerResearchRemoveByIdArgs,
    Mutation['AnswerResearchRemoveById']
  >;

  EmailTemplateCreateOne: Method<
    MutationEmailTemplateCreateOneArgs,
    Mutation['EmailTemplateCreateOne']
  >;

  EmailTemplateUpdateById: Method<
    MutationEmailTemplateUpdateByIdArgs,
    Mutation['EmailTemplateUpdateById']
  >;

  EmailTemplateRemoveById: Method<
    MutationEmailTemplateRemoveByIdArgs,
    Mutation['EmailTemplateRemoveById']
  >;

  AnalyticsDocumentsCreateOne: Method<
    MutationAnalyticsDocumentsCreateOneArgs,
    Mutation['AnalyticsDocumentsCreateOne']
  >;

  AnalyticsDocumentsUpdateOne: Method<
    MutationAnalyticsDocumentsUpdateOneArgs,
    Mutation['AnalyticsDocumentsUpdateOne']
  >;

  AnalyticsDocumentsUpdateById: Method<
    MutationAnalyticsDocumentsUpdateByIdArgs,
    Mutation['AnalyticsDocumentsUpdateById']
  >;

  AnalyticsDocumentsRemoveById: Method<
    MutationAnalyticsDocumentsRemoveByIdArgs,
    Mutation['AnalyticsDocumentsRemoveById']
  >;

  AnalyticsCommunicationsCreateOne: Method<
    MutationAnalyticsCommunicationsCreateOneArgs,
    Mutation['AnalyticsCommunicationsCreateOne']
  >;

  AnalyticsCommunicationsUpdateOne: Method<
    MutationAnalyticsCommunicationsUpdateOneArgs,
    Mutation['AnalyticsCommunicationsUpdateOne']
  >;

  AnalyticsCommunicationsUpdateById: Method<
    MutationAnalyticsCommunicationsUpdateByIdArgs,
    Mutation['AnalyticsCommunicationsUpdateById']
  >;

  AnalyticsCommunicationsRemoveById: Method<
    MutationAnalyticsCommunicationsRemoveByIdArgs,
    Mutation['AnalyticsCommunicationsRemoveById']
  >;

  RegionRemoveOne: Method<
    MutationRegionRemoveOneArgs,
    Mutation['RegionRemoveOne']
  >;

  UserContentPermissionToggle: Method<
    MutationUserContentPermissionToggleArgs,
    Mutation['UserContentPermissionToggle']
  >;

  UserUpdateOne: Method<MutationUserUpdateOneArgs, Mutation['UserUpdateOne']>;

  UserRemoveById: Method<
    MutationUserRemoveByIdArgs,
    Mutation['UserRemoveById']
  >;

  UserAcceptTerm: Method<
    MutationUserAcceptTermArgs,
    Mutation['UserAcceptTerm']
  >;

  UserLoginWithPassword: Method<
    MutationUserLoginWithPasswordArgs,
    Mutation['UserLoginWithPassword']
  >;

  UserLoginWithToken: Method<
    MutationUserLoginWithTokenArgs,
    Mutation['UserLoginWithToken']
  >;

  UserLoginRenewToken: Method<
    MutationUserLoginRenewTokenArgs,
    Mutation['UserLoginRenewToken']
  >;

  UserSaveLogoutAnalytics: Method<{}, Mutation['UserSaveLogoutAnalytics']>;

  UserSaveLoginAnalyticsPCS: Method<{}, Mutation['UserSaveLoginAnalyticsPCS']>;

  UserFillEmail: Method<MutationUserFillEmailArgs, Mutation['UserFillEmail']>;

  UserFillAvatarId: Method<
    MutationUserFillAvatarIdArgs,
    Mutation['UserFillAvatarId']
  >;

  UserOptIn: Method<MutationUserOptInArgs, Mutation['UserOptIn']>;

  UserMarkNotificationAsRead: Method<
    MutationUserMarkNotificationAsReadArgs,
    Mutation['UserMarkNotificationAsRead']
  >;

  UserDeleteNotification: Method<
    MutationUserDeleteNotificationArgs,
    Mutation['UserDeleteNotification']
  >;

  UserDeleteManyNotification: Method<
    MutationUserDeleteManyNotificationArgs,
    Mutation['UserDeleteManyNotification']
  >;

  UserRegistration: Method<
    MutationUserRegistrationArgs,
    Mutation['UserRegistration']
  >;

  UserBlockComments: Method<
    MutationUserBlockCommentsArgs,
    Mutation['UserBlockComments']
  >;

  ChangeToUserTaNarede: Method<{}, Mutation['ChangeToUserTaNarede']>;

  UserChangePassword: Method<
    MutationUserChangePasswordArgs,
    Mutation['UserChangePassword']
  >;

  UserFillEmailSecondary: Method<
    MutationUserFillEmailSecondaryArgs,
    Mutation['UserFillEmailSecondary']
  >;

  UserConnectMovidesk: Method<{}, Mutation['UserConnectMovidesk']>;

  UserDisable: Method<MutationUserDisableArgs, Mutation['UserDisable']>;

  FileCreateOne: Method<MutationFileCreateOneArgs, Mutation['FileCreateOne']>;

  FileRemoveById: Method<
    MutationFileRemoveByIdArgs,
    Mutation['FileRemoveById']
  >;

  FileUpdateById: Method<
    MutationFileUpdateByIdArgs,
    Mutation['FileUpdateById']
  >;

  MaterialCreateOne: Method<
    MutationMaterialCreateOneArgs,
    Mutation['MaterialCreateOne']
  >;

  MaterialUpdateOne: Method<
    MutationMaterialUpdateOneArgs,
    Mutation['MaterialUpdateOne']
  >;

  MaterialUpdateById: Method<
    MutationMaterialUpdateByIdArgs,
    Mutation['MaterialUpdateById']
  >;

  MaterialRemoveOne: Method<
    MutationMaterialRemoveOneArgs,
    Mutation['MaterialRemoveOne']
  >;

  CommunicationCreateOne: Method<
    MutationCommunicationCreateOneArgs,
    Mutation['CommunicationCreateOne']
  >;

  CommunicationUpdateOne: Method<
    MutationCommunicationUpdateOneArgs,
    Mutation['CommunicationUpdateOne']
  >;

  CommunicationApproveChannel: Method<
    MutationCommunicationApproveChannelArgs,
    Mutation['CommunicationApproveChannel']
  >;

  CommunicationDisapproveChannel: Method<
    MutationCommunicationDisapproveChannelArgs,
    Mutation['CommunicationDisapproveChannel']
  >;

  CommunicationRemoveApprover: Method<
    MutationCommunicationRemoveApproverArgs,
    Mutation['CommunicationRemoveApprover']
  >;

  EmailFillTemplate: Method<
    MutationEmailFillTemplateArgs,
    Mutation['EmailFillTemplate']
  >;

  EmailMarkAsRead: Method<
    MutationEmailMarkAsReadArgs,
    Mutation['EmailMarkAsRead']
  >;

  SendEmailFront: Method<
    MutationSendEmailFrontArgs,
    Mutation['SendEmailFront']
  >;

  MaterialSendTestEmail: Method<
    MutationMaterialSendTestEmailArgs,
    Mutation['MaterialSendTestEmail']
  >;

  MaterialLikeToggle: Method<
    MutationMaterialLikeToggleArgs,
    Mutation['MaterialLikeToggle']
  >;

  UpdateDeteachedAndLatestVideo: Method<
    MutationUpdateDeteachedAndLatestVideoArgs,
    Mutation['UpdateDeteachedAndLatestVideo']
  >;

  NotificationCreateOne: Method<
    MutationNotificationCreateOneArgs,
    Mutation['NotificationCreateOne']
  >;

  NotificationCreateMany: Method<
    MutationNotificationCreateManyArgs,
    Mutation['NotificationCreateMany']
  >;

  NotificationUpdateOne: Method<
    MutationNotificationUpdateOneArgs,
    Mutation['NotificationUpdateOne']
  >;

  NotificationRemoveById: Method<
    MutationNotificationRemoveByIdArgs,
    Mutation['NotificationRemoveById']
  >;

  CategoryCreateOne: Method<
    MutationCategoryCreateOneArgs,
    Mutation['CategoryCreateOne']
  >;

  CategoryUpdateOne: Method<
    MutationCategoryUpdateOneArgs,
    Mutation['CategoryUpdateOne']
  >;

  CategoryRemoveById: Method<
    MutationCategoryRemoveByIdArgs,
    Mutation['CategoryRemoveById']
  >;

  CategoryCreateMany: Method<
    MutationCategoryCreateManyArgs,
    Mutation['CategoryCreateMany']
  >;

  WebPushSubscribe: Method<
    MutationWebPushSubscribeArgs,
    Mutation['WebPushSubscribe']
  >;

  WebPushSendNotification: Method<
    MutationWebPushSendNotificationArgs,
    Mutation['WebPushSendNotification']
  >;

  PinCreateOne: Method<MutationPinCreateOneArgs, Mutation['PinCreateOne']>;

  PinRemoveOne: Method<MutationPinRemoveOneArgs, Mutation['PinRemoveOne']>;

  PinRemoveMany: Method<MutationPinRemoveManyArgs, Mutation['PinRemoveMany']>;

  ShareCreateOne: Method<
    MutationShareCreateOneArgs,
    Mutation['ShareCreateOne']
  >;

  ShareRemoveOne: Method<
    MutationShareRemoveOneArgs,
    Mutation['ShareRemoveOne']
  >;

  ShareRemoveMe: Method<MutationShareRemoveMeArgs, Mutation['ShareRemoveMe']>;

  CommentCreateOne: Method<
    MutationCommentCreateOneArgs,
    Mutation['CommentCreateOne']
  >;

  CommentUpdateOne: Method<
    MutationCommentUpdateOneArgs,
    Mutation['CommentUpdateOne']
  >;

  CommentRemoveById: Method<
    MutationCommentRemoveByIdArgs,
    Mutation['CommentRemoveById']
  >;

  CommentLikeToggle: Method<
    MutationCommentLikeToggleArgs,
    Mutation['CommentLikeToggle']
  >;

  EventLiveCreateOne: Method<
    MutationEventLiveCreateOneArgs,
    Mutation['EventLiveCreateOne']
  >;

  EventLiveRemoveById: Method<
    MutationEventLiveRemoveByIdArgs,
    Mutation['EventLiveRemoveById']
  >;

  EventLiveUpdateOne: Method<
    MutationEventLiveUpdateOneArgs,
    Mutation['EventLiveUpdateOne']
  >;

  EventLiveCloseRoom: Method<
    MutationEventLiveCloseRoomArgs,
    Mutation['EventLiveCloseRoom']
  >;

  EventLiveLikeToggle: Method<
    MutationEventLiveLikeToggleArgs,
    Mutation['EventLiveLikeToggle']
  >;

  BannerRemoveById: Method<
    MutationBannerRemoveByIdArgs,
    Mutation['BannerRemoveById']
  >;

  BannerCreateOne: Method<
    MutationBannerCreateOneArgs,
    Mutation['BannerCreateOne']
  >;

  BannerUpdateOne: Method<
    MutationBannerUpdateOneArgs,
    Mutation['BannerUpdateOne']
  >;

  AnalyticsPageView: Method<
    MutationAnalyticsPageViewArgs,
    Mutation['AnalyticsPageView']
  >;

  AnalyticsTrackEvent: Method<
    MutationAnalyticsTrackEventArgs,
    Mutation['AnalyticsTrackEvent']
  >;

  FolderCreateOne: Method<
    MutationFolderCreateOneArgs,
    Mutation['FolderCreateOne']
  >;

  FolderRemoveOne: Method<
    MutationFolderRemoveOneArgs,
    Mutation['FolderRemoveOne']
  >;

  FolderRemoveMany: Method<
    MutationFolderRemoveManyArgs,
    Mutation['FolderRemoveMany']
  >;

  FolderUpdateOne: Method<
    MutationFolderUpdateOneArgs,
    Mutation['FolderUpdateOne']
  >;

  RecentsActivitiesCreateOne: Method<
    MutationRecentsActivitiesCreateOneArgs,
    Mutation['RecentsActivitiesCreateOne']
  >;

  UserWordsBlackListCreateOne: Method<
    MutationUserWordsBlackListCreateOneArgs,
    Mutation['UserWordsBlackListCreateOne']
  >;

  ImportUsersPlanCreateOne: Method<
    MutationImportUsersPlanCreateOneArgs,
    Mutation['ImportUsersPlanCreateOne']
  >;

  ImportUsersPlanUpdateOne: Method<
    MutationImportUsersPlanUpdateOneArgs,
    Mutation['ImportUsersPlanUpdateOne']
  >;

  UserHistoryBlockCreateOne: Method<
    MutationUserHistoryBlockCreateOneArgs,
    Mutation['UserHistoryBlockCreateOne']
  >;

  EmailStatsCreateMany: Method<
    MutationEmailStatsCreateManyArgs,
    Mutation['EmailStatsCreateMany']
  >;

  EmailStatsCreateOne: Method<
    MutationEmailStatsCreateOneArgs,
    Mutation['EmailStatsCreateOne']
  >;

  UserCorpCheck: Method<MutationUserCorpCheckArgs, Mutation['UserCorpCheck']>;

  MeuPdvParamsMutation: Method<
    MutationMeuPdvParamsMutationArgs,
    Mutation['MeuPdvParamsMutation']
  >;

  RemunerationCreateOne: Method<
    MutationRemunerationCreateOneArgs,
    Mutation['RemunerationCreateOne']
  >;

  RemunerationUpdateOne: Method<
    MutationRemunerationUpdateOneArgs,
    Mutation['RemunerationUpdateOne']
  >;

  RemunerationRemoveById: Method<
    MutationRemunerationRemoveByIdArgs,
    Mutation['RemunerationRemoveById']
  >;

  RemunerationLikeToggle: Method<
    MutationRemunerationLikeToggleArgs,
    Mutation['RemunerationLikeToggle']
  >;

  UserTourMutation: Method<
    MutationUserTourMutationArgs,
    Mutation['UserTourMutation']
  >;

  ReconheceWalletRemoveById: Method<
    MutationReconheceWalletRemoveByIdArgs,
    Mutation['ReconheceWalletRemoveById']
  >;

  ReconheceWalletCreateOne: Method<
    MutationReconheceWalletCreateOneArgs,
    Mutation['ReconheceWalletCreateOne']
  >;

  ReconheceWalletUpdateById: Method<
    MutationReconheceWalletUpdateByIdArgs,
    Mutation['ReconheceWalletUpdateById']
  >;

  SetWarningAt: Method<MutationSetWarningAtArgs, Mutation['SetWarningAt']>;

  ReconheceCreateTempToken: Method<{}, Mutation['ReconheceCreateTempToken']>;
}

export interface MethodsInfo extends MethodsInfoDict {
  MenuResolvers: {
    type: 'JSON';
    schemaKey: 'MenuResolvers';
    entityName: 'JSON';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['MenuResolvers']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Essa query retorna uma lista de menus';
      deprecationReason: null;
      type: 'JSON';
      args: [];
      name: 'MenuResolvers';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventFindById: {
    type: 'Event';
    schemaKey: 'EventFindById';
    entityName: 'Event';
    isList: false;
    argsTSName: 'QueryEventFindByIdArgs';
    returnTSName: "Query['EventFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Event';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EventFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventFindMany: {
    type: '[Event]';
    schemaKey: 'EventFindMany';
    entityName: 'Event';
    isList: true;
    argsTSName: 'QueryEventFindManyArgs';
    returnTSName: "Query['EventFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Event]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEventInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyEventInput' }
      ];
      name: 'EventFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventPagination: {
    type: 'EventPagination';
    schemaKey: 'EventPagination';
    entityName: 'EventPagination';
    isList: false;
    argsTSName: 'QueryEventPaginationArgs';
    returnTSName: "Query['EventPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEventInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyEventInput' }
      ];
      name: 'EventPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FeedFindById: {
    type: 'Feed';
    schemaKey: 'FeedFindById';
    entityName: 'Feed';
    isList: false;
    argsTSName: 'QueryFeedFindByIdArgs';
    returnTSName: "Query['FeedFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Feed';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'FeedFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FeedFindMany: {
    type: '[Feed]';
    schemaKey: 'FeedFindMany';
    entityName: 'Feed';
    isList: true;
    argsTSName: 'QueryFeedFindManyArgs';
    returnTSName: "Query['FeedFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Feed]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFeedInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyFeedInput' }
      ];
      name: 'FeedFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FeedPagination: {
    type: 'FeedPagination';
    schemaKey: 'FeedPagination';
    entityName: 'FeedPagination';
    isList: false;
    argsTSName: 'QueryFeedPaginationArgs';
    returnTSName: "Query['FeedPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FeedPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFeedInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFeedInput' }
      ];
      name: 'FeedPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FeedUpdateLike: {
    type: 'FeedUpdateLike';
    schemaKey: 'FeedUpdateLike';
    entityName: 'FeedUpdateLike';
    isList: false;
    argsTSName: 'QueryFeedUpdateLikeArgs';
    returnTSName: "Query['FeedUpdateLike']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'FeedUpdateLike';
      args: [{ name: '_id'; description: 'Id da postagem'; type: 'String!' }];
      name: 'FeedUpdateLike';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MeuDiaPdvTableFindOne: {
    type: 'MeuDiaPdvTable';
    schemaKey: 'MeuDiaPdvTableFindOne';
    entityName: 'MeuDiaPdvTable';
    isList: false;
    argsTSName: 'QueryMeuDiaPdvTableFindOneArgs';
    returnTSName: "Query['MeuDiaPdvTableFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MeuDiaPdvTable';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneMeuDiaPdvTableInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneMeuDiaPdvTableInput';
        }
      ];
      name: 'MeuDiaPdvTableFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MeuDiaPdvTableFindById: {
    type: 'MeuDiaPdvTable';
    schemaKey: 'MeuDiaPdvTableFindById';
    entityName: 'MeuDiaPdvTable';
    isList: false;
    argsTSName: 'QueryMeuDiaPdvTableFindByIdArgs';
    returnTSName: "Query['MeuDiaPdvTableFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MeuDiaPdvTable';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MeuDiaPdvTableFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MeuDiaPdvTableFindMany: {
    type: '[MeuDiaPdvTable]';
    schemaKey: 'MeuDiaPdvTableFindMany';
    entityName: 'MeuDiaPdvTable';
    isList: true;
    argsTSName: 'QueryMeuDiaPdvTableFindManyArgs';
    returnTSName: "Query['MeuDiaPdvTableFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[MeuDiaPdvTable]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMeuDiaPdvTableInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyMeuDiaPdvTableInput';
        }
      ];
      name: 'MeuDiaPdvTableFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MeuDiaPdvTableFindByIds: {
    type: '[MeuDiaPdvTable]';
    schemaKey: 'MeuDiaPdvTableFindByIds';
    entityName: 'MeuDiaPdvTable';
    isList: true;
    argsTSName: 'QueryMeuDiaPdvTableFindByIdsArgs';
    returnTSName: "Query['MeuDiaPdvTableFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[MeuDiaPdvTable]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsMeuDiaPdvTableInput';
        }
      ];
      name: 'MeuDiaPdvTableFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  IncentiveCampaignPagination: {
    type: 'IncentiveCampaignPagination';
    schemaKey: 'IncentiveCampaignPagination';
    entityName: 'IncentiveCampaignPagination';
    isList: false;
    argsTSName: 'QueryIncentiveCampaignPaginationArgs';
    returnTSName: "Query['IncentiveCampaignPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'IncentiveCampaignPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyIncentiveCampaignInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyIncentiveCampaignInput';
        }
      ];
      name: 'IncentiveCampaignPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  IncentiveCampaignFindMany: {
    type: '[IncentiveCampaign]';
    schemaKey: 'IncentiveCampaignFindMany';
    entityName: 'IncentiveCampaign';
    isList: true;
    argsTSName: 'QueryIncentiveCampaignFindManyArgs';
    returnTSName: "Query['IncentiveCampaignFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[IncentiveCampaign]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyIncentiveCampaignInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyIncentiveCampaignInput';
        }
      ];
      name: 'IncentiveCampaignFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  IncentiveCampaignFindOne: {
    type: 'IncentiveCampaign';
    schemaKey: 'IncentiveCampaignFindOne';
    entityName: 'IncentiveCampaign';
    isList: false;
    argsTSName: 'QueryIncentiveCampaignFindOneArgs';
    returnTSName: "Query['IncentiveCampaignFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'IncentiveCampaign';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneIncentiveCampaignInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneIncentiveCampaignInput';
        }
      ];
      name: 'IncentiveCampaignFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  IncentiveCampaignFindById: {
    type: 'IncentiveCampaign';
    schemaKey: 'IncentiveCampaignFindById';
    entityName: 'IncentiveCampaign';
    isList: false;
    argsTSName: 'QueryIncentiveCampaignFindByIdArgs';
    returnTSName: "Query['IncentiveCampaignFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'IncentiveCampaign';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'IncentiveCampaignFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignContestationFindOne: {
    type: 'CampaignContestation';
    schemaKey: 'CampaignContestationFindOne';
    entityName: 'CampaignContestation';
    isList: false;
    argsTSName: 'QueryCampaignContestationFindOneArgs';
    returnTSName: "Query['CampaignContestationFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CampaignContestation';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCampaignContestationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneCampaignContestationInput';
        }
      ];
      name: 'CampaignContestationFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignContestationPagination: {
    type: 'CampaignContestationPagination';
    schemaKey: 'CampaignContestationPagination';
    entityName: 'CampaignContestationPagination';
    isList: false;
    argsTSName: 'QueryCampaignContestationPaginationArgs';
    returnTSName: "Query['CampaignContestationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CampaignContestationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCampaignContestationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyCampaignContestationInput';
        }
      ];
      name: 'CampaignContestationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  IncentiveCampaignStatusOptions: {
    type: '[IncentiveCampaignStatusOption]';
    schemaKey: 'IncentiveCampaignStatusOptions';
    entityName: 'IncentiveCampaignStatusOption';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['IncentiveCampaignStatusOptions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[IncentiveCampaignStatusOption]';
      args: [];
      name: 'IncentiveCampaignStatusOptions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceReverseUsersParse: {
    type: '[ReconheceReverseUsersParsePayload]';
    schemaKey: 'ReconheceReverseUsersParse';
    entityName: 'ReconheceReverseUsersParsePayload';
    isList: true;
    argsTSName: 'QueryReconheceReverseUsersParseArgs';
    returnTSName: "Query['ReconheceReverseUsersParse']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ReconheceReverseUsersParsePayload]';
      args: [
        { name: 'paymentFileId'; description: null; type: 'String!' },
        { name: 'campaignId'; description: null; type: 'String!' }
      ];
      name: 'ReconheceReverseUsersParse';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsIncentiveCampaign: {
    type: '[AnalyticsIncentiveCampaign]';
    schemaKey: 'AnalyticsIncentiveCampaign';
    entityName: 'AnalyticsIncentiveCampaign';
    isList: true;
    argsTSName: 'QueryAnalyticsIncentiveCampaignArgs';
    returnTSName: "Query['AnalyticsIncentiveCampaign']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[AnalyticsIncentiveCampaign]';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int'; defaultValue: 20 }
      ];
      name: 'AnalyticsIncentiveCampaign';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegulationPresentation: {
    type: '[RegulationPresentation]';
    schemaKey: 'RegulationPresentation';
    entityName: 'RegulationPresentation';
    isList: true;
    argsTSName: 'QueryRegulationPresentationArgs';
    returnTSName: "Query['RegulationPresentation']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[RegulationPresentation]';
      args: [
        { name: 'startDate'; description: null; type: 'String' },
        { name: 'endDate'; description: null; type: 'String' }
      ];
      name: 'RegulationPresentation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ResearchFindById: {
    type: 'Research';
    schemaKey: 'ResearchFindById';
    entityName: 'Research';
    isList: false;
    argsTSName: 'QueryResearchFindByIdArgs';
    returnTSName: "Query['ResearchFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Research';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ResearchFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ResearchFindMany: {
    type: '[Research]';
    schemaKey: 'ResearchFindMany';
    entityName: 'Research';
    isList: true;
    argsTSName: 'QueryResearchFindManyArgs';
    returnTSName: "Query['ResearchFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Research]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyResearchInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyResearchInput' }
      ];
      name: 'ResearchFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ResearchPagination: {
    type: 'ResearchPagination';
    schemaKey: 'ResearchPagination';
    entityName: 'ResearchPagination';
    isList: false;
    argsTSName: 'QueryResearchPaginationArgs';
    returnTSName: "Query['ResearchPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ResearchPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyResearchInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyResearchInput' }
      ];
      name: 'ResearchPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnswerResearchFindById: {
    type: 'AnswerResearch';
    schemaKey: 'AnswerResearchFindById';
    entityName: 'AnswerResearch';
    isList: false;
    argsTSName: 'QueryAnswerResearchFindByIdArgs';
    returnTSName: "Query['AnswerResearchFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnswerResearch';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnswerResearchFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnswerResearchFindMany: {
    type: '[AnswerResearch]';
    schemaKey: 'AnswerResearchFindMany';
    entityName: 'AnswerResearch';
    isList: true;
    argsTSName: 'QueryAnswerResearchFindManyArgs';
    returnTSName: "Query['AnswerResearchFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnswerResearch]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnswerResearchInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnswerResearchInput';
        }
      ];
      name: 'AnswerResearchFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnswerResearchPagination: {
    type: 'AnswerResearchPagination';
    schemaKey: 'AnswerResearchPagination';
    entityName: 'AnswerResearchPagination';
    isList: false;
    argsTSName: 'QueryAnswerResearchPaginationArgs';
    returnTSName: "Query['AnswerResearchPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnswerResearchPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnswerResearchInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnswerResearchInput';
        }
      ];
      name: 'AnswerResearchPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ResearchAnswersDownload: {
    type: 'BaseDownload';
    schemaKey: 'ResearchAnswersDownload';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryResearchAnswersDownloadArgs';
    returnTSName: "Query['ResearchAnswersDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [{ name: 'researchId'; description: null; type: 'String!' }];
      name: 'ResearchAnswersDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailTemplateFindById: {
    type: 'EmailTemplate';
    schemaKey: 'EmailTemplateFindById';
    entityName: 'EmailTemplate';
    isList: false;
    argsTSName: 'QueryEmailTemplateFindByIdArgs';
    returnTSName: "Query['EmailTemplateFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailTemplate';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EmailTemplateFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailTemplateFindMany: {
    type: '[EmailTemplate]';
    schemaKey: 'EmailTemplateFindMany';
    entityName: 'EmailTemplate';
    isList: true;
    argsTSName: 'QueryEmailTemplateFindManyArgs';
    returnTSName: "Query['EmailTemplateFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[EmailTemplate]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailTemplateInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyEmailTemplateInput';
        }
      ];
      name: 'EmailTemplateFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailTemplatePagination: {
    type: 'EmailTemplatePagination';
    schemaKey: 'EmailTemplatePagination';
    entityName: 'EmailTemplatePagination';
    isList: false;
    argsTSName: 'QueryEmailTemplatePaginationArgs';
    returnTSName: "Query['EmailTemplatePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailTemplatePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailTemplateInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyEmailTemplateInput';
        }
      ];
      name: 'EmailTemplatePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsDocumentsFindById: {
    type: 'AnalyticsDocuments';
    schemaKey: 'AnalyticsDocumentsFindById';
    entityName: 'AnalyticsDocuments';
    isList: false;
    argsTSName: 'QueryAnalyticsDocumentsFindByIdArgs';
    returnTSName: "Query['AnalyticsDocumentsFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsDocuments';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnalyticsDocumentsFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsDocumentsFindMany: {
    type: '[AnalyticsDocuments]';
    schemaKey: 'AnalyticsDocumentsFindMany';
    entityName: 'AnalyticsDocuments';
    isList: true;
    argsTSName: 'QueryAnalyticsDocumentsFindManyArgs';
    returnTSName: "Query['AnalyticsDocumentsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsDocuments]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsDocumentsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnalyticsDocumentsInput';
        }
      ];
      name: 'AnalyticsDocumentsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsDocumentsPagination: {
    type: 'AnalyticsDocumentsPagination';
    schemaKey: 'AnalyticsDocumentsPagination';
    entityName: 'AnalyticsDocumentsPagination';
    isList: false;
    argsTSName: 'QueryAnalyticsDocumentsPaginationArgs';
    returnTSName: "Query['AnalyticsDocumentsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsDocumentsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsDocumentsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnalyticsDocumentsInput';
        }
      ];
      name: 'AnalyticsDocumentsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsCommunicationsFindById: {
    type: 'AnalyticsCommunications';
    schemaKey: 'AnalyticsCommunicationsFindById';
    entityName: 'AnalyticsCommunications';
    isList: false;
    argsTSName: 'QueryAnalyticsCommunicationsFindByIdArgs';
    returnTSName: "Query['AnalyticsCommunicationsFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsCommunications';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnalyticsCommunicationsFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsCommunicationsFindMany: {
    type: '[AnalyticsCommunications]';
    schemaKey: 'AnalyticsCommunicationsFindMany';
    entityName: 'AnalyticsCommunications';
    isList: true;
    argsTSName: 'QueryAnalyticsCommunicationsFindManyArgs';
    returnTSName: "Query['AnalyticsCommunicationsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsCommunications]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsCommunicationsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnalyticsCommunicationsInput';
        }
      ];
      name: 'AnalyticsCommunicationsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsCommunicationsPagination: {
    type: 'AnalyticsCommunicationsPagination';
    schemaKey: 'AnalyticsCommunicationsPagination';
    entityName: 'AnalyticsCommunicationsPagination';
    isList: false;
    argsTSName: 'QueryAnalyticsCommunicationsPaginationArgs';
    returnTSName: "Query['AnalyticsCommunicationsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsCommunicationsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsCommunicationsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyAnalyticsCommunicationsInput';
        }
      ];
      name: 'AnalyticsCommunicationsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionFindMany: {
    type: '[Region]';
    schemaKey: 'RegionFindMany';
    entityName: 'Region';
    isList: true;
    argsTSName: 'QueryRegionFindManyArgs';
    returnTSName: "Query['RegionFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Region]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRegionInput' }
      ];
      name: 'RegionFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionFindOne: {
    type: 'Region';
    schemaKey: 'RegionFindOne';
    entityName: 'Region';
    isList: false;
    argsTSName: 'QueryRegionFindOneArgs';
    returnTSName: "Query['RegionFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Region';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRegionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRegionInput' }
      ];
      name: 'RegionFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionPagination: {
    type: 'RegionPagination';
    schemaKey: 'RegionPagination';
    entityName: 'RegionPagination';
    isList: false;
    argsTSName: 'QueryRegionPaginationArgs';
    returnTSName: "Query['RegionPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRegionInput' }
      ];
      name: 'RegionPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemFindMany: {
    type: '[RegionItem]';
    schemaKey: 'RegionItemFindMany';
    entityName: 'RegionItem';
    isList: true;
    argsTSName: 'QueryRegionItemFindManyArgs';
    returnTSName: "Query['RegionItemFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[RegionItem]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRegionItemInput' }
      ];
      name: 'RegionItemFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemFindOne: {
    type: 'RegionItem';
    schemaKey: 'RegionItemFindOne';
    entityName: 'RegionItem';
    isList: false;
    argsTSName: 'QueryRegionItemFindOneArgs';
    returnTSName: "Query['RegionItemFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionItem';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRegionItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRegionItemInput' }
      ];
      name: 'RegionItemFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemPagination: {
    type: 'RegionItemPagination';
    schemaKey: 'RegionItemPagination';
    entityName: 'RegionItemPagination';
    isList: false;
    argsTSName: 'QueryRegionItemPaginationArgs';
    returnTSName: "Query['RegionItemPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionItemPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionItemInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRegionItemInput' }
      ];
      name: 'RegionItemPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemDistinctDDD: {
    type: 'RegionItemDistinctDDD';
    schemaKey: 'RegionItemDistinctDDD';
    entityName: 'RegionItemDistinctDDD';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['RegionItemDistinctDDD']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos itens de região';
      deprecationReason: null;
      type: 'RegionItemDistinctDDD';
      args: [];
      name: 'RegionItemDistinctDDD';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemDistinctRegionAndUF: {
    type: 'RegionItemDistinctRegionAndUF';
    schemaKey: 'RegionItemDistinctRegionAndUF';
    entityName: 'RegionItemDistinctRegionAndUF';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['RegionItemDistinctRegionAndUF']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro)';
      deprecationReason: null;
      type: 'RegionItemDistinctRegionAndUF';
      args: [];
      name: 'RegionItemDistinctRegionAndUF';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  JobTitleFindOne: {
    type: 'JobTitle';
    schemaKey: 'JobTitleFindOne';
    entityName: 'JobTitle';
    isList: false;
    argsTSName: 'QueryJobTitleFindOneArgs';
    returnTSName: "Query['JobTitleFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JobTitle';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneJobTitleInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneJobTitleInput' }
      ];
      name: 'JobTitleFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  JobTitleFindMany: {
    type: '[JobTitle]';
    schemaKey: 'JobTitleFindMany';
    entityName: 'JobTitle';
    isList: true;
    argsTSName: 'QueryJobTitleFindManyArgs';
    returnTSName: "Query['JobTitleFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[JobTitle]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyJobTitleInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyJobTitleInput' }
      ];
      name: 'JobTitleFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupFindOne: {
    type: 'RoleGroup';
    schemaKey: 'RoleGroupFindOne';
    entityName: 'RoleGroup';
    isList: false;
    argsTSName: 'QueryRoleGroupFindOneArgs';
    returnTSName: "Query['RoleGroupFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RoleGroup';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRoleGroupInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRoleGroupInput' }
      ];
      name: 'RoleGroupFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupFindMany: {
    type: '[RoleGroup]';
    schemaKey: 'RoleGroupFindMany';
    entityName: 'RoleGroup';
    isList: true;
    argsTSName: 'QueryRoleGroupFindManyArgs';
    returnTSName: "Query['RoleGroupFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[RoleGroup]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRoleGroupInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRoleGroupInput' }
      ];
      name: 'RoleGroupFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupPagination: {
    type: 'RoleGroupPagination';
    schemaKey: 'RoleGroupPagination';
    entityName: 'RoleGroupPagination';
    isList: false;
    argsTSName: 'QueryRoleGroupPaginationArgs';
    returnTSName: "Query['RoleGroupPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RoleGroupPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRoleGroupInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRoleGroupInput' }
      ];
      name: 'RoleGroupPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelFindMany: {
    type: '[Channel]';
    schemaKey: 'ChannelFindMany';
    entityName: 'Channel';
    isList: true;
    argsTSName: 'QueryChannelFindManyArgs';
    returnTSName: "Query['ChannelFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Channel]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyChannelInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyChannelInput' }
      ];
      name: 'ChannelFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelFindOne: {
    type: 'Channel';
    schemaKey: 'ChannelFindOne';
    entityName: 'Channel';
    isList: false;
    argsTSName: 'QueryChannelFindOneArgs';
    returnTSName: "Query['ChannelFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Channel';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneChannelInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneChannelInput' }
      ];
      name: 'ChannelFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelPagination: {
    type: 'ChannelPagination';
    schemaKey: 'ChannelPagination';
    entityName: 'ChannelPagination';
    isList: false;
    argsTSName: 'QueryChannelPaginationArgs';
    returnTSName: "Query['ChannelPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ChannelPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyChannelInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyChannelInput' }
      ];
      name: 'ChannelPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserContentPermissionFindOne: {
    type: 'UserContentPermission';
    schemaKey: 'UserContentPermissionFindOne';
    entityName: 'UserContentPermission';
    isList: false;
    argsTSName: 'QueryUserContentPermissionFindOneArgs';
    returnTSName: "Query['UserContentPermissionFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserContentPermission';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserContentPermissionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneUserContentPermissionInput';
        }
      ];
      name: 'UserContentPermissionFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ContentPermissionsHelper: {
    type: '[ContentPermissionsHelperOption]';
    schemaKey: 'ContentPermissionsHelper';
    entityName: 'ContentPermissionsHelperOption';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['ContentPermissionsHelper']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Lista de possiveis content-permissions que os usuários podem ter.';
      deprecationReason: null;
      type: '[ContentPermissionsHelperOption]';
      args: [];
      name: 'ContentPermissionsHelper';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserPagination: {
    type: 'UserPagination';
    schemaKey: 'UserPagination';
    entityName: 'UserPagination';
    isList: false;
    argsTSName: 'QueryUserPaginationArgs';
    returnTSName: "Query['UserPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' }
      ];
      name: 'UserPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByIds: {
    type: '[User]';
    schemaKey: 'UserFindByIds';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindByIdsArgs';
    returnTSName: "Query['UserFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[User]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsUserInput' }
      ];
      name: 'UserFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindById: {
    type: 'User';
    schemaKey: 'UserFindById';
    entityName: 'User';
    isList: false;
    argsTSName: 'QueryUserFindByIdArgs';
    returnTSName: "Query['UserFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindOne: {
    type: 'User';
    schemaKey: 'UserFindOne';
    entityName: 'User';
    isList: false;
    argsTSName: 'QueryUserFindOneArgs';
    returnTSName: "Query['UserFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneUserInput' }
      ];
      name: 'UserFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindMany: {
    type: '[User]';
    schemaKey: 'UserFindMany';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindManyArgs';
    returnTSName: "Query['UserFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[User]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' }
      ];
      name: 'UserFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  User: {
    type: 'User';
    schemaKey: 'User';
    entityName: 'User';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['User']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [];
      name: 'User';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserSearch: {
    type: '[UserSearch]';
    schemaKey: 'UserSearch';
    entityName: 'UserSearch';
    isList: true;
    argsTSName: 'QueryUserSearchArgs';
    returnTSName: "Query['UserSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do usuario';
      deprecationReason: null;
      type: '[UserSearch]';
      args: [
        {
          name: 'search';
          description: 'Nome parcial, email parcial ou ObjectId';
          type: 'String!';
        },
        { name: 'roleGroup'; description: null; type: '[String]' }
      ];
      name: 'UserSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserSearchPagination: {
    type: 'UserPagination';
    schemaKey: 'UserSearchPagination';
    entityName: 'UserPagination';
    isList: false;
    argsTSName: 'QueryUserSearchPaginationArgs';
    returnTSName: "Query['UserSearchPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'UserSearchPaginationFilterInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' }
      ];
      name: 'UserSearchPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TextFindBlackList: {
    type: 'TextFindBlackList';
    schemaKey: 'TextFindBlackList';
    entityName: 'TextFindBlackList';
    isList: false;
    argsTSName: 'QueryTextFindBlackListArgs';
    returnTSName: "Query['TextFindBlackList']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'TextFindBlackList';
      args: [{ name: 'text'; description: null; type: 'String!' }];
      name: 'TextFindBlackList';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByEmails: {
    type: '[UserSimpleResult]';
    schemaKey: 'UserFindByEmails';
    entityName: 'UserSimpleResult';
    isList: true;
    argsTSName: 'QueryUserFindByEmailsArgs';
    returnTSName: "Query['UserFindByEmails']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do usuario';
      deprecationReason: null;
      type: '[UserSimpleResult]';
      args: [
        {
          name: 'emails';
          description: 'Lista de emails de usuários';
          type: '[String]!';
        }
      ];
      name: 'UserFindByEmails';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByMatricula: {
    type: '[User]';
    schemaKey: 'UserFindByMatricula';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindByMatriculaArgs';
    returnTSName: "Query['UserFindByMatricula']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[User]';
      args: [
        { name: 'isMatriculaNull'; description: null; type: 'Boolean' },
        { name: 'email'; description: null; type: 'String' },
        { name: 'name'; description: null; type: 'String' },
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'UserFindByMatricula';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FileFindByIds: {
    type: '[File]';
    schemaKey: 'FileFindByIds';
    entityName: 'File';
    isList: true;
    argsTSName: 'QueryFileFindByIdsArgs';
    returnTSName: "Query['FileFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[File]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsFileInput' }
      ];
      name: 'FileFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FileFindById: {
    type: 'File';
    schemaKey: 'FileFindById';
    entityName: 'File';
    isList: false;
    argsTSName: 'QueryFileFindByIdArgs';
    returnTSName: "Query['FileFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'File';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FileFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FilePagination: {
    type: 'FilePagination';
    schemaKey: 'FilePagination';
    entityName: 'FilePagination';
    isList: false;
    argsTSName: 'QueryFilePaginationArgs';
    returnTSName: "Query['FilePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FilePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFileInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFileInput' }
      ];
      name: 'FilePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindById: {
    type: 'Material';
    schemaKey: 'MaterialFindById';
    entityName: 'Material';
    isList: false;
    argsTSName: 'QueryMaterialFindByIdArgs';
    returnTSName: "Query['MaterialFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Material';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindMany: {
    type: '[Material]';
    schemaKey: 'MaterialFindMany';
    entityName: 'Material';
    isList: true;
    argsTSName: 'QueryMaterialFindManyArgs';
    returnTSName: "Query['MaterialFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Material]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindOne: {
    type: 'Material';
    schemaKey: 'MaterialFindOne';
    entityName: 'Material';
    isList: false;
    argsTSName: 'QueryMaterialFindOneArgs';
    returnTSName: "Query['MaterialFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Material';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneMaterialInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneMaterialInput' }
      ];
      name: 'MaterialFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialPagination: {
    type: 'MaterialPagination';
    schemaKey: 'MaterialPagination';
    entityName: 'MaterialPagination';
    isList: false;
    argsTSName: 'QueryMaterialPaginationArgs';
    returnTSName: "Query['MaterialPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MaterialPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialCount: {
    type: 'Int';
    schemaKey: 'MaterialCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryMaterialCountArgs';
    returnTSName: "Query['MaterialCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterMaterialInput';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialCount';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialStatusOptions: {
    type: '[MaterialStatusOption]';
    schemaKey: 'MaterialStatusOptions';
    entityName: 'MaterialStatusOption';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['MaterialStatusOptions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[MaterialStatusOption]';
      args: [];
      name: 'MaterialStatusOptions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialSearchOwners: {
    type: '[MaterialSearchOwners]';
    schemaKey: 'MaterialSearchOwners';
    entityName: 'MaterialSearchOwners';
    isList: true;
    argsTSName: 'QueryMaterialSearchOwnersArgs';
    returnTSName: "Query['MaterialSearchOwners']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[MaterialSearchOwners]';
      args: [{ name: 'kind'; description: null; type: 'EnumMaterialKind' }];
      name: 'MaterialSearchOwners';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailFindMany: {
    type: '[Email]';
    schemaKey: 'EmailFindMany';
    entityName: 'Email';
    isList: true;
    argsTSName: 'QueryEmailFindManyArgs';
    returnTSName: "Query['EmailFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Email]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyEmailInput' }
      ];
      name: 'EmailFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailPagination: {
    type: 'EmailPagination';
    schemaKey: 'EmailPagination';
    entityName: 'EmailPagination';
    isList: false;
    argsTSName: 'QueryEmailPaginationArgs';
    returnTSName: "Query['EmailPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyEmailInput' }
      ];
      name: 'EmailPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailFindOne: {
    type: 'Email';
    schemaKey: 'EmailFindOne';
    entityName: 'Email';
    isList: false;
    argsTSName: 'QueryEmailFindOneArgs';
    returnTSName: "Query['EmailFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Email';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEmailInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEmailInput' }
      ];
      name: 'EmailFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailCheckRead: {
    type: 'Boolean';
    schemaKey: 'EmailCheckRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'QueryEmailCheckReadArgs';
    returnTSName: "Query['EmailCheckRead']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Retorna true se o email foi marcado como lido pelo destinatario informado';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'emailId'; description: null; type: 'String!' },
        { name: 'userEmail'; description: null; type: 'String!' }
      ];
      name: 'EmailCheckRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialSearch: {
    type: 'MaterialSearch';
    schemaKey: 'MaterialSearch';
    entityName: 'MaterialSearch';
    isList: false;
    argsTSName: 'QueryMaterialSearchArgs';
    returnTSName: "Query['MaterialSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'MaterialSearch';
      args: [
        { name: 'search'; description: null; type: 'String!' },
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int'; defaultValue: 20 },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' },
        { name: 'category'; description: null; type: 'String' },
        { name: 'kind'; description: null; type: 'EnumMaterialKind' }
      ];
      name: 'MaterialSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindPinned: {
    type: 'MaterialPagination';
    schemaKey: 'MaterialFindPinned';
    entityName: 'MaterialPagination';
    isList: false;
    argsTSName: 'QueryMaterialFindPinnedArgs';
    returnTSName: "Query['MaterialFindPinned']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MaterialPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        {
          name: 'filter';
          description: null;
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialFindPinned';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationFindMany: {
    type: '[Notification]';
    schemaKey: 'NotificationFindMany';
    entityName: 'Notification';
    isList: true;
    argsTSName: 'QueryNotificationFindManyArgs';
    returnTSName: "Query['NotificationFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Notification]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyNotificationInput';
        }
      ];
      name: 'NotificationFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationFindOne: {
    type: 'Notification';
    schemaKey: 'NotificationFindOne';
    entityName: 'Notification';
    isList: false;
    argsTSName: 'QueryNotificationFindOneArgs';
    returnTSName: "Query['NotificationFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Notification';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneNotificationInput';
        }
      ];
      name: 'NotificationFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationPagination: {
    type: 'NotificationPagination';
    schemaKey: 'NotificationPagination';
    entityName: 'NotificationPagination';
    isList: false;
    argsTSName: 'QueryNotificationPaginationArgs';
    returnTSName: "Query['NotificationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'NotificationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyNotificationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyNotificationInput';
        }
      ];
      name: 'NotificationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  HelperAddress: {
    type: 'AddressHelperPayload';
    schemaKey: 'HelperAddress';
    entityName: 'AddressHelperPayload';
    isList: false;
    argsTSName: 'QueryHelperAddressArgs';
    returnTSName: "Query['HelperAddress']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'AddressHelperPayload';
      args: [{ name: 'zipCode'; description: null; type: 'String!' }];
      name: 'HelperAddress';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindOne: {
    type: 'Category';
    schemaKey: 'CategoryFindOne';
    entityName: 'Category';
    isList: false;
    argsTSName: 'QueryCategoryFindOneArgs';
    returnTSName: "Query['CategoryFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Category';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCategoryInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneCategoryInput' }
      ];
      name: 'CategoryFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindById: {
    type: 'Category';
    schemaKey: 'CategoryFindById';
    entityName: 'Category';
    isList: false;
    argsTSName: 'QueryCategoryFindByIdArgs';
    returnTSName: "Query['CategoryFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Category';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortMenuEnum' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindMany: {
    type: '[Category]';
    schemaKey: 'CategoryFindMany';
    entityName: 'Category';
    isList: true;
    argsTSName: 'QueryCategoryFindManyArgs';
    returnTSName: "Query['CategoryFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Category]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCategoryInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyCategoryInput' }
      ];
      name: 'CategoryFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindByIds: {
    type: '[Category]';
    schemaKey: 'CategoryFindByIds';
    entityName: 'Category';
    isList: true;
    argsTSName: 'QueryCategoryFindByIdsArgs';
    returnTSName: "Query['CategoryFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Category]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsCategoryInput' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryPagination: {
    type: 'CategoryPagination';
    schemaKey: 'CategoryPagination';
    entityName: 'CategoryPagination';
    isList: false;
    argsTSName: 'QueryCategoryPaginationArgs';
    returnTSName: "Query['CategoryPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CategoryPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCategoryInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCategoryInput' }
      ];
      name: 'CategoryPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  WebPushPublicKey: {
    type: 'String!';
    schemaKey: 'WebPushPublicKey';
    entityName: 'String';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['WebPushPublicKey']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Retorna a chave publica (VAPID key) pra a subscription de web push notifications';
      deprecationReason: null;
      type: 'String!';
      args: [];
      name: 'WebPushPublicKey';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'query';
  };

  PinFindMany: {
    type: '[Pin]';
    schemaKey: 'PinFindMany';
    entityName: 'Pin';
    isList: true;
    argsTSName: 'QueryPinFindManyArgs';
    returnTSName: "Query['PinFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Pin]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPinInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyPinInput' }
      ];
      name: 'PinFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PinPagination: {
    type: 'PinPagination';
    schemaKey: 'PinPagination';
    entityName: 'PinPagination';
    isList: false;
    argsTSName: 'QueryPinPaginationArgs';
    returnTSName: "Query['PinPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PinPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPinInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyPinInput' }
      ];
      name: 'PinPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ShareFindMany: {
    type: '[Share]';
    schemaKey: 'ShareFindMany';
    entityName: 'Share';
    isList: true;
    argsTSName: 'QueryShareFindManyArgs';
    returnTSName: "Query['ShareFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Share]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyShareInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyShareInput' }
      ];
      name: 'ShareFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SharePagination: {
    type: 'SharePagination';
    schemaKey: 'SharePagination';
    entityName: 'SharePagination';
    isList: false;
    argsTSName: 'QuerySharePaginationArgs';
    returnTSName: "Query['SharePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SharePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyShareInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyShareInput' }
      ];
      name: 'SharePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentPagination: {
    type: 'CommentPagination';
    schemaKey: 'CommentPagination';
    entityName: 'CommentPagination';
    isList: false;
    argsTSName: 'QueryCommentPaginationArgs';
    returnTSName: "Query['CommentPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CommentPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCommentInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindOne: {
    type: 'Comment';
    schemaKey: 'CommentFindOne';
    entityName: 'Comment';
    isList: false;
    argsTSName: 'QueryCommentFindOneArgs';
    returnTSName: "Query['CommentFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Comment';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCommentInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneCommentInput' }
      ];
      name: 'CommentFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindById: {
    type: 'Comment';
    schemaKey: 'CommentFindById';
    entityName: 'Comment';
    isList: false;
    argsTSName: 'QueryCommentFindByIdArgs';
    returnTSName: "Query['CommentFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Comment';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'CommentFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindMany: {
    type: '[Comment]';
    schemaKey: 'CommentFindMany';
    entityName: 'Comment';
    isList: true;
    argsTSName: 'QueryCommentFindManyArgs';
    returnTSName: "Query['CommentFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Comment]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCommentInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentSortPagination: {
    type: 'CommentPagination';
    schemaKey: 'CommentSortPagination';
    entityName: 'CommentPagination';
    isList: false;
    argsTSName: 'QueryCommentSortPaginationArgs';
    returnTSName: "Query['CommentSortPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CommentPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        {
          name: 'filter';
          description: null;
          type: 'CommentSortPaginationFilterInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentSortPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLivePagination: {
    type: 'EventLivePagination';
    schemaKey: 'EventLivePagination';
    entityName: 'EventLivePagination';
    isList: false;
    argsTSName: 'QueryEventLivePaginationArgs';
    returnTSName: "Query['EventLivePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLivePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEventLiveInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyEventLiveInput' }
      ];
      name: 'EventLivePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLiveFindOne: {
    type: 'EventLive';
    schemaKey: 'EventLiveFindOne';
    entityName: 'EventLive';
    isList: false;
    argsTSName: 'QueryEventLiveFindOneArgs';
    returnTSName: "Query['EventLiveFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLive';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEventLiveInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEventLiveInput' }
      ];
      name: 'EventLiveFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLiveFindById: {
    type: 'EventLive';
    schemaKey: 'EventLiveFindById';
    entityName: 'EventLive';
    isList: false;
    argsTSName: 'QueryEventLiveFindByIdArgs';
    returnTSName: "Query['EventLiveFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLive';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BannerFindMany: {
    type: '[Banner]';
    schemaKey: 'BannerFindMany';
    entityName: 'Banner';
    isList: true;
    argsTSName: 'QueryBannerFindManyArgs';
    returnTSName: "Query['BannerFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Banner]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyBannerInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyBannerInput' }
      ];
      name: 'BannerFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BannerFindOne: {
    type: 'Banner';
    schemaKey: 'BannerFindOne';
    entityName: 'Banner';
    isList: false;
    argsTSName: 'QueryBannerFindOneArgs';
    returnTSName: "Query['BannerFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Banner';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneBannerInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneBannerInput' }
      ];
      name: 'BannerFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsCount: {
    type: 'Int';
    schemaKey: 'AnalyticsCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryAnalyticsCountArgs';
    returnTSName: "Query['AnalyticsCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterAnalyticsInput';
        }
      ];
      name: 'AnalyticsCount';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPagination: {
    type: 'AnalyticsPagination';
    schemaKey: 'AnalyticsPagination';
    entityName: 'AnalyticsPagination';
    isList: false;
    argsTSName: 'QueryAnalyticsPaginationArgs';
    returnTSName: "Query['AnalyticsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyAnalyticsInput' }
      ];
      name: 'AnalyticsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsUsersReport: {
    type: '[AnalyticsUser]';
    schemaKey: 'AnalyticsUsersReport';
    entityName: 'AnalyticsUser';
    isList: true;
    argsTSName: 'QueryAnalyticsUsersReportArgs';
    returnTSName: "Query['AnalyticsUsersReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[AnalyticsUser]';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int'; defaultValue: 20 }
      ];
      name: 'AnalyticsUsersReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsFindReport: {
    type: '[AnalyticsReportItem]';
    schemaKey: 'AnalyticsFindReport';
    entityName: 'AnalyticsReportItem';
    isList: true;
    argsTSName: 'QueryAnalyticsFindReportArgs';
    returnTSName: "Query['AnalyticsFindReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsReportItem]';
      args: [
        {
          name: 'filter';
          description: null;
          type: 'AnalyticsReportFilterInput';
        },
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsFindReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsUserNotification: {
    type: '[AnalyticsUserNotification]';
    schemaKey: 'AnalyticsUserNotification';
    entityName: 'AnalyticsUserNotification';
    isList: true;
    argsTSName: 'QueryAnalyticsUserNotificationArgs';
    returnTSName: "Query['AnalyticsUserNotification']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsUserNotification]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsUserNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPin: {
    type: '[AnalyticsPin]';
    schemaKey: 'AnalyticsPin';
    entityName: 'AnalyticsPin';
    isList: true;
    argsTSName: 'QueryAnalyticsPinArgs';
    returnTSName: "Query['AnalyticsPin']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsPin]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsPin';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsComment: {
    type: '[AnalyticsComment]';
    schemaKey: 'AnalyticsComment';
    entityName: 'AnalyticsComment';
    isList: true;
    argsTSName: 'QueryAnalyticsCommentArgs';
    returnTSName: "Query['AnalyticsComment']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsComment]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsComment';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPoints: {
    type: '[AnalyticsPoints]';
    schemaKey: 'AnalyticsPoints';
    entityName: 'AnalyticsPoints';
    isList: true;
    argsTSName: 'QueryAnalyticsPointsArgs';
    returnTSName: "Query['AnalyticsPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsPoints]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsDownloads: {
    type: 'Boolean';
    schemaKey: 'AnalyticsDownloads';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'QueryAnalyticsDownloadsArgs';
    returnTSName: "Query['AnalyticsDownloads']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'fileId'; description: null; type: 'String' }];
      name: 'AnalyticsDownloads';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SearchCountFindMany: {
    type: '[SearchCount]';
    schemaKey: 'SearchCountFindMany';
    entityName: 'SearchCount';
    isList: true;
    argsTSName: 'QuerySearchCountFindManyArgs';
    returnTSName: "Query['SearchCountFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[SearchCount]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySearchCountInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySearchCountInput';
        }
      ];
      name: 'SearchCountFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SearchCountPagination: {
    type: 'SearchCountPagination';
    schemaKey: 'SearchCountPagination';
    entityName: 'SearchCountPagination';
    isList: false;
    argsTSName: 'QuerySearchCountPaginationArgs';
    returnTSName: "Query['SearchCountPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SearchCountPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySearchCountInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySearchCountInput';
        }
      ];
      name: 'SearchCountPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderFindMany: {
    type: '[Folder]';
    schemaKey: 'FolderFindMany';
    entityName: 'Folder';
    isList: true;
    argsTSName: 'QueryFolderFindManyArgs';
    returnTSName: "Query['FolderFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Folder]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFolderInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyFolderInput' }
      ];
      name: 'FolderFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderFindById: {
    type: 'Folder';
    schemaKey: 'FolderFindById';
    entityName: 'Folder';
    isList: false;
    argsTSName: 'QueryFolderFindByIdArgs';
    returnTSName: "Query['FolderFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Folder';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FolderFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderPagination: {
    type: 'FolderPagination';
    schemaKey: 'FolderPagination';
    entityName: 'FolderPagination';
    isList: false;
    argsTSName: 'QueryFolderPaginationArgs';
    returnTSName: "Query['FolderPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FolderPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFolderInput' }
      ];
      name: 'FolderPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RecentsActivitiesPagination: {
    type: 'RecentsActivitiesPagination';
    schemaKey: 'RecentsActivitiesPagination';
    entityName: 'RecentsActivitiesPagination';
    isList: false;
    argsTSName: 'QueryRecentsActivitiesPaginationArgs';
    returnTSName: "Query['RecentsActivitiesPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RecentsActivitiesPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRecentsActivitiesInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyRecentsActivitiesInput';
        }
      ];
      name: 'RecentsActivitiesPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserWordsBlackListFindMany: {
    type: '[UserWordsBlackList]';
    schemaKey: 'UserWordsBlackListFindMany';
    entityName: 'UserWordsBlackList';
    isList: true;
    argsTSName: 'QueryUserWordsBlackListFindManyArgs';
    returnTSName: "Query['UserWordsBlackListFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserWordsBlackList]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserWordsBlackListInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserWordsBlackListInput';
        }
      ];
      name: 'UserWordsBlackListFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserWordsBlackListFindById: {
    type: 'UserWordsBlackList';
    schemaKey: 'UserWordsBlackListFindById';
    entityName: 'UserWordsBlackList';
    isList: false;
    argsTSName: 'QueryUserWordsBlackListFindByIdArgs';
    returnTSName: "Query['UserWordsBlackListFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserWordsBlackList';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserWordsBlackListFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadSensitiveFilesPagination: {
    type: 'DownloadSensitiveFilesPagination';
    schemaKey: 'DownloadSensitiveFilesPagination';
    entityName: 'DownloadSensitiveFilesPagination';
    isList: false;
    argsTSName: 'QueryDownloadSensitiveFilesPaginationArgs';
    returnTSName: "Query['DownloadSensitiveFilesPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DownloadSensitiveFilesPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDownloadSensitiveFilesInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDownloadSensitiveFilesInput';
        }
      ];
      name: 'DownloadSensitiveFilesPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersInfo: {
    type: 'DownloadUsersInfo';
    schemaKey: 'DownloadUsersInfo';
    entityName: 'DownloadUsersInfo';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersInfo']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersInfo';
      args: [];
      name: 'DownloadUsersInfo';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadAcceptedTerms: {
    type: 'DownloadAcceptedTerms';
    schemaKey: 'DownloadAcceptedTerms';
    entityName: 'DownloadAcceptedTerms';
    isList: false;
    argsTSName: 'QueryDownloadAcceptedTermsArgs';
    returnTSName: "Query['DownloadAcceptedTerms']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadAcceptedTerms';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String!' }
      ];
      name: 'DownloadAcceptedTerms';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersAcceptedPoints: {
    type: 'DownloadUsersAcceptedPoints';
    schemaKey: 'DownloadUsersAcceptedPoints';
    entityName: 'DownloadUsersAcceptedPoints';
    isList: false;
    argsTSName: 'QueryDownloadUsersAcceptedPointsArgs';
    returnTSName: "Query['DownloadUsersAcceptedPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersAcceptedPoints';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String!' }
      ];
      name: 'DownloadUsersAcceptedPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersWithPoints: {
    type: 'DownloadUsersWithPoints';
    schemaKey: 'DownloadUsersWithPoints';
    entityName: 'DownloadUsersWithPoints';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersWithPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersWithPoints';
      args: [];
      name: 'DownloadUsersWithPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersBase: {
    type: 'DownloadUsersInfo';
    schemaKey: 'DownloadUsersBase';
    entityName: 'DownloadUsersInfo';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersBase']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersInfo';
      args: [];
      name: 'DownloadUsersBase';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadExtraCredit: {
    type: 'DownloadExtraCreditUrl';
    schemaKey: 'DownloadExtraCredit';
    entityName: 'DownloadExtraCreditUrl';
    isList: false;
    argsTSName: 'QueryDownloadExtraCreditArgs';
    returnTSName: "Query['DownloadExtraCredit']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadExtraCreditUrl';
      args: [
        { name: 'campaignId'; description: null; type: 'String!' },
        { name: 'transactionId'; description: null; type: 'String' }
      ];
      name: 'DownloadExtraCredit';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadReversalPoints: {
    type: 'DownloadReversalUrl';
    schemaKey: 'DownloadReversalPoints';
    entityName: 'DownloadReversalUrl';
    isList: false;
    argsTSName: 'QueryDownloadReversalPointsArgs';
    returnTSName: "Query['DownloadReversalPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadReversalUrl';
      args: [
        { name: 'campaignId'; description: null; type: 'String!' },
        { name: 'transactionId'; description: null; type: 'String' }
      ];
      name: 'DownloadReversalPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadExpirePoints: {
    type: 'DownloadExpirePointsUrl';
    schemaKey: 'DownloadExpirePoints';
    entityName: 'DownloadExpirePointsUrl';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadExpirePoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadExpirePointsUrl';
      args: [];
      name: 'DownloadExpirePoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadDebitExpirePoints: {
    type: 'DownloadDebitExpirePointsUrl';
    schemaKey: 'DownloadDebitExpirePoints';
    entityName: 'DownloadDebitExpirePointsUrl';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadDebitExpirePoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadDebitExpirePointsUrl';
      args: [];
      name: 'DownloadDebitExpirePoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindMany: {
    type: '[ImportUsersPlan]';
    schemaKey: 'ImportUsersPlanFindMany';
    entityName: 'ImportUsersPlan';
    isList: true;
    argsTSName: 'QueryImportUsersPlanFindManyArgs';
    returnTSName: "Query['ImportUsersPlanFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ImportUsersPlan]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyImportUsersPlanInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyImportUsersPlanInput';
        }
      ];
      name: 'ImportUsersPlanFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindById: {
    type: 'ImportUsersPlan';
    schemaKey: 'ImportUsersPlanFindById';
    entityName: 'ImportUsersPlan';
    isList: false;
    argsTSName: 'QueryImportUsersPlanFindByIdArgs';
    returnTSName: "Query['ImportUsersPlanFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ImportUsersPlan';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindByIds: {
    type: '[ImportUsersPlan]';
    schemaKey: 'ImportUsersPlanFindByIds';
    entityName: 'ImportUsersPlan';
    isList: true;
    argsTSName: 'QueryImportUsersPlanFindByIdsArgs';
    returnTSName: "Query['ImportUsersPlanFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ImportUsersPlan]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsImportUsersPlanInput';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanPagination: {
    type: 'ImportUsersPlanPagination';
    schemaKey: 'ImportUsersPlanPagination';
    entityName: 'ImportUsersPlanPagination';
    isList: false;
    argsTSName: 'QueryImportUsersPlanPaginationArgs';
    returnTSName: "Query['ImportUsersPlanPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ImportUsersPlanPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyImportUsersPlanInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyImportUsersPlanInput';
        }
      ];
      name: 'ImportUsersPlanPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindByIds: {
    type: '[UserDeleted]';
    schemaKey: 'UserDeletedFindByIds';
    entityName: 'UserDeleted';
    isList: true;
    argsTSName: 'QueryUserDeletedFindByIdsArgs';
    returnTSName: "Query['UserDeletedFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserDeleted]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsUserDeletedInput';
        }
      ];
      name: 'UserDeletedFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindOne: {
    type: 'UserDeleted';
    schemaKey: 'UserDeletedFindOne';
    entityName: 'UserDeleted';
    isList: false;
    argsTSName: 'QueryUserDeletedFindOneArgs';
    returnTSName: "Query['UserDeletedFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserDeleted';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserDeletedInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneUserDeletedInput' }
      ];
      name: 'UserDeletedFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindMany: {
    type: '[UserDeleted]';
    schemaKey: 'UserDeletedFindMany';
    entityName: 'UserDeleted';
    isList: true;
    argsTSName: 'QueryUserDeletedFindManyArgs';
    returnTSName: "Query['UserDeletedFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserDeleted]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserDeletedInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserDeletedInput';
        }
      ];
      name: 'UserDeletedFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserHistoryBlockFindMany: {
    type: '[UserHistoryBlock]';
    schemaKey: 'UserHistoryBlockFindMany';
    entityName: 'UserHistoryBlock';
    isList: true;
    argsTSName: 'QueryUserHistoryBlockFindManyArgs';
    returnTSName: "Query['UserHistoryBlockFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserHistoryBlock]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserHistoryBlockInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserHistoryBlockInput';
        }
      ];
      name: 'UserHistoryBlockFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserHistoryBlockPagination: {
    type: 'UserHistoryBlockPagination';
    schemaKey: 'UserHistoryBlockPagination';
    entityName: 'UserHistoryBlockPagination';
    isList: false;
    argsTSName: 'QueryUserHistoryBlockPaginationArgs';
    returnTSName: "Query['UserHistoryBlockPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserHistoryBlockPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserHistoryBlockInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserHistoryBlockInput';
        }
      ];
      name: 'UserHistoryBlockPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FutureOperationFindById: {
    type: 'FutureOperation';
    schemaKey: 'FutureOperationFindById';
    entityName: 'FutureOperation';
    isList: false;
    argsTSName: 'QueryFutureOperationFindByIdArgs';
    returnTSName: "Query['FutureOperationFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FutureOperation';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FutureOperationFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailStatsFindOne: {
    type: 'EmailStats';
    schemaKey: 'EmailStatsFindOne';
    entityName: 'EmailStats';
    isList: false;
    argsTSName: 'QueryEmailStatsFindOneArgs';
    returnTSName: "Query['EmailStatsFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailStats';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEmailStatsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEmailStatsInput' }
      ];
      name: 'EmailStatsFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailStatsFindMany: {
    type: '[EmailStats]';
    schemaKey: 'EmailStatsFindMany';
    entityName: 'EmailStats';
    isList: true;
    argsTSName: 'QueryEmailStatsFindManyArgs';
    returnTSName: "Query['EmailStatsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[EmailStats]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailStatsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyEmailStatsInput' }
      ];
      name: 'EmailStatsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MeuDiaPdvTableAll: {
    type: 'MeuPdvReturn';
    schemaKey: 'MeuDiaPdvTableAll';
    entityName: 'MeuPdvReturn';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['MeuDiaPdvTableAll']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MeuPdvReturn';
      args: [];
      name: 'MeuDiaPdvTableAll';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UpdatedUsers: {
    type: 'UpdatedUsersPayload';
    schemaKey: 'UpdatedUsers';
    entityName: 'UpdatedUsersPayload';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['UpdatedUsers']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Esta query retorna uma lista com os usuários cadastrados no portal o total e a timestamp da ultima atualização do cache';
      deprecationReason: null;
      type: 'UpdatedUsersPayload';
      args: [];
      name: 'UpdatedUsers';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SelectRegionalProfiles: {
    type: '[RegionalProfiles]';
    schemaKey: 'SelectRegionalProfiles';
    entityName: 'RegionalProfiles';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['SelectRegionalProfiles']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[RegionalProfiles]';
      args: [];
      name: 'SelectRegionalProfiles';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailAnalytics: {
    type: '[ResultEmailAnalytics]';
    schemaKey: 'EmailAnalytics';
    entityName: 'ResultEmailAnalytics';
    isList: true;
    argsTSName: 'QueryEmailAnalyticsArgs';
    returnTSName: "Query['EmailAnalytics']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ResultEmailAnalytics]';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' }
      ];
      name: 'EmailAnalytics';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailStatsAnalytics: {
    type: '[EmailStatsAnalyticsPayload]';
    schemaKey: 'EmailStatsAnalytics';
    entityName: 'EmailStatsAnalyticsPayload';
    isList: true;
    argsTSName: 'QueryEmailStatsAnalyticsArgs';
    returnTSName: "Query['EmailStatsAnalytics']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Status de envio dos e-mails';
      deprecationReason: null;
      type: '[EmailStatsAnalyticsPayload]';
      args: [
        { name: 'day'; description: null; type: 'String!' },
        { name: 'month'; description: null; type: 'String!' },
        { name: 'year'; description: null; type: 'String!' }
      ];
      name: 'EmailStatsAnalytics';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReportMenuView: {
    type: '[ReportMenuView]';
    schemaKey: 'ReportMenuView';
    entityName: 'ReportMenuView';
    isList: true;
    argsTSName: 'QueryReportMenuViewArgs';
    returnTSName: "Query['ReportMenuView']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[ReportMenuView]';
      args: [
        { name: 'date'; description: null; type: 'String!' },
        { name: 'menu'; description: null; type: 'String!' },
        { name: 'kind'; description: null; type: 'String!' }
      ];
      name: 'ReportMenuView';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RemunerationPagination: {
    type: 'RemunerationPagination';
    schemaKey: 'RemunerationPagination';
    entityName: 'RemunerationPagination';
    isList: false;
    argsTSName: 'QueryRemunerationPaginationArgs';
    returnTSName: "Query['RemunerationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RemunerationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRemunerationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyRemunerationInput';
        }
      ];
      name: 'RemunerationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RemunerationFindMany: {
    type: '[Remuneration]';
    schemaKey: 'RemunerationFindMany';
    entityName: 'Remuneration';
    isList: true;
    argsTSName: 'QueryRemunerationFindManyArgs';
    returnTSName: "Query['RemunerationFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Remuneration]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRemunerationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyRemunerationInput';
        }
      ];
      name: 'RemunerationFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RemunerationFindOne: {
    type: 'Remuneration';
    schemaKey: 'RemunerationFindOne';
    entityName: 'Remuneration';
    isList: false;
    argsTSName: 'QueryRemunerationFindOneArgs';
    returnTSName: "Query['RemunerationFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Remuneration';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRemunerationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneRemunerationInput';
        }
      ];
      name: 'RemunerationFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RemunerationFindById: {
    type: 'Remuneration';
    schemaKey: 'RemunerationFindById';
    entityName: 'Remuneration';
    isList: false;
    argsTSName: 'QueryRemunerationFindByIdArgs';
    returnTSName: "Query['RemunerationFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Remuneration';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'RemunerationFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RemunerationCount: {
    type: 'Int';
    schemaKey: 'RemunerationCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryRemunerationCountArgs';
    returnTSName: "Query['RemunerationCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemunerationInput';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'RemunerationCount';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReportRemuneration: {
    type: '[ReportRemuneration]';
    schemaKey: 'ReportRemuneration';
    entityName: 'ReportRemuneration';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['ReportRemuneration']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[ReportRemuneration]';
      args: [];
      name: 'ReportRemuneration';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsMaterialView: {
    type: 'Material';
    schemaKey: 'AnalyticsMaterialView';
    entityName: 'Material';
    isList: false;
    argsTSName: 'QueryAnalyticsMaterialViewArgs';
    returnTSName: "Query['AnalyticsMaterialView']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Visualização de material por _id e registro de visualização no analytics';
      deprecationReason: null;
      type: 'Material';
      args: [{ name: '_id'; description: null; type: 'String!' }];
      name: 'AnalyticsMaterialView';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReportTourUser: {
    type: '[ReportTourUser]';
    schemaKey: 'ReportTourUser';
    entityName: 'ReportTourUser';
    isList: true;
    argsTSName: 'QueryReportTourUserArgs';
    returnTSName: "Query['ReportTourUser']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[ReportTourUser]';
      args: [{ name: 'date'; description: null; type: 'String!' }];
      name: 'ReportTourUser';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsMaterialDownload: {
    type: 'Boolean';
    schemaKey: 'AnalyticsMaterialDownload';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'QueryAnalyticsMaterialDownloadArgs';
    returnTSName: "Query['AnalyticsMaterialDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'fileId'; description: null; type: 'String' }];
      name: 'AnalyticsMaterialDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReportDocumentsActions: {
    type: '[ReportDocumentsActions]';
    schemaKey: 'ReportDocumentsActions';
    entityName: 'ReportDocumentsActions';
    isList: true;
    argsTSName: 'QueryReportDocumentsActionsArgs';
    returnTSName: "Query['ReportDocumentsActions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[ReportDocumentsActions]';
      args: [
        { name: 'date'; description: null; type: 'String!' },
        { name: 'menu'; description: null; type: 'String!' }
      ];
      name: 'ReportDocumentsActions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceWalletPagination: {
    type: 'ReconheceWalletPagination';
    schemaKey: 'ReconheceWalletPagination';
    entityName: 'ReconheceWalletPagination';
    isList: false;
    argsTSName: 'QueryReconheceWalletPaginationArgs';
    returnTSName: "Query['ReconheceWalletPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ReconheceWalletPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyReconheceWalletInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyReconheceWalletInput';
        }
      ];
      name: 'ReconheceWalletPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceWalletFindMany: {
    type: '[ReconheceWallet]';
    schemaKey: 'ReconheceWalletFindMany';
    entityName: 'ReconheceWallet';
    isList: true;
    argsTSName: 'QueryReconheceWalletFindManyArgs';
    returnTSName: "Query['ReconheceWalletFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ReconheceWallet]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyReconheceWalletInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyReconheceWalletInput';
        }
      ];
      name: 'ReconheceWalletFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceWalletFindById: {
    type: 'ReconheceWallet';
    schemaKey: 'ReconheceWalletFindById';
    entityName: 'ReconheceWallet';
    isList: false;
    argsTSName: 'QueryReconheceWalletFindByIdArgs';
    returnTSName: "Query['ReconheceWalletFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ReconheceWallet';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ReconheceWalletFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceWalletSum: {
    type: 'ReconheceWalletSum';
    schemaKey: 'ReconheceWalletSum';
    entityName: 'ReconheceWalletSum';
    isList: false;
    argsTSName: 'QueryReconheceWalletSumArgs';
    returnTSName: "Query['ReconheceWalletSum']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ReconheceWalletSum';
      args: [
        { name: 'invoiceOrigin'; description: null; type: 'String' },
        {
          name: 'createdAtRange';
          description: null;
          type: 'ReconheceWalletCreatedAtRange';
        }
      ];
      name: 'ReconheceWalletSum';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserCreditOperationFindById: {
    type: 'UserCreditOperation';
    schemaKey: 'UserCreditOperationFindById';
    entityName: 'UserCreditOperation';
    isList: false;
    argsTSName: 'QueryUserCreditOperationFindByIdArgs';
    returnTSName: "Query['UserCreditOperationFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserCreditOperation';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserCreditOperationFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserCreditOperationPagination: {
    type: 'UserCreditOperationPagination';
    schemaKey: 'UserCreditOperationPagination';
    entityName: 'UserCreditOperationPagination';
    isList: false;
    argsTSName: 'QueryUserCreditOperationPaginationArgs';
    returnTSName: "Query['UserCreditOperationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserCreditOperationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserCreditOperationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserCreditOperationInput';
        }
      ];
      name: 'UserCreditOperationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserCreditOperationFindByIncentiveCampaignId: {
    type: '[UserCreditOperation]';
    schemaKey: 'UserCreditOperationFindByIncentiveCampaignId';
    entityName: 'UserCreditOperation';
    isList: true;
    argsTSName: 'QueryUserCreditOperationFindByIncentiveCampaignIdArgs';
    returnTSName: "Query['UserCreditOperationFindByIncentiveCampaignId']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserCreditOperation]';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String!' }
      ];
      name: 'UserCreditOperationFindByIncentiveCampaignId';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ExtractUserCreditPoints: {
    type: '[UserCreditOperation]';
    schemaKey: 'ExtractUserCreditPoints';
    entityName: 'UserCreditOperation';
    isList: true;
    argsTSName: 'QueryExtractUserCreditPointsArgs';
    returnTSName: "Query['ExtractUserCreditPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserCreditOperation]';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String' },
        { name: 'cpf'; description: null; type: 'String' },
        { name: 'matricula'; description: null; type: 'String' },
        { name: 'userName'; description: null; type: 'String' }
      ];
      name: 'ExtractUserCreditPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadtUserCreditPoints: {
    type: 'DownloadtUserCreditPoints';
    schemaKey: 'DownloadtUserCreditPoints';
    entityName: 'DownloadtUserCreditPoints';
    isList: false;
    argsTSName: 'QueryDownloadtUserCreditPointsArgs';
    returnTSName: "Query['DownloadtUserCreditPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DownloadtUserCreditPoints';
      args: [
        { name: 'incentiveCampaignIds'; description: null; type: '[String]' },
        { name: 'cpf'; description: null; type: 'String' },
        { name: 'matricula'; description: null; type: 'String' },
        { name: 'userName'; description: null; type: 'String' }
      ];
      name: 'DownloadtUserCreditPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserCreditOperationAnalytics: {
    type: '[UserCreditOperation]';
    schemaKey: 'UserCreditOperationAnalytics';
    entityName: 'UserCreditOperation';
    isList: true;
    argsTSName: 'QueryUserCreditOperationAnalyticsArgs';
    returnTSName: "Query['UserCreditOperationAnalytics']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserCreditOperation]';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' }
      ];
      name: 'UserCreditOperationAnalytics';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceTransactionPagination: {
    type: 'ReconheceTransactionPagination';
    schemaKey: 'ReconheceTransactionPagination';
    entityName: 'ReconheceTransactionPagination';
    isList: false;
    argsTSName: 'QueryReconheceTransactionPaginationArgs';
    returnTSName: "Query['ReconheceTransactionPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ReconheceTransactionPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyReconheceTransactionInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyReconheceTransactionInput';
        }
      ];
      name: 'ReconheceTransactionPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceInvoiceOriginFindMany: {
    type: '[ReconheceInvoiceOrigin]';
    schemaKey: 'ReconheceInvoiceOriginFindMany';
    entityName: 'ReconheceInvoiceOrigin';
    isList: true;
    argsTSName: 'QueryReconheceInvoiceOriginFindManyArgs';
    returnTSName: "Query['ReconheceInvoiceOriginFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ReconheceInvoiceOrigin]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyReconheceInvoiceOriginInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyReconheceInvoiceOriginInput';
        }
      ];
      name: 'ReconheceInvoiceOriginFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ReconheceUserBalance: {
    type: 'ReconheceUserBalancePayload';
    schemaKey: 'ReconheceUserBalance';
    entityName: 'ReconheceUserBalancePayload';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['ReconheceUserBalance']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ReconheceUserBalancePayload';
      args: [];
      name: 'ReconheceUserBalance';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventCreateOne: {
    type: 'CreateOneEventPayload';
    schemaKey: 'EventCreateOne';
    entityName: 'CreateOneEventPayload';
    isList: false;
    argsTSName: 'MutationEventCreateOneArgs';
    returnTSName: "Mutation['EventCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEventPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneEventInput!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EventCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventUpdateById: {
    type: 'UpdateByIdEventPayload';
    schemaKey: 'EventUpdateById';
    entityName: 'UpdateByIdEventPayload';
    isList: false;
    argsTSName: 'MutationEventUpdateByIdArgs';
    returnTSName: "Mutation['EventUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdEventPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdEventInput!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EventUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventRemoveById: {
    type: 'RemoveByIdEventPayload';
    schemaKey: 'EventRemoveById';
    entityName: 'RemoveByIdEventPayload';
    isList: false;
    argsTSName: 'MutationEventRemoveByIdArgs';
    returnTSName: "Mutation['EventRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdEventPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EventRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FeedCreateOne: {
    type: 'CreateOneFeedPayload';
    schemaKey: 'FeedCreateOne';
    entityName: 'CreateOneFeedPayload';
    isList: false;
    argsTSName: 'MutationFeedCreateOneArgs';
    returnTSName: "Mutation['FeedCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFeedPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFeedInput!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'FeedCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FeedUpdateById: {
    type: 'UpdateByIdFeedPayload';
    schemaKey: 'FeedUpdateById';
    entityName: 'UpdateByIdFeedPayload';
    isList: false;
    argsTSName: 'MutationFeedUpdateByIdArgs';
    returnTSName: "Mutation['FeedUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdFeedPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdFeedInput!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'FeedUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FeedRemoveById: {
    type: 'RemoveByIdFeedPayload';
    schemaKey: 'FeedRemoveById';
    entityName: 'RemoveByIdFeedPayload';
    isList: false;
    argsTSName: 'MutationFeedRemoveByIdArgs';
    returnTSName: "Mutation['FeedRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdFeedPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'FeedRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MeuDiaPdvTableCreateOne: {
    type: 'CreateOneMeuDiaPdvTablePayload';
    schemaKey: 'MeuDiaPdvTableCreateOne';
    entityName: 'CreateOneMeuDiaPdvTablePayload';
    isList: false;
    argsTSName: 'MutationMeuDiaPdvTableCreateOneArgs';
    returnTSName: "Mutation['MeuDiaPdvTableCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneMeuDiaPdvTablePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneMeuDiaPdvTableInput!';
        }
      ];
      name: 'MeuDiaPdvTableCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MeuDiaPdvTableUpdateOne: {
    type: 'UpdateOneMeuDiaPdvTablePayload';
    schemaKey: 'MeuDiaPdvTableUpdateOne';
    entityName: 'UpdateOneMeuDiaPdvTablePayload';
    isList: false;
    argsTSName: 'MutationMeuDiaPdvTableUpdateOneArgs';
    returnTSName: "Mutation['MeuDiaPdvTableUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneMeuDiaPdvTablePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneMeuDiaPdvTableInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneMeuDiaPdvTableInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneMeuDiaPdvTableInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'MeuDiaPdvTableUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MeuDiaPdvTableRemoveById: {
    type: 'RemoveByIdMeuDiaPdvTablePayload';
    schemaKey: 'MeuDiaPdvTableRemoveById';
    entityName: 'RemoveByIdMeuDiaPdvTablePayload';
    isList: false;
    argsTSName: 'MutationMeuDiaPdvTableRemoveByIdArgs';
    returnTSName: "Mutation['MeuDiaPdvTableRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdMeuDiaPdvTablePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MeuDiaPdvTableRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MeuDiaPdvTableCreateMany: {
    type: 'CreateManyMeuDiaPdvTablePayload';
    schemaKey: 'MeuDiaPdvTableCreateMany';
    entityName: 'CreateManyMeuDiaPdvTablePayload';
    isList: false;
    argsTSName: 'MutationMeuDiaPdvTableCreateManyArgs';
    returnTSName: "Mutation['MeuDiaPdvTableCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyMeuDiaPdvTablePayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyMeuDiaPdvTableInput!]!';
        }
      ];
      name: 'MeuDiaPdvTableCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  IncentiveCampaignCreateOne: {
    type: 'CreateOneIncentiveCampaignPayload';
    schemaKey: 'IncentiveCampaignCreateOne';
    entityName: 'CreateOneIncentiveCampaignPayload';
    isList: false;
    argsTSName: 'MutationIncentiveCampaignCreateOneArgs';
    returnTSName: "Mutation['IncentiveCampaignCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneIncentiveCampaignPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneIncentiveCampaignInput!';
        },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'IncentiveCampaignCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  IncentiveCampaignUpdateOne: {
    type: 'UpdateOneIncentiveCampaignPayload';
    schemaKey: 'IncentiveCampaignUpdateOne';
    entityName: 'UpdateOneIncentiveCampaignPayload';
    isList: false;
    argsTSName: 'MutationIncentiveCampaignUpdateOneArgs';
    returnTSName: "Mutation['IncentiveCampaignUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneIncentiveCampaignPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneIncentiveCampaignInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneIncentiveCampaignInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneIncentiveCampaignInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'IncentiveCampaignUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  IncentiveCampaignRemoveById: {
    type: 'RemoveByIdIncentiveCampaignPayload';
    schemaKey: 'IncentiveCampaignRemoveById';
    entityName: 'RemoveByIdIncentiveCampaignPayload';
    isList: false;
    argsTSName: 'MutationIncentiveCampaignRemoveByIdArgs';
    returnTSName: "Mutation['IncentiveCampaignRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdIncentiveCampaignPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'IncentiveCampaignRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CampaignContestationCreateOne: {
    type: 'CreateOneCampaignContestationPayload';
    schemaKey: 'CampaignContestationCreateOne';
    entityName: 'CreateOneCampaignContestationPayload';
    isList: false;
    argsTSName: 'MutationCampaignContestationCreateOneArgs';
    returnTSName: "Mutation['CampaignContestationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCampaignContestationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneCampaignContestationInput!';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CampaignContestationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CampaignContestationResolve: {
    type: 'CampaignContestation';
    schemaKey: 'CampaignContestationResolve';
    entityName: 'CampaignContestation';
    isList: false;
    argsTSName: 'MutationCampaignContestationResolveArgs';
    returnTSName: "Mutation['CampaignContestationResolve']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CampaignContestation';
      args: [
        { name: '_id'; description: null; type: 'String!' },
        { name: 'title'; description: null; type: 'String!' },
        {
          name: 'status';
          description: null;
          type: 'EnumCampaignContestationResponseStatus!';
        },
        { name: 'body'; description: null; type: 'String!' },
        { name: 'responseFileIds'; description: null; type: '[String]' }
      ];
      name: 'CampaignContestationResolve';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  IncentiveCampaignPayPoints: {
    type: 'Boolean';
    schemaKey: 'IncentiveCampaignPayPoints';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationIncentiveCampaignPayPointsArgs';
    returnTSName: "Mutation['IncentiveCampaignPayPoints']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'campaignId'; description: null; type: 'String!' },
        { name: 'paymentFileId'; description: null; type: 'String!' }
      ];
      name: 'IncentiveCampaignPayPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconheceReversePoints: {
    type: 'Boolean';
    schemaKey: 'ReconheceReversePoints';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationReconheceReversePointsArgs';
    returnTSName: "Mutation['ReconheceReversePoints']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'paymentFileId'; description: null; type: 'String!' },
        { name: 'campaignId'; description: null; type: 'String!' },
        { name: 'reason'; description: null; type: 'String!' }
      ];
      name: 'ReconheceReversePoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconhecePayExtraPoints: {
    type: 'Boolean';
    schemaKey: 'ReconhecePayExtraPoints';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationReconhecePayExtraPointsArgs';
    returnTSName: "Mutation['ReconhecePayExtraPoints']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'paymentFileId'; description: null; type: 'String!' },
        { name: 'campaignId'; description: null; type: 'String!' }
      ];
      name: 'ReconhecePayExtraPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  IncentiveCampaignLikeToggle: {
    type: 'Boolean';
    schemaKey: 'IncentiveCampaignLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationIncentiveCampaignLikeToggleArgs';
    returnTSName: "Mutation['IncentiveCampaignLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'IncentiveCampaignLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ResearchCreateOne: {
    type: 'CreateOneResearchPayload';
    schemaKey: 'ResearchCreateOne';
    entityName: 'CreateOneResearchPayload';
    isList: false;
    argsTSName: 'MutationResearchCreateOneArgs';
    returnTSName: "Mutation['ResearchCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneResearchPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneResearchInput!' }
      ];
      name: 'ResearchCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ResearchUpdateOne: {
    type: 'UpdateOneResearchPayload';
    schemaKey: 'ResearchUpdateOne';
    entityName: 'UpdateOneResearchPayload';
    isList: false;
    argsTSName: 'MutationResearchUpdateOneArgs';
    returnTSName: "Mutation['ResearchUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneResearchPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneResearchInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneResearchInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneResearchInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'ResearchUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ResearchUpdateById: {
    type: 'UpdateByIdResearchPayload';
    schemaKey: 'ResearchUpdateById';
    entityName: 'UpdateByIdResearchPayload';
    isList: false;
    argsTSName: 'MutationResearchUpdateByIdArgs';
    returnTSName: "Mutation['ResearchUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdResearchPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdResearchInput!' }
      ];
      name: 'ResearchUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ResearchRemoveById: {
    type: 'RemoveByIdResearchPayload';
    schemaKey: 'ResearchRemoveById';
    entityName: 'RemoveByIdResearchPayload';
    isList: false;
    argsTSName: 'MutationResearchRemoveByIdArgs';
    returnTSName: "Mutation['ResearchRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdResearchPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ResearchRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnswerResearchCreateOne: {
    type: 'CreateOneAnswerResearchPayload';
    schemaKey: 'AnswerResearchCreateOne';
    entityName: 'CreateOneAnswerResearchPayload';
    isList: false;
    argsTSName: 'MutationAnswerResearchCreateOneArgs';
    returnTSName: "Mutation['AnswerResearchCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneAnswerResearchPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneAnswerResearchInput!';
        }
      ];
      name: 'AnswerResearchCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnswerResearchUpdateOne: {
    type: 'UpdateOneAnswerResearchPayload';
    schemaKey: 'AnswerResearchUpdateOne';
    entityName: 'UpdateOneAnswerResearchPayload';
    isList: false;
    argsTSName: 'MutationAnswerResearchUpdateOneArgs';
    returnTSName: "Mutation['AnswerResearchUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneAnswerResearchPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneAnswerResearchInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneAnswerResearchInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneAnswerResearchInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'AnswerResearchUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnswerResearchUpdateById: {
    type: 'UpdateByIdAnswerResearchPayload';
    schemaKey: 'AnswerResearchUpdateById';
    entityName: 'UpdateByIdAnswerResearchPayload';
    isList: false;
    argsTSName: 'MutationAnswerResearchUpdateByIdArgs';
    returnTSName: "Mutation['AnswerResearchUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdAnswerResearchPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdAnswerResearchInput!';
        }
      ];
      name: 'AnswerResearchUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnswerResearchRemoveById: {
    type: 'RemoveByIdAnswerResearchPayload';
    schemaKey: 'AnswerResearchRemoveById';
    entityName: 'RemoveByIdAnswerResearchPayload';
    isList: false;
    argsTSName: 'MutationAnswerResearchRemoveByIdArgs';
    returnTSName: "Mutation['AnswerResearchRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdAnswerResearchPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnswerResearchRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailTemplateCreateOne: {
    type: 'CreateOneEmailTemplatePayload';
    schemaKey: 'EmailTemplateCreateOne';
    entityName: 'CreateOneEmailTemplatePayload';
    isList: false;
    argsTSName: 'MutationEmailTemplateCreateOneArgs';
    returnTSName: "Mutation['EmailTemplateCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEmailTemplatePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneEmailTemplateInput!';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EmailTemplateCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailTemplateUpdateById: {
    type: 'UpdateByIdEmailTemplatePayload';
    schemaKey: 'EmailTemplateUpdateById';
    entityName: 'UpdateByIdEmailTemplatePayload';
    isList: false;
    argsTSName: 'MutationEmailTemplateUpdateByIdArgs';
    returnTSName: "Mutation['EmailTemplateUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdEmailTemplatePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdEmailTemplateInput!';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EmailTemplateUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailTemplateRemoveById: {
    type: 'RemoveByIdEmailTemplatePayload';
    schemaKey: 'EmailTemplateRemoveById';
    entityName: 'RemoveByIdEmailTemplatePayload';
    isList: false;
    argsTSName: 'MutationEmailTemplateRemoveByIdArgs';
    returnTSName: "Mutation['EmailTemplateRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdEmailTemplatePayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'EmailTemplateRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsDocumentsCreateOne: {
    type: 'CreateOneAnalyticsDocumentsPayload';
    schemaKey: 'AnalyticsDocumentsCreateOne';
    entityName: 'CreateOneAnalyticsDocumentsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsDocumentsCreateOneArgs';
    returnTSName: "Mutation['AnalyticsDocumentsCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneAnalyticsDocumentsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneAnalyticsDocumentsInput!';
        }
      ];
      name: 'AnalyticsDocumentsCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsDocumentsUpdateOne: {
    type: 'UpdateOneAnalyticsDocumentsPayload';
    schemaKey: 'AnalyticsDocumentsUpdateOne';
    entityName: 'UpdateOneAnalyticsDocumentsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsDocumentsUpdateOneArgs';
    returnTSName: "Mutation['AnalyticsDocumentsUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneAnalyticsDocumentsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneAnalyticsDocumentsInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneAnalyticsDocumentsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneAnalyticsDocumentsInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'AnalyticsDocumentsUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsDocumentsUpdateById: {
    type: 'UpdateByIdAnalyticsDocumentsPayload';
    schemaKey: 'AnalyticsDocumentsUpdateById';
    entityName: 'UpdateByIdAnalyticsDocumentsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsDocumentsUpdateByIdArgs';
    returnTSName: "Mutation['AnalyticsDocumentsUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdAnalyticsDocumentsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdAnalyticsDocumentsInput!';
        }
      ];
      name: 'AnalyticsDocumentsUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsDocumentsRemoveById: {
    type: 'RemoveByIdAnalyticsDocumentsPayload';
    schemaKey: 'AnalyticsDocumentsRemoveById';
    entityName: 'RemoveByIdAnalyticsDocumentsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsDocumentsRemoveByIdArgs';
    returnTSName: "Mutation['AnalyticsDocumentsRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdAnalyticsDocumentsPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnalyticsDocumentsRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsCommunicationsCreateOne: {
    type: 'CreateOneAnalyticsCommunicationsPayload';
    schemaKey: 'AnalyticsCommunicationsCreateOne';
    entityName: 'CreateOneAnalyticsCommunicationsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsCommunicationsCreateOneArgs';
    returnTSName: "Mutation['AnalyticsCommunicationsCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneAnalyticsCommunicationsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneAnalyticsCommunicationsInput!';
        }
      ];
      name: 'AnalyticsCommunicationsCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsCommunicationsUpdateOne: {
    type: 'UpdateOneAnalyticsCommunicationsPayload';
    schemaKey: 'AnalyticsCommunicationsUpdateOne';
    entityName: 'UpdateOneAnalyticsCommunicationsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsCommunicationsUpdateOneArgs';
    returnTSName: "Mutation['AnalyticsCommunicationsUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneAnalyticsCommunicationsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneAnalyticsCommunicationsInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneAnalyticsCommunicationsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneAnalyticsCommunicationsInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'AnalyticsCommunicationsUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsCommunicationsUpdateById: {
    type: 'UpdateByIdAnalyticsCommunicationsPayload';
    schemaKey: 'AnalyticsCommunicationsUpdateById';
    entityName: 'UpdateByIdAnalyticsCommunicationsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsCommunicationsUpdateByIdArgs';
    returnTSName: "Mutation['AnalyticsCommunicationsUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdAnalyticsCommunicationsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdAnalyticsCommunicationsInput!';
        }
      ];
      name: 'AnalyticsCommunicationsUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsCommunicationsRemoveById: {
    type: 'RemoveByIdAnalyticsCommunicationsPayload';
    schemaKey: 'AnalyticsCommunicationsRemoveById';
    entityName: 'RemoveByIdAnalyticsCommunicationsPayload';
    isList: false;
    argsTSName: 'MutationAnalyticsCommunicationsRemoveByIdArgs';
    returnTSName: "Mutation['AnalyticsCommunicationsRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdAnalyticsCommunicationsPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AnalyticsCommunicationsRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegionRemoveOne: {
    type: 'RemoveOneRegionPayload';
    schemaKey: 'RegionRemoveOne';
    entityName: 'RemoveOneRegionPayload';
    isList: false;
    argsTSName: 'MutationRegionRemoveOneArgs';
    returnTSName: "Mutation['RegionRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneRegionPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneRegionInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneRegionInput' }
      ];
      name: 'RegionRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserContentPermissionToggle: {
    type: 'Boolean';
    schemaKey: 'UserContentPermissionToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserContentPermissionToggleArgs';
    returnTSName: "Mutation['UserContentPermissionToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'userId'; description: null; type: 'String!' },
        { name: 'roleGroups'; description: null; type: '[String]!' },
        { name: 'regions'; description: null; type: '[String]!' },
        { name: 'contentTypes'; description: null; type: '[String]!' },
        { name: 'viewOtherUsersContent'; description: null; type: 'Boolean' }
      ];
      name: 'UserContentPermissionToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserUpdateOne: {
    type: 'UpdateOneUserPayload';
    schemaKey: 'UserUpdateOne';
    entityName: 'UpdateOneUserPayload';
    isList: false;
    argsTSName: 'MutationUserUpdateOneArgs';
    returnTSName: "Mutation['UserUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneUserPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneUserInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneUserInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneUserInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'UserUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserRemoveById: {
    type: 'RemoveByIdUserPayload';
    schemaKey: 'UserRemoveById';
    entityName: 'RemoveByIdUserPayload';
    isList: false;
    argsTSName: 'MutationUserRemoveByIdArgs';
    returnTSName: "Mutation['UserRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdUserPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserAcceptTerm: {
    type: 'Boolean';
    schemaKey: 'UserAcceptTerm';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserAcceptTermArgs';
    returnTSName: "Mutation['UserAcceptTerm']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'term'; description: null; type: 'String!' }];
      name: 'UserAcceptTerm';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginWithPassword: {
    type: 'User';
    schemaKey: 'UserLoginWithPassword';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginWithPasswordArgs';
    returnTSName: "Mutation['UserLoginWithPassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        {
          name: 'user';
          description: 'email, CPF, CNPJ ou matricula';
          type: 'String!';
        },
        { name: 'password'; description: null; type: 'String!' },
        { name: 'platformName'; description: null; type: 'PlatformNamesEnum' }
      ];
      name: 'UserLoginWithPassword';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginWithToken: {
    type: 'User';
    schemaKey: 'UserLoginWithToken';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginWithTokenArgs';
    returnTSName: "Mutation['UserLoginWithToken']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        { name: 'token'; description: null; type: 'String!' },
        { name: 'platformName'; description: null; type: 'PlatformNamesEnum' }
      ];
      name: 'UserLoginWithToken';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginRenewToken: {
    type: 'User';
    schemaKey: 'UserLoginRenewToken';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginRenewTokenArgs';
    returnTSName: "Mutation['UserLoginRenewToken']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [{ name: 'token'; description: null; type: 'String!' }];
      name: 'UserLoginRenewToken';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserSaveLogoutAnalytics: {
    type: 'Boolean';
    schemaKey: 'UserSaveLogoutAnalytics';
    entityName: 'Boolean';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['UserSaveLogoutAnalytics']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [];
      name: 'UserSaveLogoutAnalytics';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserSaveLoginAnalyticsPCS: {
    type: 'Boolean';
    schemaKey: 'UserSaveLoginAnalyticsPCS';
    entityName: 'Boolean';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['UserSaveLoginAnalyticsPCS']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [];
      name: 'UserSaveLoginAnalyticsPCS';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserFillEmail: {
    type: 'Boolean';
    schemaKey: 'UserFillEmail';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserFillEmailArgs';
    returnTSName: "Mutation['UserFillEmail']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'email'; description: null; type: 'String!' }];
      name: 'UserFillEmail';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserFillAvatarId: {
    type: 'Boolean';
    schemaKey: 'UserFillAvatarId';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserFillAvatarIdArgs';
    returnTSName: "Mutation['UserFillAvatarId']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'avatarId'; description: null; type: 'String!' }];
      name: 'UserFillAvatarId';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserOptIn: {
    type: 'Boolean';
    schemaKey: 'UserOptIn';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserOptInArgs';
    returnTSName: "Mutation['UserOptIn']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'accept'; description: null; type: 'Boolean!' }];
      name: 'UserOptIn';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserMarkNotificationAsRead: {
    type: 'Boolean';
    schemaKey: 'UserMarkNotificationAsRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserMarkNotificationAsReadArgs';
    returnTSName: "Mutation['UserMarkNotificationAsRead']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'isRead'; description: null; type: 'Boolean!' },
        { name: 'notifications'; description: null; type: '[String]!' }
      ];
      name: 'UserMarkNotificationAsRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserDeleteNotification: {
    type: 'String';
    schemaKey: 'UserDeleteNotification';
    entityName: 'String';
    isList: false;
    argsTSName: 'MutationUserDeleteNotificationArgs';
    returnTSName: "Mutation['UserDeleteNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'String';
      args: [{ name: 'notificationId'; description: null; type: 'String!' }];
      name: 'UserDeleteNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserDeleteManyNotification: {
    type: '[String]';
    schemaKey: 'UserDeleteManyNotification';
    entityName: 'String';
    isList: true;
    argsTSName: 'MutationUserDeleteManyNotificationArgs';
    returnTSName: "Mutation['UserDeleteManyNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[String]';
      args: [{ name: 'notificationIds'; description: null; type: '[String!]' }];
      name: 'UserDeleteManyNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserRegistration: {
    type: 'UserRegistrationPayload';
    schemaKey: 'UserRegistration';
    entityName: 'UserRegistrationPayload';
    isList: false;
    argsTSName: 'MutationUserRegistrationArgs';
    returnTSName: "Mutation['UserRegistration']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserRegistrationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UserRegistrationTypeInput!';
        }
      ];
      name: 'UserRegistration';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserBlockComments: {
    type: 'Boolean';
    schemaKey: 'UserBlockComments';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserBlockCommentsArgs';
    returnTSName: "Mutation['UserBlockComments']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'userId'; description: null; type: 'String!' },
        { name: 'isBlocked'; description: null; type: 'Boolean' },
        { name: 'materialId'; description: null; type: 'String' }
      ];
      name: 'UserBlockComments';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ChangeToUserTaNarede: {
    type: 'UserTaNaRede';
    schemaKey: 'ChangeToUserTaNarede';
    entityName: 'UserTaNaRede';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['ChangeToUserTaNarede']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'UserTaNaRede';
      args: [];
      name: 'ChangeToUserTaNarede';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserChangePassword: {
    type: 'Boolean';
    schemaKey: 'UserChangePassword';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserChangePasswordArgs';
    returnTSName: "Mutation['UserChangePassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'newPassword'; description: null; type: 'String!' }];
      name: 'UserChangePassword';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserFillEmailSecondary: {
    type: 'Boolean';
    schemaKey: 'UserFillEmailSecondary';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserFillEmailSecondaryArgs';
    returnTSName: "Mutation['UserFillEmailSecondary']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'emailSecondary'; description: null; type: 'String!' }];
      name: 'UserFillEmailSecondary';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserConnectMovidesk: {
    type: 'String';
    schemaKey: 'UserConnectMovidesk';
    entityName: 'String';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['UserConnectMovidesk']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'String';
      args: [];
      name: 'UserConnectMovidesk';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserDisable: {
    type: 'Boolean';
    schemaKey: 'UserDisable';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserDisableArgs';
    returnTSName: "Mutation['UserDisable']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: '_id'; description: null; type: 'String!' }];
      name: 'UserDisable';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileCreateOne: {
    type: 'CreateOneFilePayload';
    schemaKey: 'FileCreateOne';
    entityName: 'CreateOneFilePayload';
    isList: false;
    argsTSName: 'MutationFileCreateOneArgs';
    returnTSName: "Mutation['FileCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFilePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFileInput!' }
      ];
      name: 'FileCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileRemoveById: {
    type: 'RemoveByIdFilePayload';
    schemaKey: 'FileRemoveById';
    entityName: 'RemoveByIdFilePayload';
    isList: false;
    argsTSName: 'MutationFileRemoveByIdArgs';
    returnTSName: "Mutation['FileRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdFilePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FileRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileUpdateById: {
    type: 'UpdateByIdFilePayload';
    schemaKey: 'FileUpdateById';
    entityName: 'UpdateByIdFilePayload';
    isList: false;
    argsTSName: 'MutationFileUpdateByIdArgs';
    returnTSName: "Mutation['FileUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdFilePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdFileInput!' }
      ];
      name: 'FileUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialCreateOne: {
    type: 'CreateOneMaterialPayload';
    schemaKey: 'MaterialCreateOne';
    entityName: 'CreateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialCreateOneArgs';
    returnTSName: "Mutation['MaterialCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMaterialInput!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialUpdateOne: {
    type: 'UpdateOneMaterialPayload';
    schemaKey: 'MaterialUpdateOne';
    entityName: 'UpdateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialUpdateOneArgs';
    returnTSName: "Mutation['MaterialUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneMaterialInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneMaterialInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'MaterialUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialUpdateById: {
    type: 'UpdateByIdMaterialPayload';
    schemaKey: 'MaterialUpdateById';
    entityName: 'UpdateByIdMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialUpdateByIdArgs';
    returnTSName: "Mutation['MaterialUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdMaterialInput!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialRemoveOne: {
    type: 'RemoveOneMaterialPayload';
    schemaKey: 'MaterialRemoveOne';
    entityName: 'RemoveOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialRemoveOneArgs';
    returnTSName: "Mutation['MaterialRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneMaterialPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneMaterialInput' }
      ];
      name: 'MaterialRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationCreateOne: {
    type: 'CreateOneMaterialPayload';
    schemaKey: 'CommunicationCreateOne';
    entityName: 'CreateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationCommunicationCreateOneArgs';
    returnTSName: "Mutation['CommunicationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CreateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMaterialInput!' }
      ];
      name: 'CommunicationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationUpdateOne: {
    type: 'UpdateOneMaterialPayload';
    schemaKey: 'CommunicationUpdateOne';
    entityName: 'UpdateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationCommunicationUpdateOneArgs';
    returnTSName: "Mutation['CommunicationUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UpdateOneMaterialPayload';
      args: [
        { name: 'communicationId'; description: null; type: 'String!' },
        { name: 'record'; description: null; type: 'UpdateOneMaterialInput' }
      ];
      name: 'CommunicationUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationApproveChannel: {
    type: 'Boolean';
    schemaKey: 'CommunicationApproveChannel';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationApproveChannelArgs';
    returnTSName: "Mutation['CommunicationApproveChannel']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'CommunicationApproveChannel';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationDisapproveChannel: {
    type: 'Boolean';
    schemaKey: 'CommunicationDisapproveChannel';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationDisapproveChannelArgs';
    returnTSName: "Mutation['CommunicationDisapproveChannel']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'recordId'; description: null; type: 'String!' },
        { name: 'message'; description: null; type: 'String!' }
      ];
      name: 'CommunicationDisapproveChannel';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationRemoveApprover: {
    type: 'Boolean';
    schemaKey: 'CommunicationRemoveApprover';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationRemoveApproverArgs';
    returnTSName: "Mutation['CommunicationRemoveApprover']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'communicationId'; description: null; type: 'String!' },
        { name: 'userId'; description: null; type: 'String!' }
      ];
      name: 'CommunicationRemoveApprover';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailFillTemplate: {
    type: 'String!';
    schemaKey: 'EmailFillTemplate';
    entityName: 'String';
    isList: false;
    argsTSName: 'MutationEmailFillTemplateArgs';
    returnTSName: "Mutation['EmailFillTemplate']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'String!';
      args: [
        { name: 'template'; description: null; type: 'EmailTemplateNames' },
        {
          name: 'props';
          description: null;
          type: 'MaterialCommunicationItemsConfigTemplatePropsInput';
        }
      ];
      name: 'EmailFillTemplate';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  EmailMarkAsRead: {
    type: 'Boolean';
    schemaKey: 'EmailMarkAsRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEmailMarkAsReadArgs';
    returnTSName: "Mutation['EmailMarkAsRead']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Marca um email como lido pelo destinatario informado pelo token recebido no e-mail';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'token'; description: null; type: 'String!' }];
      name: 'EmailMarkAsRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SendEmailFront: {
    type: 'Boolean';
    schemaKey: 'SendEmailFront';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationSendEmailFrontArgs';
    returnTSName: "Mutation['SendEmailFront']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'subject'; description: null; type: 'String' },
        { name: 'text'; description: null; type: 'String' },
        { name: 'attach'; description: null; type: '[String]' },
        { name: 'categories'; description: null; type: '[String]' }
      ];
      name: 'SendEmailFront';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialSendTestEmail: {
    type: 'Boolean!';
    schemaKey: 'MaterialSendTestEmail';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMaterialSendTestEmailArgs';
    returnTSName: "Mutation['MaterialSendTestEmail']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean!';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'MaterialSendTestEmail';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  MaterialLikeToggle: {
    type: 'Boolean';
    schemaKey: 'MaterialLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMaterialLikeToggleArgs';
    returnTSName: "Mutation['MaterialLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'MaterialLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UpdateDeteachedAndLatestVideo: {
    type: 'Boolean';
    schemaKey: 'UpdateDeteachedAndLatestVideo';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUpdateDeteachedAndLatestVideoArgs';
    returnTSName: "Mutation['UpdateDeteachedAndLatestVideo']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'idDetachedVideo'; description: null; type: 'String' },
        { name: 'idLatestVideo1'; description: null; type: 'String' },
        { name: 'idLatestVideo2'; description: null; type: 'String' },
        { name: 'idLatestVideo3'; description: null; type: 'String' }
      ];
      name: 'UpdateDeteachedAndLatestVideo';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationCreateOne: {
    type: 'CreateOneNotificationPayload';
    schemaKey: 'NotificationCreateOne';
    entityName: 'CreateOneNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationCreateOneArgs';
    returnTSName: "Mutation['NotificationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneNotificationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneNotificationInput!';
        }
      ];
      name: 'NotificationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationCreateMany: {
    type: 'CreateManyNotificationPayload';
    schemaKey: 'NotificationCreateMany';
    entityName: 'CreateManyNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationCreateManyArgs';
    returnTSName: "Mutation['NotificationCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyNotificationPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyNotificationInput!]!';
        }
      ];
      name: 'NotificationCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationUpdateOne: {
    type: 'UpdateOneNotificationPayload';
    schemaKey: 'NotificationUpdateOne';
    entityName: 'UpdateOneNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationUpdateOneArgs';
    returnTSName: "Mutation['NotificationUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneNotificationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneNotificationInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneNotificationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'NotificationUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationRemoveById: {
    type: 'RemoveByIdNotificationPayload';
    schemaKey: 'NotificationRemoveById';
    entityName: 'RemoveByIdNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationRemoveByIdArgs';
    returnTSName: "Mutation['NotificationRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdNotificationPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'NotificationRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryCreateOne: {
    type: 'CreateOneCategoryPayload';
    schemaKey: 'CategoryCreateOne';
    entityName: 'CreateOneCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryCreateOneArgs';
    returnTSName: "Mutation['CategoryCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCategoryPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneCategoryInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryUpdateOne: {
    type: 'UpdateOneCategoryPayload';
    schemaKey: 'CategoryUpdateOne';
    entityName: 'UpdateOneCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryUpdateOneArgs';
    returnTSName: "Mutation['CategoryUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneCategoryPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneCategoryInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneCategoryInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneCategoryInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'CategoryUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryRemoveById: {
    type: 'RemoveByIdCategoryPayload';
    schemaKey: 'CategoryRemoveById';
    entityName: 'RemoveByIdCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryRemoveByIdArgs';
    returnTSName: "Mutation['CategoryRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdCategoryPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryCreateMany: {
    type: 'CreateManyCategoryPayload';
    schemaKey: 'CategoryCreateMany';
    entityName: 'CreateManyCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryCreateManyArgs';
    returnTSName: "Mutation['CategoryCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyCategoryPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyCategoryInput!]!';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  WebPushSubscribe: {
    type: 'Boolean';
    schemaKey: 'WebPushSubscribe';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationWebPushSubscribeArgs';
    returnTSName: "Mutation['WebPushSubscribe']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario.';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'subscription'; description: null; type: 'JSON!' }];
      name: 'WebPushSubscribe';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  WebPushSendNotification: {
    type: '[String]';
    schemaKey: 'WebPushSendNotification';
    entityName: 'String';
    isList: true;
    argsTSName: 'MutationWebPushSendNotificationArgs';
    returnTSName: "Mutation['WebPushSendNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Envia uma web push notification para uma lista de userIds';
      deprecationReason: null;
      type: '[String]';
      args: [
        { name: 'userIds'; description: null; type: '[String]!' },
        { name: 'notificationOptions'; description: null; type: 'JSON!' },
        { name: 'sync'; description: null; type: 'Boolean' }
      ];
      name: 'WebPushSendNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinCreateOne: {
    type: 'CreateOnePinPayload';
    schemaKey: 'PinCreateOne';
    entityName: 'CreateOnePinPayload';
    isList: false;
    argsTSName: 'MutationPinCreateOneArgs';
    returnTSName: "Mutation['PinCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePinPayload';
      args: [{ name: 'record'; description: null; type: 'CreateOnePinInput!' }];
      name: 'PinCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinRemoveOne: {
    type: 'RemoveOnePinPayload';
    schemaKey: 'PinRemoveOne';
    entityName: 'RemoveOnePinPayload';
    isList: false;
    argsTSName: 'MutationPinRemoveOneArgs';
    returnTSName: "Mutation['PinRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOnePinPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOnePinInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOnePinInput' }
      ];
      name: 'PinRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinRemoveMany: {
    type: 'RemoveManyPinPayload';
    schemaKey: 'PinRemoveMany';
    entityName: 'RemoveManyPinPayload';
    isList: false;
    argsTSName: 'MutationPinRemoveManyArgs';
    returnTSName: "Mutation['PinRemoveMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'RemoveManyPinPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveManyPinInput!';
        }
      ];
      name: 'PinRemoveMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareCreateOne: {
    type: 'CreateOneSharePayload';
    schemaKey: 'ShareCreateOne';
    entityName: 'CreateOneSharePayload';
    isList: false;
    argsTSName: 'MutationShareCreateOneArgs';
    returnTSName: "Mutation['ShareCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneSharePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneShareInput!' }
      ];
      name: 'ShareCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareRemoveOne: {
    type: 'RemoveOneSharePayload';
    schemaKey: 'ShareRemoveOne';
    entityName: 'RemoveOneSharePayload';
    isList: false;
    argsTSName: 'MutationShareRemoveOneArgs';
    returnTSName: "Mutation['ShareRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneSharePayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneShareInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneShareInput' }
      ];
      name: 'ShareRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareRemoveMe: {
    type: 'Boolean!';
    schemaKey: 'ShareRemoveMe';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationShareRemoveMeArgs';
    returnTSName: "Mutation['ShareRemoveMe']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove o usuário atual de um compartilhamento.';
      deprecationReason: null;
      type: 'Boolean!';
      args: [{ name: 'shareId'; description: null; type: 'String' }];
      name: 'ShareRemoveMe';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  CommentCreateOne: {
    type: 'CreateOneCommentPayload';
    schemaKey: 'CommentCreateOne';
    entityName: 'CreateOneCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentCreateOneArgs';
    returnTSName: "Mutation['CommentCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCommentPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneCommentInput!' }
      ];
      name: 'CommentCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentUpdateOne: {
    type: 'UpdateOneCommentPayload';
    schemaKey: 'CommentUpdateOne';
    entityName: 'UpdateOneCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentUpdateOneArgs';
    returnTSName: "Mutation['CommentUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneCommentPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneCommentInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneCommentInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneCommentInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'CommentUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentRemoveById: {
    type: 'RemoveByIdCommentPayload';
    schemaKey: 'CommentRemoveById';
    entityName: 'RemoveByIdCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentRemoveByIdArgs';
    returnTSName: "Mutation['CommentRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdCommentPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'CommentRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentLikeToggle: {
    type: 'Boolean';
    schemaKey: 'CommentLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommentLikeToggleArgs';
    returnTSName: "Mutation['CommentLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'CommentLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveCreateOne: {
    type: 'CreateOneEventLivePayload';
    schemaKey: 'EventLiveCreateOne';
    entityName: 'CreateOneEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveCreateOneArgs';
    returnTSName: "Mutation['EventLiveCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEventLivePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneEventLiveInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveRemoveById: {
    type: 'RemoveByIdEventLivePayload';
    schemaKey: 'EventLiveRemoveById';
    entityName: 'RemoveByIdEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveRemoveByIdArgs';
    returnTSName: "Mutation['EventLiveRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdEventLivePayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveUpdateOne: {
    type: 'UpdateOneEventLivePayload';
    schemaKey: 'EventLiveUpdateOne';
    entityName: 'UpdateOneEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveUpdateOneArgs';
    returnTSName: "Mutation['EventLiveUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneEventLivePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneEventLiveInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneEventLiveInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneEventLiveInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'EventLiveUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveCloseRoom: {
    type: 'Boolean';
    schemaKey: 'EventLiveCloseRoom';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEventLiveCloseRoomArgs';
    returnTSName: "Mutation['EventLiveCloseRoom']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'eventId'; description: null; type: 'String!' }];
      name: 'EventLiveCloseRoom';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveLikeToggle: {
    type: 'Boolean';
    schemaKey: 'EventLiveLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEventLiveLikeToggleArgs';
    returnTSName: "Mutation['EventLiveLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'EventLiveLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerRemoveById: {
    type: 'RemoveByIdBannerPayload';
    schemaKey: 'BannerRemoveById';
    entityName: 'RemoveByIdBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerRemoveByIdArgs';
    returnTSName: "Mutation['BannerRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdBannerPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'BannerRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerCreateOne: {
    type: 'CreateOneBannerPayload';
    schemaKey: 'BannerCreateOne';
    entityName: 'CreateOneBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerCreateOneArgs';
    returnTSName: "Mutation['BannerCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneBannerPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneBannerInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'BannerCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerUpdateOne: {
    type: 'UpdateOneBannerPayload';
    schemaKey: 'BannerUpdateOne';
    entityName: 'UpdateOneBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerUpdateOneArgs';
    returnTSName: "Mutation['BannerUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneBannerPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneBannerInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneBannerInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneBannerInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'BannerUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsPageView: {
    type: 'Boolean';
    schemaKey: 'AnalyticsPageView';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationAnalyticsPageViewArgs';
    returnTSName: "Mutation['AnalyticsPageView']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'title'; description: null; type: 'String!' },
        { name: 'host'; description: null; type: 'String' },
        { name: 'route'; description: null; type: 'String' }
      ];
      name: 'AnalyticsPageView';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsTrackEvent: {
    type: 'Boolean';
    schemaKey: 'AnalyticsTrackEvent';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationAnalyticsTrackEventArgs';
    returnTSName: "Mutation['AnalyticsTrackEvent']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'event'; description: null; type: 'EnumAnalyticsEvent!' },
        { name: 'kind'; description: null; type: 'EnumAnalyticsParentKind!' },
        { name: 'recordId'; description: null; type: 'String' },
        { name: 'entityName'; description: null; type: 'String' },
        { name: 'label'; description: null; type: 'String' }
      ];
      name: 'AnalyticsTrackEvent';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderCreateOne: {
    type: 'CreateOneFolderPayload';
    schemaKey: 'FolderCreateOne';
    entityName: 'CreateOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderCreateOneArgs';
    returnTSName: "Mutation['FolderCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFolderPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFolderInput!' }
      ];
      name: 'FolderCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderRemoveOne: {
    type: 'RemoveOneFolderPayload';
    schemaKey: 'FolderRemoveOne';
    entityName: 'RemoveOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderRemoveOneArgs';
    returnTSName: "Mutation['FolderRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneFolderPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneFolderInput' }
      ];
      name: 'FolderRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderRemoveMany: {
    type: 'RemoveManyFolderPayload';
    schemaKey: 'FolderRemoveMany';
    entityName: 'RemoveManyFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderRemoveManyArgs';
    returnTSName: "Mutation['FolderRemoveMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'RemoveManyFolderPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveManyFolderInput!';
        }
      ];
      name: 'FolderRemoveMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderUpdateOne: {
    type: 'UpdateOneFolderPayload';
    schemaKey: 'FolderUpdateOne';
    entityName: 'UpdateOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderUpdateOneArgs';
    returnTSName: "Mutation['FolderUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneFolderPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneFolderInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneFolderInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'FolderUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RecentsActivitiesCreateOne: {
    type: 'CreateOneRecentsActivitiesPayload';
    schemaKey: 'RecentsActivitiesCreateOne';
    entityName: 'CreateOneRecentsActivitiesPayload';
    isList: false;
    argsTSName: 'MutationRecentsActivitiesCreateOneArgs';
    returnTSName: "Mutation['RecentsActivitiesCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneRecentsActivitiesPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneRecentsActivitiesInput!';
        }
      ];
      name: 'RecentsActivitiesCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserWordsBlackListCreateOne: {
    type: 'CreateOneUserWordsBlackListPayload';
    schemaKey: 'UserWordsBlackListCreateOne';
    entityName: 'CreateOneUserWordsBlackListPayload';
    isList: false;
    argsTSName: 'MutationUserWordsBlackListCreateOneArgs';
    returnTSName: "Mutation['UserWordsBlackListCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneUserWordsBlackListPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneUserWordsBlackListInput!';
        }
      ];
      name: 'UserWordsBlackListCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ImportUsersPlanCreateOne: {
    type: 'CreateOneImportUsersPlanPayload';
    schemaKey: 'ImportUsersPlanCreateOne';
    entityName: 'CreateOneImportUsersPlanPayload';
    isList: false;
    argsTSName: 'MutationImportUsersPlanCreateOneArgs';
    returnTSName: "Mutation['ImportUsersPlanCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneImportUsersPlanPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneImportUsersPlanInput!';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ImportUsersPlanUpdateOne: {
    type: 'UpdateOneImportUsersPlanPayload';
    schemaKey: 'ImportUsersPlanUpdateOne';
    entityName: 'UpdateOneImportUsersPlanPayload';
    isList: false;
    argsTSName: 'MutationImportUsersPlanUpdateOneArgs';
    returnTSName: "Mutation['ImportUsersPlanUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneImportUsersPlanPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneImportUsersPlanInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneImportUsersPlanInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneImportUsersPlanInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'ImportUsersPlanUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserHistoryBlockCreateOne: {
    type: 'CreateOneUserHistoryBlockPayload';
    schemaKey: 'UserHistoryBlockCreateOne';
    entityName: 'CreateOneUserHistoryBlockPayload';
    isList: false;
    argsTSName: 'MutationUserHistoryBlockCreateOneArgs';
    returnTSName: "Mutation['UserHistoryBlockCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneUserHistoryBlockPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneUserHistoryBlockInput!';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'UserHistoryBlockCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailStatsCreateMany: {
    type: 'CreateManyEmailStatsPayload';
    schemaKey: 'EmailStatsCreateMany';
    entityName: 'CreateManyEmailStatsPayload';
    isList: false;
    argsTSName: 'MutationEmailStatsCreateManyArgs';
    returnTSName: "Mutation['EmailStatsCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyEmailStatsPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyEmailStatsInput!]!';
        }
      ];
      name: 'EmailStatsCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailStatsCreateOne: {
    type: 'CreateOneEmailStatsPayload';
    schemaKey: 'EmailStatsCreateOne';
    entityName: 'CreateOneEmailStatsPayload';
    isList: false;
    argsTSName: 'MutationEmailStatsCreateOneArgs';
    returnTSName: "Mutation['EmailStatsCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEmailStatsPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneEmailStatsInput!' }
      ];
      name: 'EmailStatsCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserCorpCheck: {
    type: 'UserCorpCheckPayload';
    schemaKey: 'UserCorpCheck';
    entityName: 'UserCorpCheckPayload';
    isList: false;
    argsTSName: 'MutationUserCorpCheckArgs';
    returnTSName: "Mutation['UserCorpCheck']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserCorpCheckPayload';
      args: [{ name: 'CPF'; description: null; type: 'String!' }];
      name: 'UserCorpCheck';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MeuPdvParamsMutation: {
    type: 'MeuDiaPdv';
    schemaKey: 'MeuPdvParamsMutation';
    entityName: 'MeuDiaPdv';
    isList: false;
    argsTSName: 'MutationMeuPdvParamsMutationArgs';
    returnTSName: "Mutation['MeuPdvParamsMutation']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MeuDiaPdv';
      args: [
        { name: 'nome'; description: null; type: 'String' },
        { name: 'endereco'; description: null; type: 'String' },
        { name: 'complemento'; description: null; type: 'String' },
        { name: 'bairro'; description: null; type: 'String' },
        { name: 'uf'; description: null; type: 'String' },
        { name: 'municipio'; description: null; type: 'String' },
        { name: 'regional'; description: null; type: 'String' },
        { name: 'canal'; description: null; type: 'String' },
        { name: 'tipo'; description: null; type: 'String' },
        { name: 'vaga'; description: null; type: 'String' },
        { name: 'cep'; description: null; type: 'String' },
        { name: 'loja'; description: null; type: 'String' },
        { name: 'matricula'; description: null; type: 'String' },
        { name: 'celular'; description: null; type: 'String' },
        { name: 'diretoria'; description: null; type: 'String' },
        { name: 'email'; description: null; type: 'String' }
      ];
      name: 'MeuPdvParamsMutation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RemunerationCreateOne: {
    type: 'CreateOneRemunerationPayload';
    schemaKey: 'RemunerationCreateOne';
    entityName: 'CreateOneRemunerationPayload';
    isList: false;
    argsTSName: 'MutationRemunerationCreateOneArgs';
    returnTSName: "Mutation['RemunerationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneRemunerationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneRemunerationInput!';
        },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'RemunerationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RemunerationUpdateOne: {
    type: 'UpdateOneRemunerationPayload';
    schemaKey: 'RemunerationUpdateOne';
    entityName: 'UpdateOneRemunerationPayload';
    isList: false;
    argsTSName: 'MutationRemunerationUpdateOneArgs';
    returnTSName: "Mutation['RemunerationUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneRemunerationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneRemunerationInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneRemunerationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneRemunerationInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'RemunerationUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RemunerationRemoveById: {
    type: 'RemoveByIdRemunerationPayload';
    schemaKey: 'RemunerationRemoveById';
    entityName: 'RemoveByIdRemunerationPayload';
    isList: false;
    argsTSName: 'MutationRemunerationRemoveByIdArgs';
    returnTSName: "Mutation['RemunerationRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdRemunerationPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'RemunerationRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RemunerationLikeToggle: {
    type: 'Boolean';
    schemaKey: 'RemunerationLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationRemunerationLikeToggleArgs';
    returnTSName: "Mutation['RemunerationLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'RemunerationLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserTourMutation: {
    type: 'Boolean';
    schemaKey: 'UserTourMutation';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserTourMutationArgs';
    returnTSName: "Mutation['UserTourMutation']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'tour'; description: null; type: 'String' }];
      name: 'UserTourMutation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconheceWalletRemoveById: {
    type: 'RemoveByIdReconheceWalletPayload';
    schemaKey: 'ReconheceWalletRemoveById';
    entityName: 'RemoveByIdReconheceWalletPayload';
    isList: false;
    argsTSName: 'MutationReconheceWalletRemoveByIdArgs';
    returnTSName: "Mutation['ReconheceWalletRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdReconheceWalletPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ReconheceWalletRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconheceWalletCreateOne: {
    type: 'CreateOneReconheceWalletPayload';
    schemaKey: 'ReconheceWalletCreateOne';
    entityName: 'CreateOneReconheceWalletPayload';
    isList: false;
    argsTSName: 'MutationReconheceWalletCreateOneArgs';
    returnTSName: "Mutation['ReconheceWalletCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneReconheceWalletPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneReconheceWalletInput!';
        }
      ];
      name: 'ReconheceWalletCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconheceWalletUpdateById: {
    type: 'UpdateByIdReconheceWalletPayload';
    schemaKey: 'ReconheceWalletUpdateById';
    entityName: 'UpdateByIdReconheceWalletPayload';
    isList: false;
    argsTSName: 'MutationReconheceWalletUpdateByIdArgs';
    returnTSName: "Mutation['ReconheceWalletUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdReconheceWalletPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdReconheceWalletInput!';
        }
      ];
      name: 'ReconheceWalletUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SetWarningAt: {
    type: 'WarningAtResult';
    schemaKey: 'SetWarningAt';
    entityName: 'WarningAtResult';
    isList: false;
    argsTSName: 'MutationSetWarningAtArgs';
    returnTSName: "Mutation['SetWarningAt']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'WarningAtResult';
      args: [
        { name: 'idUserCreditOperation'; description: null; type: 'String!' }
      ];
      name: 'SetWarningAt';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ReconheceCreateTempToken: {
    type: 'String';
    schemaKey: 'ReconheceCreateTempToken';
    entityName: 'String';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['ReconheceCreateTempToken']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Cria um "one time roken" que será enviado via url ao Reconhece';
      deprecationReason: null;
      type: 'String';
      args: [];
      name: 'ReconheceCreateTempToken';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };
}
